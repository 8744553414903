import _ from 'lodash';
import CountryFlag from '../../common/CountryFlag';

const conditionEmoji = {
  loadNormal: '👦',
  loadHigh: '👪',
  highway:  '🛣',
  "no-highway":  '🏘',
  comfort:  '💅',
  economic:  '🌿',
}


function TirePressureTable({ data, small, textFilter }) {
  let { countries, tires, pressures, models, model, labelId } = data || {};

  if(model && !models) {
    models = [model];
  }

  let hasOther = false;

  let byCondition = _.groupBy(pressures, r => r.condition || '');
  byCondition = _.mapValues(byCondition, v => {
    let cols = { front: null, rear: null, spare: null, other: null };
    for (let { wheels, pressure, tire } of v) {
      if (wheels in cols) {
        cols[wheels] = pressure;
      } else {
        cols.other = pressure;
        hasOther = true;
      }
      if(tire) {
        cols.tire = tire;
      }
    }
    return cols;
  });

  const pressureCell = pressure => {
    let {kpa, psi, bar, kgfcm2, ... other} = pressure || {};

    let dataWarning = (kpa && (kpa < 100))
      || (psi && (psi < 20 || psi > 70))
      || (bar && (bar < 1 || bar > 6))
      || (kgfcm2 && (kgfcm2 < 1 || kgfcm2 > 6))
      || (bar && psi && Math.abs((bar*14.5038) - psi) > 1.5)
      || (kpa && psi && Math.abs((kpa/100*14.5038) - psi) > 1.5)
      || (kpa && bar && Math.abs((kpa - bar*100)) > 10)
      || (kpa && kgfcm2 && Math.abs((kpa - kgfcm2*100)) > 10)
      || _.some(_.compact([kpa, psi, bar, kgfcm2]), n => !_.isNumber(n))
      || !_.isEmpty(other)
    ;

    return <span className={dataWarning ? 'bg-light-danger border border-danger rounded' : ''}>{pressure ? _.toPairs(pressure).map(([k, v], i) => <span key={k}>
      {i ? '/' : ''}<span title={v + ' ' + k} className={'text-info'}>{(v || '').toString()}</span>
    </span>) : ''}</span>;
  };

  return <div className={'small'}>
    <table className={'table text-center mb-0 ' + (small ? 'zoom-75' : '')}>
      <tbody>
      <tr className={'bg-light'}>
        <td className={'p-0 px-3 text-secondary'}>
          {(tires || []).map(t => <span className={'badge badge-info mr-1'} key={t}>{t}</span>)} &nbsp;
          {(models || []).map(m => <span className={'badge badge-violeta mr-1'} key={m}>{m}</span>)}
        </td>
        <td className={'p-0 px-3'}>Front</td>
        <td className={'p-0 px-3'}>Rear</td>
        <td className={'p-0 px-3'}>Spare</td>
        {hasOther ? <td className={'p-0 px-2'}>Other</td> : null}
      </tr>

      {_.map(byCondition, (cols, condition) =>
        <tr key={condition}>
          <td className={'p-0 px-2 text-secondary text-left'}>{conditionEmoji[condition]} &nbsp; {condition}{cols.tire || ''}</td>
          <td className={'p-0 px-2'}>{pressureCell(cols.front)}</td>
          <td className={'p-0 px-2'}>{pressureCell(cols.rear)}</td>
          <td className={'p-0 px-2'}>{pressureCell(cols.spare)}</td>

          {hasOther ? <td className={'p-0 px-2'}>{pressureCell(cols.other)}</td> : null}
        </tr>)}
      </tbody>
  </table>
  </div>;
}

export default function TirePressureLegoPreview({ data, small, textFilter }) {
  let { countries, date, model, labelIds, pressuresByTire } = data || {};

  if (pressuresByTire.length === 1) {
    pressuresByTire[0].labelIds = labelIds;
  }

  // return "nope"
  try {
    return <div className={'bg-white rounded'}>
      <div className={'text-left'}>
        {labelIds?.map(id => <span key={id} className={'badge badge-primary mr-1 monospace'}>{id}</span>)}
        {(countries || []).map(c => <CountryFlag country={c} key={c}/>)}&nbsp;
        {model ? <span className={'small text-violeta mr-1'}>{model}</span> : null }
      </div>

      {
        _.map(pressuresByTire, (p,i) => <TirePressureTable data={{ ...p, countries }} small={small}
                                                       textFilter={textFilter} key={i+'-'+(p.tires || p.model)}/>)
      }
    </div>;
  } catch (e) {
    return <div className={'alert alert-danger'}>Error rendering tire pressure preview</div>;
  }
}
