import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '../common/IconButton';
import { VehicleSummary } from '../common/VehicleSummary';
import TirePressureLegoPreview from '../lego/tire-pressure/TirePressureLegoPreview';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { parseTirePressureContribution } from '../lego/tire-pressure/ParseTirePressureModal';
import _ from 'lodash';
import LegoContextSummary from '../lego/LegoContextSummary';
import { LegoPreview } from '../lego/LegoPreview';
import BadgeId from '../common/BadgeId';
import LegoEditButton from '../lego/LegoEditButton';
import { LegoStateBadge } from '../lego/LegoStateBadge';
import JsonTextEditor from '../common/editors/JsonTextEditor';
import LeftRightLayout from '../common/layout/LeftRightLayout';
import TextDiff from '../test-sets/TextDiff';
import { ImageLabelSelector } from '../images/ImageLabelSelector';
import { getImageThumbnailMedium } from '../../../../lib/imageUtilsESM';
import {
  haveEqualInformation,
  includesLabelId,
  createTireLabelLegoFromContribution,
  normalizeLabel,
  normalizePressures,
  getUpdateAddingContributionToTireLabelLego
} from '../../../../model/live-scripts/tirepressure/TirePressureLabelLogic.mjs';

export default function TaskEditorTirePressureParse({ batchEditor, task }) {
  const { page } = useContext(LegoAdminPageContext);

  const contributionsSvc = page.service('services/data/contributions');
  const legoSvc = page.service('services/legos');

  const [legos, setLegos] = useState();
  const [contribution, setContribution] = useState();
  const [error, setError] = useState();
  const [parsing, setParsing] = useState(false);

  const [originalParsedLabelIndex, setOriginalParsedLabel] = useState(0);

  let selectedSavedLabel = task.input.parsedLabel;
  if(_.isArray(selectedSavedLabel)) {
    selectedSavedLabel = selectedSavedLabel[originalParsedLabelIndex];
  }

  let [parsedLabel, setParsedLabel] = useState();
  parsedLabel = parsedLabel || selectedSavedLabel;
  const [corners, setCorners] = useState(task.input.labelCorners);

  useEffect(() => {
    page.runAsync(refreshData());
  }, [task.input.contributionId]);

  async function refreshData() {
    let [contribution] = await contributionsSvc.find({
      query: { _id: task.input.contributionId }
    });

    if (contribution) {

      contribution.vehicleContext = { ...contribution.vehicleContext, ...contribution.vehicleContext.trimSpecs };
      delete contribution.vehicleContext.trimSpecs;

      setContribution(contribution);

      let { modelId } = contribution.vehicleContext;
      let legos = await legoSvc.find({
        query: { type: 'data', intentions: 'spec', semantics: 'PRESION_INFLADO', context: { $elemMatch: { modelId } } }
      });

      setLegos(legos);
    }
  }

  const onApprove = async () => {
    await batchEditor.doneAndNext();
  };

  const onSkip = async () => {
    await batchEditor.doneAndNext();
  };

  const openDataDiff = (lego) => {
    page.openModal(<div>
      <div className={'p-2 bg-light monospace small break-word-all'}>
        <TextDiff type={'json'} inputB={JSON.stringify(normalizePressures(lego.data), true, 2)} ellipsis={100}
                  inputA={JSON.stringify(normalizePressures(parsedLabel), true, 2)}/>
      </div>
    </div>, {title: 'Compare parsed data (red) with lego data (green)'});
  };

  const onCreateLego = async () => {
    let newLego = createTireLabelLegoFromContribution(parsedLabel, contribution, page.getLoggedUserSignature());
    const lego = await page.runAsync(legoSvc.create(newLego));
    await refreshData();
  }

  const onAddToLego = async (lego) => {
    let update = getUpdateAddingContributionToTireLabelLego(lego, contribution, !lego.data.labelIds?.length ? parsedLabel?.labelId : null);

    let res = await page.runAsync(legoSvc.update(lego._id, update));
    console.log(res);
    await refreshData();
  }

  const onAddLabelIdToLego = async (lego) => {
    let update = { $addToSet: { "data.labelIds": parsedLabel.labelId } };
    await page.runAsync(legoSvc.update(lego._id, update));
    await refreshData();
  }

  const onRemoveFromLego = async (lego) => {
    let res = await page.runAsync(legoSvc.update(lego._id, { $pull: { source: { type: 'contribution', id: contribution._id.toString() } } }));
    console.log(res);
    await refreshData();
  }

  const onSaveParsedLabel = async () => {
    await batchEditor.updateTask(batchEditor.state.currentTask, { $set: { 'input.parsedLabel': parsedLabel } });
  };

  const onSaveCorners = async () => {
    await batchEditor.updateTask(batchEditor.state.currentTask, { $set: { 'input.labelCorners': corners } });
  };

  const onCornersChange = (corners) => {
    setCorners(corners);
  }

 const isComplete = task.state === 'complete';

  let parseImage = async (useClaude = false, useGemini = false) => {
    try {
      setParsing(true);
      let res = await parseTirePressureContribution({ contribution, cropCorners: corners, useClaude, useGemini, page });
      console.log(res);
      setParsedLabel(res.data);
      setError(null);
    } catch (e) {
      setError('ERROR: ' + e.message);
    } finally {
      setParsing(false);
    }
  };

  let guessCorners = async () => {
    try {
      let response = await page.service(`services/ai/read-tire-pressure-label`).get(getImageThumbnailMedium(contribution.data?.images?.[0]?.url));

      if(response.message) {
        try {
          const {location, orientation} = JSON.parse(response.message);
          let corners = location.map(p => ({x: p[0]/10, y: p[1]/10}));
          console.log("Received corners and orientation", corners, orientation);
          setCorners(corners);
        } catch(e) {
          throw new Error("Error parsing response: "+e.message);
        }
      }
      setError(null);
    } catch (e) {
      setError('ERROR: ' + e.message);
    }
  }

  let parsedLabelSelector = null;
  if(task.input.parsedLabel?.length > 1) {
    let llmContentMatch = false, llmLabelMatch = false;
    if(task.input.parsedLabel?.length === 2) {
      if(haveEqualInformation(task.input.parsedLabel[0], task.input.parsedLabel[1])) {
        llmContentMatch = true;
      }
      if(normalizeLabel(task.input.parsedLabel[0].labelId) === normalizeLabel(task.input.parsedLabel[1].labelId)) {
        llmLabelMatch = true;
      }
    }

    parsedLabelSelector = <div className={'mb-2'}>
      <div className={'pb-1 border-bottom'}>
        {_.map(task.input.parsedLabel, (parsedLabel, index) => {
          return <span key={index} className={`mr-1 btn btn-sm btn-${index === originalParsedLabelIndex ? 'primary' : 'secondary'}`}
                       onClick={() => setOriginalParsedLabel(index)}>
            {parsedLabel.model}
          </span>;
        })}
        {llmContentMatch ? ' ✅ Same info': '❌ Dif. info'}
        {llmLabelMatch ? ' ✅ Same labelIds': '❌ Dif. labels'}
      </div>
    </div>;
  }

  const btnParseGpt = <IconButton icon={'photo_filter'} disabled={parsing}
                                  onClick={() => parseImage()}>Parse GPT</IconButton>;
  const btnParseClaude = <IconButton icon={'photo_filter'} disabled={parsing}
                             onClick={() => parseImage(true)}>Parse Claude</IconButton>;
  const btnParseGemini = <IconButton icon={'photo_filter'} disabled={parsing}
                             onClick={() => parseImage(null, true)}>Parse Gemini</IconButton>;

  const btnSave = <IconButton level={'success'} icon={'save'}
                            onClick={() => page.runAsync(onSaveParsedLabel)}>Overwrite parsed data</IconButton>;

  const btnDiscardChanges = <IconButton level={'danger'} icon={'clear'}
                            onClick={() => setParsedLabel(null)}>Reset</IconButton>;

  const btnCreate = parsedLabel ? <IconButton fill level={'success'} icon={'add'}
                            onClick={() => page.runAsync(onCreateLego)}>Create new lego</IconButton> : null;

  const btnSaveCorners = <IconButton level={'success'} icon={'save'}
                              onClick={() => page.runAsync(onSaveCorners)}>Save updated corners</IconButton>;

  const btnGuessCorners = <IconButton level={'secondary'} icon={'photo_filter'} onClick={() => page.runAsync(guessCorners)}>Guess corners</IconButton>;

  let changedParsedLabel = parsedLabel && !selectedSavedLabel || !_.isEqual(parsedLabel, selectedSavedLabel);

  let changedCorners = corners?.length === 4 && (!task.input.labelCorners || !_.isEqual(corners, task.input.labelCorners));

  let displayData;
  if (parsedLabel?.pressuresByTire) {
    displayData = <div>
      <TirePressureLegoPreview data={{... parsedLabel, labelIds: parsedLabel.labelId ? [parsedLabel.labelId] : []}}/>
    </div>;
  } else {
    displayData = <div className={'alert alert-danger mb-1'}>No parsed data in task input</div>;
  }

  const isLinked = _.some(legos, l => _.some(l.source, s => s.type === 'contribution' && s.id === contribution._id.toString()));

  let sortedLegos = _.sortBy(legos, l => {
    let sameId = includesLabelId(l.data?.labelIds, parsedLabel?.labelId);
    let sameInfo = haveEqualInformation(l.data, parsedLabel);
    return (sameInfo ? 0 : 10) + (sameId ? 0 : 1) + (1 / (1 + (l.source?.length || 0)));
  });

  return <React.Fragment>
    <div className={'grid-pdf-extraction'} style={{ gridTemplateColumns: '30% 40% 30%' }}>
      {contribution ?
        <div className={'bg-secondary'}>
          <ImageLabelSelector url={contribution.data?.images?.[0]?.url} points={corners} onPointsChange={onCornersChange}/>
        </div>
        : <div>Loading...</div>
      }

      <div className={'bg-white'} id={'extractionInputArea'}>
        <div className={'p-2 '}>
          {error ? <div className={'alert alert-danger m-2 p-5 text-center'}>{error}</div> : null}

          <div className={'mb-2'}>
            {contribution ? <VehicleSummary vehicle={contribution.vehicleContext}/> : null}
          </div>

          { parsedLabelSelector }

          {displayData}

          <div className={'my-2'}>
            {parsing ? <div className={'alert alert-info mb-2'}>Parsing with AI...</div> : <>{btnParseGpt} {btnParseClaude} {btnParseGemini}</>}

            {btnGuessCorners}
          </div>

          <div className={'my-2'}>
            {changedCorners ? <span className={'inline-block bg-light-warning rounded ml-1'}>{btnSaveCorners}</span> : null}

            {changedParsedLabel ? <span className={'inline-block bg-light-warning rounded ml-1'}>{btnSave}</span> : null}

            {changedParsedLabel ? <span className={'inline-block bg-light-warning rounded ml-1'}>{btnDiscardChanges}</span> : null}
          </div>

          <div className={'mt-2 zoom-75'}>
            <JsonTextEditor  rows={20} value={parsedLabel} onChange={setParsedLabel}/>
          </div>
        </div>
      </div>

      <div className={'p-2 bg-light'}>
        <div className={'p-2 small text-center '}>
          <div className={'mb-2 d-flex justify-content-between'}>
            {isLinked ? <span/> : btnCreate}

            <span className={`btn btn-${isComplete ? 'primary' : 'success'} ${!isLinked ? 'disabled' : ''}`}
                  onClick={async () => isLinked && page.runAsync(onApprove)}>
            {isComplete ? 'Next' : 'Done and next'}
            </span>
          </div>

          <div className={'mt-2'}>
            {
              _.map(sortedLegos, lego => {
                const sameData = haveEqualInformation(lego.data, parsedLabel);
                const sameId = includesLabelId(lego.data?.labelIds, parsedLabel?.labelId);
                const isMatch = parsedLabel && (sameData || sameId);

                const linkedToContribution = _.some(lego.source, s => s.type === 'contribution' && s.id === contribution._id.toString());

                let btnAddToLego = <span
                  className={`btn btn-${isMatch ? 'success btn-sm' : 'outline-secondary btn-xs '}`}
                  onClick={() => onAddToLego(lego)}>
                  Add as source
                </span>;

                let btnRemoveFromLego = <IconButton icon={'link_off'} level={'danger'}
                                                    onClick={() => onRemoveFromLego(lego)}>
                  Unlink
                </IconButton>;

                let btnAddLabelId = null;
                if(parsedLabel?.labelId && !sameId && sameData && linkedToContribution) {
                  btnAddLabelId = <span className={'btn btn-primary btn-xs'} onClick={() => onAddLabelIdToLego(lego)}>Add label id</span>;
                }

                return <div key={lego._id}
                            className={`mb-1 border rounded p-1 ${linkedToContribution ? 'border-success' : ''} ${isMatch ? 'bg-light-success' : 'bg-white'}`}>
                  <LeftRightLayout>
                    <div className={'text-left'}>
                      <LegoStateBadge compact state={lego.state}/>&nbsp;
                      <LegoEditButton legoId={lego._id} editorProps={{ onAfterSave: refreshData }}/>
                      <BadgeId id={lego._id}/>&nbsp;
                      {/*<LocalesList locales={lego.locales}/>*/}
                    </div>

                    <span className={'pb-1 d-inline-flex align-items-center'}>
                      <IconButton icon={'compare'} level={'primary'} onClick={() => openDataDiff(lego)}/>
                    <span title={'Has the same label id?'}>{sameId ? '✔ ' : '❌'}</span> <span
                      title={'Label data matches exactly?'}>{sameData ? '✔ ' : '❌'}</span>
                      &nbsp;{!linkedToContribution ? btnAddToLego : btnRemoveFromLego} {btnAddLabelId}
                  </span>
                  </LeftRightLayout>

                  <div className={'mb-1 TEXT-LEFT'}>
                    <span className={'badge badge-dark mr-1'}>📚{lego.source?.length || 0}</span>
                    <LegoContextSummary inline context={lego.context}/>
                  </div>

                  <div className={'bg-white'}>
                    <LegoPreview lego={lego}/>
                  </div>
                </div>;
            })
          }
          </div>
        </div>

        <div className={'border border-left-0 border-right-0'}>
          {batchEditor.getTaskStateControls()}
        </div>
      </div>
    </div>
  </React.Fragment>;
}
