import _ from 'lodash';
import { useState } from 'react';

const ellipsis = (s, maxLen) => s.length > maxLen ? `${s.slice(0, maxLen)}(...)` : s;

export default function JSONPreview({value}) {
  const [clip, setClip] = useState(true);

  return <pre style={{ whiteSpace: 'pre-wrap' }} className={'text-secondary monospace break-word-all mb-1'}>
          {_.map(value, (val, key) => <div key={key} onClick={() => setClip(!clip)}>
            <span className={'text-primary'}>{key}</span>: {ellipsis(JSON.stringify(val), clip ? 100 : Infinity)}
          </div>)}
        </pre>
}
