import React, { Component, useContext, useState } from 'react';

import _ from 'lodash';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import CountryFlag from '../../common/CountryFlag';
import { openLego } from '../LegoEditorModal';
import { normalizeTireSpec } from '../../../../../model/live-scripts/tirepressure/TirePressureLabelLogic.mjs';

const colorsById = {};

export default function TirePressureLegosMap({ legos }) {
  const { page } = useContext(LegoAdminPageContext);

  let [contributions, setContributions] = useState(null);

  let mounted = true;
  useAsyncEffect(async () => {
    try {
      const contributionIds = _.flatMap(legos, l => _.filter(l.source, s => s.type === 'contribution').map(s => s.id));
      const result = await page.service('/services/data/contributions').
                                find({ query: { _id: { $in: contributionIds } } });
      if (mounted) {
        setContributions(result);
      }
    } catch (err) {
      console.error(err);
    }
  }, () => mounted = false, _.map(legos, '_id'));

  if (contributions) {
    const byTireByYear = {};
    const contributionsById = _.keyBy(contributions, '_id');

    for (const { data, source, _id } of legos) {
      let sourceContribs = _.filter(source, s => s.type === 'contribution');
      colorsById[_id] = colorsById[_id] || `hsla(${Math.random() * 360}, 100%, ${Math.random() * 60+20}%, 0.4)`;
      let { pressuresByTire } = data;

      for (const { tires, pressures } of (pressuresByTire || [])) {
        let front,rear;
        for(let {condition, wheels, pressure} of pressures) {
          if(['loadNormal', 'no-highway'].includes(condition)) {
            if(wheels === 'front') {
              front = pressure?.psi || Math.round(pressure?.kpa*0.145038) || pressure?.bar;
            } else if(wheels === 'rear') {
              rear = pressure?.psi || Math.round(pressure?.kpa*0.145038) ||pressure?.bar;
            }
          }
        }
        if(!rear || !front) {
          continue;
        }

        for (const source of sourceContribs) {
          let contribution = contributionsById[source.id];
          if (contribution) {
            for (let tire of tires) {
              tire = normalizeTireSpec(tire);

              let yearsByTire = byTireByYear[tire] || {};
              let year = contribution.vehicleContext.year;
              if (year) {
                yearsByTire[year] = yearsByTire[year] || {};
                yearsByTire[year][_id] = yearsByTire[year][_id] || [`${front}/${rear}`];
                yearsByTire[year][_id].push(contribution);
              }
              byTireByYear[tire] = yearsByTire;
            }
          }
        }
      }
    }

    // For each tire, find min and max year for each _id, and fill any year in-between with that _id with the same pressure
    _.each(byTireByYear, (byYearAndId, tire) => {
      let minById = {};
      let maxById = {};
      _.each(byYearAndId, (byId, year) => {
        _.each(byId, (values, id) => {
          minById[id] = Math.min(minById[id] || year, year);
          maxById[id] = Math.max(maxById[id] || year, year);
        });
      });
      // Fill in between year holes
      _.each(minById, (min, id) => {
        if(min !== maxById[id]) {
          _.each(_.range(min+1, maxById[id]), middleYear => {
            byYearAndId[middleYear] = byYearAndId[middleYear] || {};
            byYearAndId[middleYear][id] = byYearAndId[middleYear][id] || [byYearAndId[min][id][0]];
          });
        }
      });
    });



    let usedYears = _.sortBy(_.uniq(_.flatMap(_.values(byTireByYear), years => _.keys(years))).map(y => parseInt(y)));
    usedYears = _.range(_.min(usedYears), _.max(usedYears) + 1).map(y => y.toString());
    const usedTires = _.sortBy(_.keys(byTireByYear));

    return <div>
      <table className={'table table-sm small'}>
        <tbody>
        <tr>
          <td></td>
          <td></td>
          {usedYears.map(year => <th key={year} className={'text-center'}>{year.slice(-2)}'</th>)}
        </tr>

        {_.map(usedTires, (tire,i) => {
          let contribsByYear = byTireByYear[tire];

          return <tr key={tire}>
            <td className={'text-center no-wrap text-info'}>{i}</td>
            <td className={'text-center no-wrap'}>{tire}</td>

            {
              _.map(usedYears, (year) =>
                <td key={year} className={'text-center p-0'} style={{ border: 'solid 1px #DDD' }}>
                  {_.map(contribsByYear[year], ((contribs, id) => {
                    return <div style={{ backgroundColor: colorsById[id] }} className={'cursor-pointer'}  onClick={() => openLego(id, page)}>
                      <div>{contribs[0]}</div>

                      {/*{(contribs.length - 1) || '' } &nbsp;*/}

                      {_.map(contribs.slice(1), (c => <span>
                       <CountryFlag countryCode={c.vehicleContext.countryCode} title={_.values(c.vehicleContext).join(' ')} key={c._id}/>
                    </span>))}</div>
                  }))}
                </td>)
            }
          </tr>;
        }
        )}
        </tbody>
      </table>
      {/*{_.map(legos, (lego, i) => <div><Tiresu</div>)}*/}
    </div>;
  } else {
    return <span>Loading contributions...</span>;
  }
}
