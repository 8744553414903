import React, { Component } from 'react';
import _ from 'lodash'
import { Icon } from '../common/Icon';
import figmaIcon from '../../public/images/logo-figma.svg?url'
import LegoWarningLightsPreview from './lego-previews/LegoWarningLightsPreview';
import CountryFlag from '../common/CountryFlag';
import TirePressureLegoPreview from './tire-pressure/TirePressureLegoPreview';

function LegoImagePreview({url, semanticVariation, generated, source}) {
  return <div className={'overflow-auto no-wrap'}>

    <img className={'border rounded img-sm-fixed bg-info mr-2'} title={url} src={url}/>

    { generated && source === 'figma' ? <span><img alt='figma' className={'mr-2'} src={'/'+figmaIcon}/></span> : null}

    { semanticVariation ? <span title={'semanticVariation'}><span className={'badge badge-primary'}>{semanticVariation}</span></span> : null }
  </div>;
}

function LegoTextPreview(data) {
  return <div className={'small text-secondary'}>{data.toString()}</div>;
}

function LegoSymptomPreview(data) {
  return <div className={'small text-secondary'}>{data.title.toString()}</div>;
}

function LegoDataSpecPreview(data, semantics, lego) {
  if (semantics && semantics.length) {
    let [sem] = semantics;

    if (sem === 'TIPO_DE_ACEITE_MOTOR') {
      let { viscosity, quality, viscosityAlternative, qualityAlternative } = data || {};
      return <div className={'small'}>
        <div>Primary: &nbsp;
          {(viscosity || []).map((v, i) => <span key={i} className={'badge badge-primary mr-1'}>{v}</span>)}
          {(quality || []).map((q, i) => <span key={i} className={'badge badge-secondary mr-1'}>{q}</span>)}
        </div>
        <div className={'translucent'}>Alternative: &nbsp;
          {(viscosityAlternative || []).map((v, i) => <span key={i} className={'badge badge-primary mr-1'}>{v}</span>)}
          {(qualityAlternative || []).map((q, i) => <span key={i} className={'badge badge-secondary mr-1'}>{q}</span>)}
        </div>
      </div>;
    } else if (sem === 'PRESION_INFLADO') {
      return <span>
        <span className={'badge badge-secondary'}>{lego.source?.length} sources</span>
        <TirePressureLegoPreview small data={data}/>
      </span>
    }
  }
  return JSONPreview(data);
}

function LegoArticlePreview(image, title, articleTopicShort, slug, semanticCategory, relatedTag, content) {
  return <div className={'text-secondary'}>
    {image ? <img className={'rounded img-sm-fixed bg-info float-right mr-2'} src={image}/> : null}
    <div className={'small'}>
      <strong>{title}</strong>
    </div>
    <div>
      <span className={'small mr-1'}>{articleTopicShort}</span>
      &nbsp;
      <span className={'badge badge-primary monospace mr-1 '}>{slug}</span>
      <span className={'badge badge-info monospace mr-1'}>{semanticCategory}</span>
      <span className={'badge badge-secondary monospace'}>{relatedTag}</span>
    </div>
    <div className={'small font-italic'}>{content.toString().slice(0, 200)} (...)</div>

  </div>;
}

function LegoFuseboxPreview(data, lego) {
  const isTemplate = lego.type === 'fuseboxTemplate';

  return <div className={'row '}> {
    _.map(data, ({ boxName, boxDiagramImg, boxTablesImages, boxAspectRatio, hidden, fuses, templateId }, i) => {
      let extraStyles = '';

      if (isTemplate) {
        extraStyles += ' bg-gris-blue';
      }

      if(!fuses.length) {
        extraStyles += ' bg-warning';
      } else if(_.isEmpty(fuses[0].layout) && !templateId) {
        extraStyles += ' bg-med-danger';
      }

      if (templateId) {
        extraStyles += ' border border-primary';
      }

      const templateStamp = templateId ?
        <Icon icon={'fingerprint'} className={'float-top-right m-0 p-0'} level={'gris-blue'}/> : null;

      if (boxDiagramImg) {
        let style = { height: '100px', width: '100px', objectFit: "contain" };

        return <span key={i} className={'mr-1 pos-relative' + (isTemplate ? ' bg-gris-blue rounded' : '')}>
                  {hidden ? <Icon icon={'visibility_off'} level={'danger'} className={'position-absolute'}/> : null}

          <img style={style} alt={boxName} title={boxName}
               className={'rounded img-med img-thumbnail ' + extraStyles}
               src={boxDiagramImg.url}/>

          {templateStamp}
              </span>;
      } else if (boxTablesImages?.length) {

        return <span key={i} className={'mr-1 pos-relative' + (isTemplate ? ' bg-gris-blue rounded' : '')}>
                  {hidden ? <Icon icon={'visibility_off'} level={'danger'} className={'position-absolute'}/> : null}

          <img alt={boxName} title={boxName}
               className={'rounded img-200 img-med img-thumbnail ' + extraStyles}
               src={boxTablesImages[0].url}/>

          {templateStamp}
              </span>;
      } else {
        return <span key={i} className={'small img-200 bg-light mr-1 img-thumbnail'}>{boxName || "NO BOX NAME OR IMAGES"}</span>;
        }
      }
    )
  }
  </div>;
}

function LegoProductPreview({brand, productLine, productName, variation, specs, images, name, country   }) {
  return <div>
    <img className={'rounded img-sm bg-secondary float-left mr-2'} src={(images || {})[0]}/>
    <div>{name}</div>
    <div className={'small text-secondary'}>{brand} - {productLine} - {productName} - {variation} <span className={'badge badge-info'}>{country}</span></div>
    <div className={'small'}>{JSON.stringify(specs).slice(0,50).replace(/[{}"\[\]]/g, '') + '...'}</div>
  </div>;
}

function JSONPreview(data) {
  let json = JSON.stringify(data);
  if (json.length > 300) {
    json = <span>{json.slice(0, 300)}<span className={'text-primary'}> (...)</span></span>
  }
  return <div className={'monospace'}>{json}</div>;
}

export class LegoPreview extends Component {
  render() {
    try {
      const {lego, textFilter} = this.props;
      const { type, data, intentions, semantics } = lego;

      if (type === 'image') {
        return LegoImagePreview(data);
      } else if (type === 'text') {
        return LegoTextPreview(data);
      } else if (type === 'symptom') {
        return LegoSymptomPreview(data);
      } else if (type === 'article') {
        let { title, slug, semanticCategory, articleTopicShort, content, image, relatedTag } = data;
        return LegoArticlePreview(image, title, articleTopicShort, slug, semanticCategory, relatedTag, content);
      } else if (type === 'fusebox' || type === 'fuseboxTemplate') {
        return LegoFuseboxPreview(data, lego)
      } else if (type === 'data' && intentions && intentions[0] === 'product') {
        return LegoProductPreview(data);
      } else if (type === 'data' && intentions && intentions[0] === 'spec') {
        return LegoDataSpecPreview(data, semantics, lego);
      } else if (type === 'data' && intentions && intentions[0] === 'warning-lights-incomplete') {
        return <LegoWarningLightsPreview lego={lego} textFilter={textFilter}/>;
      } else {
        return JSONPreview(data);
      }
    } catch(err) {
      return <div className={'alert alert-danger'}>Problem creating lego preview: {err.toString()}</div>
    }
  }
}
