import React from 'react';

import { UserAvatar } from '../common/UserAvatar';
import _ from 'lodash';
import {prettyDuration} from '../common/TimeUtils';
import dayjs from 'dayjs';
import { IconButton } from '../common/IconButton';
import Ansi from "ansi-to-react";
import JSONPreview from '../common/JSONPreview';


const stateRowClass = state => ({
  completed: 'bg-light',
  cancelled: 'bg-light-secondary translucent',
  starting: 'bg-light-warning',
  running: 'bg-light-success',
  failed: 'bg-light-danger',
})[state] || '';

const stateBadgeClass = state => ({
  completed: 'badge-primary',
  starting: 'badge-warning',
  running: 'badge-success',
  cancelled: 'badge-secondary',
  failed: 'badge-danger',
})[state] || '';

export default function ScriptRunsTable({ runs, onCancel, onShowLog, onShowOutput, onRerun }) {
  return <table className={'table table-sm'}>
    <thead>
    <tr>
      <th className={'text-center'}>Who</th>
      <th className={'text-center'}>When</th>
      <th className={'text-center'}>State</th>
      <th className={'text-center'}></th>
      <th className={'text-center'} title={'Duration'}>Dur.</th>
      <th>Script</th>
      <th></th>
      <th>Log</th>
      <th>Parameters and output</th>
    </tr>
    </thead>

    <tbody>
    {(runs || []).map(({_id, startTime, createdBy, script, parameters, state, cancelled, progressText, progressData, error, endTime, id, ... other}) => {
      if(cancelled)
        state = 'cancelled';

      return <tr className={stateRowClass(state)} key={id}>
        <td className={'text-center'}>{createdBy ? <UserAvatar user={createdBy}/> : 'Anon'}</td>

        <td className={'text-info small text-center'}>
          {dayjs(startTime).fromNow()}
        </td>

        <td className={'text-center'}>
          <span className={`badge ${stateBadgeClass(state)}`}>{state}</span>
        </td>

        <td className={'text-center'}>
          {
            ['starting', 'running'].includes(state)
              ?
              <IconButton icon={'cancel'} className={'p-0'} level={'danger'} onClick={() => onCancel(_id)}>Cancel script</IconButton>
              :
              null
          }
        </td>

        <td className={'text-center '}>
          {prettyDuration((endTime ? new Date(endTime) : new Date()) - new Date(startTime))}
        </td>

        <td className={'monospace'}>{script}</td>

        <td>
          {state !== 'running' ? <IconButton icon={'play_circle'} level={'success'} onClick={() => onRerun(script, parameters)} title={'Rerun with same parameters'}></IconButton> : null }
        </td>

        <td>
          { other.hasLog ? <IconButton icon={'article'} onClick={() => onShowLog(_id)} title={'See log'}/> : null}
        </td>

        <td>
          { progressText ? <div className={'text-info small'} style={{width: '500px'}}><Ansi>{progressText}</Ansi></div> : null}

          {_.isEmpty(parameters) ? null : <JSONPreview value={parameters}/>}

          { other.hasOutput ? <IconButton icon={'data_object'} onClick={() => onShowOutput(_id)}>Show output</IconButton> : null}

          {error ? <pre className={'small text-danger p-1 mb-0'}>{error}</pre> : null}

          {other?.length ? JSON.stringify(other) : null}
        </td>
      </tr>;
    })}
    </tbody>
  </table>;
}
