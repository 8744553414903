import React, { Component } from 'react';
import _ from 'lodash';

import PageHeader from '../components/PageHeader';

import LivePage from './LivePage';
import TemplatedFuseboxDiagram from '../components/lego/fusebox-editor/TemplatedFuseboxDiagram';
import LegoIdEditor from '../components/lego/LegoIdEditor';
import { Btn } from '../../../components/common/Btn';

export default class TestFuseboxEditor extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    this.state = {
      ...this.state,
      id: this.getUrlParam('fuseboxId') || '5d3b2b63f0cb181d448b70f3',
      selectedBox: 0,
      // fusebox: emptyFusebox
    };

    this.loadFusebox().catch(console.error);
  }

  async loadFusebox() {
    try {
      let fusebox = await this.service('services/legos').get(this.state.id);
      this.setState({ fusebox });
    } catch(err) {
      console.error(err);
    }
  }

  setId(id) {
    this.setState({id}, () => this.loadFusebox());
    this.setUrlParam('fuseboxId', id)
  }

  renderPageBody() {
    let {fusebox, id, selectedBox} = this.state;

    let box = fusebox?.data?.[selectedBox];
    return <div className={''}>
      <div className={'d-flex'}>
        <LegoIdEditor value={id} onChange={id => this.setId(id)}/>
        { _.map(fusebox?.data, (box,i) => <span key={i} className={'m-1 btn btn-secondary btn-sm'} onClick={() => this.setState({ selectedBox: i })}>{box.boxName}</span>) }
      </div>


      <div style={{height: 'calc(100% - 80px)'}}>
        { box ? <TemplatedFuseboxDiagram development={true} fusebox={box}/> : 'Loading...' }
      </div>
    </div>;
  }
}
