import React from "react";
import _ from "lodash";

import ManualFeaturesLegoEditor from "./ManualFeaturesLegoEditor";
import { FuseboxLocationPdfExtractionEditor } from '../../lego/extraction-data-editors/FuseboxLocationPdfExtractionEditor';


const fuseboxSelector = {
  type: 'data',
  intentions: 'spec',
  semantics: 'WHERE_IS(what=FUSIBLERA)',
  'source.type': 'owner-manual',
}

const defaultNewEmptyLego = {
  data: [{
    fuses: []
  }],
  type: 'data',
  intentions: ['spec'],
  state: 'inprogress',
  semantics: ['WHERE_IS(what=FUSIBLERA)'],
}

export default class ManualFeaturesFuseboxLocationEditor extends ManualFeaturesLegoEditor {
  constructor(props) {
    super(props, 'Fusebox location', 'features.hasFuseboxLocation', fuseboxSelector, defaultNewEmptyLego);
    this.state.commonContext = false;
    this.state.featureName = 'Has fusebox location';
    this.useIndividualContext = true;
  }

  getSingleLegoDataEditor(lego, props) {
    // Parameters needed for manual fragment extraction
    const pdfUrl = this.props.manual.manualPdfUrl;
    const manualId = this.props.manual._id;
    return <FuseboxLocationPdfExtractionEditor manualPdfUrl={pdfUrl} manualId={manualId} value={lego} {...props}/>;
  }
}
