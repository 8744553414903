import React, { Component, useContext, useState } from 'react';

import useAsyncEffect from '../common/react-hooks/useAsyncEffect';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import { VehicleSummary } from '../common/VehicleSummary';
import { ImagePreview } from '../images/ImagePreview';

export default function ContributionPreview({id}) {
  const { page } = useContext(LegoAdminPageContext);

  let [data, setData] = useState(null);

  let mounted = true;
  useAsyncEffect(async () => {
    try {
      const result = await page.service('/services/data/contributions').get(id);
      if (mounted) {
        setData(result);
      }
    } catch(err) {
      console.error(err);
    }
  }, () => mounted = false);

  if(data) {
    return <span className={'no-wrap'}>
      <span style={{display: 'inline-block', width: '32px'}}>
        <ImagePreview img32 url={data.data.images?.[0]?.url} zoom/>
      </span>

      <span className={'ml-1 mr-1 badge badge-secondary'}>{data.contributionType}</span>

      <VehicleSummary vehicle={data.vehicleContext}/>

      {/*<pre>{JSON.stringify(data, true, 2)}</pre>*/}
    </span>;
  } else {
    return <span>Loading contribution ${id}...</span>;
  }
}
