import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { IconButton } from '../../common/IconButton';
import { MultipleImagesEditor } from '../../common/editors/MultipleImagesEditor';
import MultipleItemsEditor from '../MultipleItemsEditor';
import TrimSpecsEditor from '../trims/TrimSpecsEditor';
import SingleTextEditor from '../../common/editors/SingleTextEditor';
import SingleValueSelector from '../../common/editors/SingleValueSelector';
import useEditableLegoData from '../data-caches/useEditableLegoData';

function FuseboxLocationType({ value, onChange, ...other }) {
  const [options, setOptions, legoId] = useEditableLegoData({ type: 'data', intentions: 'definition', semantics: 'FUSEBOX_STRUCTURED_LOCATIONS' }, [], true);

  const onCreateOption = (newOption) => {
    setOptions(_.uniq([...options, newOption]).sort()).catch(alert);
    onChange(newOption);
  };

  return <SingleValueSelector placeholder={'Fusebox location...'}
                              value={value}
                              canCreate={true}
                              onCreateOption={onCreateOption}
                              onChange={onChange}
                              options={options} {...other}/>;
}


export const FuseboxLocationPdfExtractionEditor = ({ value, onChange, manualId, manualPdfUrl }) => {
  const getContextIdentifier = () => value.context && value.context.length      ? _.values(value.context[0]).join('-').toLowerCase()      : null;

  const editField = (fuseboxIndex, field, newValue) => {
    const lego = _.cloneDeep(value);

    if (!lego.data[fuseboxIndex]) {
      lego.data.push({ fuses: [] });
    }

    lego.data[fuseboxIndex][field] = newValue;

    onChange(lego);
  };

  const updateContext = (newContext) => {
    if (newContext.length > 0) {
      onChange({ ...(_.cloneDeep(value)), context: newContext });
    }
  };

  const addFusebox = () => editField(value.data.length, 'boxLocationImages', []);

  const delFusebox = (fuseboxIndex) => {
    const lego = _.cloneDeep(value);
    lego.data.splice(fuseboxIndex, 1);
    onChange(lego);
  };

  const { context: propContext, data, _id } = value;

  const header = _id ? <a target={'_blank'} className={'small'} href={`/legos/fusebox?editing="${_id}"`}>
    Fusebox {_id} <IconButton icon={''}/>
  </a> : null;

  const contexts = _.isArray(propContext) ? propContext : [propContext];

  let defaultNewContext = {};
  if (contexts[0] && contexts[0].modelId && contexts[0].year) {
    defaultNewContext = { modelId: contexts[0].modelId, year: contexts[0].year };
  }

  const contextEditor = (
    <MultipleItemsEditor
      value={contexts}
      defaultNewItem={defaultNewContext}
      buttonsAlwaysVisible={true}
      renderComponent={(item, props) => <TrimSpecsEditor value={item} {...props} />}
      onChange={(specs) => updateContext(specs)}
    />
  );

  const boxes = data.map(({ boxLocationImages, location, comments }, i) => (
    <div className={'bg-white rounded px-1 mb-1'} key={i}>
      <strong>Fusebox #{i + 1}</strong>
      <IconButton icon={'delete'} level={'secondary'} onClick={() => delFusebox(i)}/>

      <MultipleImagesEditor
        value={boxLocationImages || []}
        defaultName={getContextIdentifier()}
        manualId={manualId}
        manualPdfUrl={manualPdfUrl}
        placeholder={'UBICACION'}
        folder={'fusebox-location'}
        category={'fusebox-location'}
        onChange={(images) => editField(i, 'boxLocationImages', images)}
      />

      <FuseboxLocationType value={location} onChange={(val) => editField(i, 'location', val)}/>

      <SingleTextEditor placeholder={'Comment'}
                        value={comments}
                        className={'mb-1 mt-1 zoom-90'}
                        onChange={txt => editField(i, 'comments', txt)}/>
    </div>
  ));

  return <div className={`rounded p-1 bg-light-${_id ? 'secondary' : 'success'} zoom-90`}>
    {header}
    {contextEditor}
    {boxes}
    <IconButton icon={'add'} level={'success'} onClick={addFusebox}>
      Add Fusebox #{boxes.length + 1}
    </IconButton>
  </div>;
};
