import React, { Component } from 'react';

import _ from 'lodash';

import {
  FUSE_COLORS,
  FUSE_NAMES_EN,
  FUSE_NAMES_ES,
  FUSE_NAMES_PT,
  FUSE_SIZES,
  getSemanticIcon
} from './FuseboxData.mjs';

let FUSE_NAMES_LOCALIZED;
// Unused locales will be tree-shaked
if(window.config?.locale === 'en') {
  FUSE_NAMES_LOCALIZED = FUSE_NAMES_EN;
} else if(window.config?.locale === 'pt') {
  FUSE_NAMES_LOCALIZED = FUSE_NAMES_PT;
} else {
  FUSE_NAMES_LOCALIZED = FUSE_NAMES_ES;
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


function contrastRequiresBlack(bgColor) {
  const {r,g,b} = _.isString(bgColor) ? hexToRgb(bgColor) : bgColor
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return (yiq >= 128);
}

function fuseImageFormat(fuse) {
  let { type, format } = fuse.kind || {};
  if(type) {
    let imageSpecification = FUSE_SIZES[type] && (FUSE_SIZES[type][format] || FUSE_SIZES[type]['all']);

    if (FUSE_SIZES[type] && fuse.vertical) {
      imageSpecification = FUSE_SIZES[type][format + "-vertical"] || FUSE_SIZES[type]['all-vertical'] || imageSpecification;
    }

    return imageSpecification;
  }
}

function mergeLayoutBoxes({left, top, width, height, ... other}, l2) {
  const newLeft = Math.min(left, l2.left);
  const newTop = Math.min(top, l2.top);
  return {
    left: newLeft,
    top: newTop,
    width: Math.max(left+width, l2.left+l2.width) - newLeft,
    height: Math.max(top+height, l2.top+l2.height) - newTop,
    ... other
  }
}

function areTouching({left, top, width, height, ... other}, f2) {
  return !((top + height) < f2.top
    || (left + width) < f2.left
    || top > (f2.top + f2.height)
    || left > (f2.left + f2.width))
}

function rotate(cx, cy, x, y, angle) {
  // Rotate in the same coordinate system as CSS (Y axis increases downwards)
  const radians = -(Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (x - cx)) - (sin * (-y + cy)) + cx,
    ny = (cos * (-y + cy)) + (sin * (x - cx)) - cy;

  return [nx, -ny];
}

function overlap(cx, cy, rad, width, height, left, top) {
  // Circle bounds
  const circleLeft = cx - rad;
  const circleRight = cx + rad;
  const circleTop = cy - rad;
  const circleBottom = cy + rad;

  // Rectangle bounds
  const rectRight = left + width;
  const rectBottom = top + height;

  // Calculate the intersection rectangle
  const intersectLeft = Math.max(circleLeft, left);
  const intersectRight = Math.min(circleRight, rectRight);
  const intersectTop = Math.max(circleTop, top);
  const intersectBottom = Math.min(circleBottom, rectBottom);

  // Check if there is an intersection
  if (intersectLeft < intersectRight && intersectTop < intersectBottom) {
    const intersectArea = (intersectRight - intersectLeft) * (intersectBottom - intersectTop);
    const fuseArea = width*height;
    return (intersectArea / fuseArea); // Percentage of the circle overlapping
  }
  return 0; // No overlap
}


function distanceToBoxRotated(x, y, left, top, width, height, rotation) {
  // Convert rotation angle from degrees to radians
  const angle = (rotation * Math.PI) / 180;

  // Calculate the center of the box
  const centerX = left + width / 2;
  const centerY = top + height / 2;

  // Translate the point (x, y) to the box's coordinate system (centered at the box's center)
  const translatedX = x - centerX;
  const translatedY = y - centerY;

  // Apply reverse rotation to the point
  const rotatedX = translatedX * Math.cos(-angle) - translatedY * Math.sin(-angle);
  const rotatedY = translatedX * Math.sin(-angle) + translatedY * Math.cos(-angle);

  // Translate the point back to the original coordinate system
  const unrotatedX = rotatedX + centerX;
  const unrotatedY = rotatedY + centerY;

  // Box boundaries in the unrotated coordinate system
  const right = left + width;
  const bottom = top + height;

  // Check if the point is inside the box
  if (unrotatedX >= left && unrotatedX <= right && unrotatedY >= top && unrotatedY <= bottom) {
    // Compute the minimum distance to the perimeter from inside the box
    const distLeft = unrotatedX - left;
    const distRight = right - unrotatedX;
    const distTop = unrotatedY - top;
    const distBottom = bottom - unrotatedY;
    return -Math.min(distLeft, distRight, distTop, distBottom);
  } else {
    // Compute the horizontal and vertical distances to the box
    const dx = unrotatedX < left ? left - unrotatedX : unrotatedX > right ? unrotatedX - right : 0;
    const dy = unrotatedY < top ? top - unrotatedY : unrotatedY > bottom ? unrotatedY - bottom : 0;

    // Return the Euclidean distance to the box
    return Math.sqrt(dx * dx + dy * dy);
  }
}

class FuseboxDiagram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // fusebox: this.preprocessDiagram(this.props.fusebox),
      selected: this.props.selected || [],
      maxHeight: 100,
      maxWidth: 100,
      showSearchBar: false
    }

    this.containerRef = React.createRef();
    this.titleRef = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);

    let fuseboxId = Math.random();
  }

  // Used from the .pug fusebox page through React's component ref
  selectFuseByIndex(fuseIndex) {
    let matchingFuse = this.props.fusebox.fuses[fuseIndex];
    if(matchingFuse) {
      this.selectFuse(matchingFuse)
    }
  }

  selectFusesByIndexes(fuseIndexes) {
    let matchingFuses = _.compact(fuseIndexes.map(fuseIndex => this.props.fusebox.fuses[fuseIndex]));
    this.setState({ selected: matchingFuses, hoverSelection: true });
  }

  // Used from the .pug fusebox page through React's component ref
  hoverFuseByIndex(fuseIndex) {
    let matchingFuse = this.props.fusebox.fuses[fuseIndex];
    if(matchingFuse) {
      this.hoverFuse(matchingFuse)
    }
  }

  selectFuse(fuse, e) {
    if(fuse) {
      this.setState({ selected: [fuse], hoverSelection: false });
      if(e) {
        e.stopPropagation();
      }
    } else {
      this.setState({ selected: [], hoverSelection: false });
    }

    if(this.props.onSelectFuse) {
      this.props.onSelectFuse(fuse, e);
    }
  }

  hoverFuse(fuse, e) {
    if (fuse && (!this.state.selected.length || this.state.hoverSelection)) {
      this.setState({ selected: [fuse], hoverSelection: true });
    } else if (this.state.hoverSelection) {
      this.setState({ selected: [], hoverSelection: false });
    }

    if (this.props.onHoverFuse) {
      this.props.onHoverFuse(fuse, e);
    }
  }

  renderFuse(fuse, viewBox, i) {
    let {kind, layout, id, vertical, onlyBlockSelection, description, npc, option, totalOptions} = fuse;

    if(!layout?.width) {
      if(this.props.development && !this.props.marks) {
        return <span className={'badge badge-danger m-1'} key={id + i}>{id}</span>
      } else {
        return null
      }
    }

    let { amp, variation, type, format, notUsed } = kind;

    // Cheap way to reuse the same image in different fuse formats
    format = (format || "")
      .replace(/cartridge-flf$/i, 'cartridge-flm')

    let colorClass = '';

    if(amp || (type === 'fuse' && notUsed === false)) {
      colorClass += ` fuse-amp-${(amp || '').toString().replace('.', '_')}`;
    }

    if(variation && !notUsed) {
      colorClass += ` fuse-var-${variation}`;
    }

    if(notUsed === true || (type === 'fuse' && notUsed !== false && !amp)) {
      colorClass += ` ${type}-empty`;
      colorClass += ` fuse-amp-empty`;
    }

    if (vertical) {
      colorClass += " vertical"
    }

    if(totalOptions) {
      colorClass += ` option-${option}-of-${totalOptions}`;
    }

    const formatClass = format  ? `${type}-${format}` : ""

    let fuseStyle = this.getBoxCss(layout, viewBox);

    // Order components from right to left, and from their bottom
    fuseStyle.zIndex = `${Math.max(1, Math.round(10*(layout.top+layout.height) - layout.left))}`;

    if(layout.rotation) {
      fuseStyle.transform = `rotate(${layout.rotation}deg)`
    }
    if(layout.translateX) {
      fuseStyle.transform = ((fuseStyle.transform || '') + ` translateX(${layout.translateX})`).trim();
    }
    if(layout.translateY) {
      fuseStyle.transform = ((fuseStyle.transform || '') + ` translateY(${layout.translateY})`).trim();
    }


    let fuseClass = `fuse fuse-${type} ${colorClass} ${formatClass}`

    if(onlyBlockSelection) {
      fuseClass = `fuse fuse-transparent`
    }

    if(npc) {
      fuseClass += ' fuse-decoration';
    }

    if(!description && type !== 'fuse' && !npc && !this.props.development) {
      fuseClass += ' fuse-non-interactive';
    }

    const idSafeString = str => str.replace(" ", "");
    let fuseId = "fuse" + idSafeString(id);

    let inner = null;

    if (this.state.selected?.length || this.props.highlightedIds?.length) {
      if (_.includes(_.map(this.state.selected, 'id'), id) || _.includes(this.props.highlightedIds, id)) {
        if(!this.state.hoverSelection) {
          fuseClass += ' highlighted'
        } else {
          fuseClass += ' hovered'
        }
        fuseStyle.zIndex = '1001'
      } else {
        fuseClass += ' translucent'
      }
    }

    if (type === "fuse" && !onlyBlockSelection) {
      inner = <div className={'amp'}>{amp}<span>A</span></div>;
    }

    if (type === "relay") {
      let text = (layout.hideText || (!description && id.toString().length <= 4)) ? '' : id;
      inner = <div className={'relay'}><span className={'small'}>{text}</span></div>;
    }

    const fuseMark = this.props.marks?.[id.toString()];
    if(fuseMark) {
      fuseClass += ' marked';
    }

    if(!npc) {
      return <div
        onClick={(e) => this.selectFuse(fuse, e)}
        onMouseEnter={(e) => this.hoverFuse(fuse, e)}
        onMouseLeave={(e) => this.hoverFuse(null, e)}
        key={fuseId + '_' + i}
        className={fuseClass} style={fuseStyle}>
        <div className={'inner'}>{inner}</div>
        {fuseMark ? <span className={'fuse-mark'}>{fuseMark}</span> : null}
      </div>
    } else {
      return <div
        key={fuseId + '_' + i}
        className={fuseClass} style={fuseStyle}>
        <div className={'inner'}>{inner}</div>
        {fuseMark ? <span className={'fuse-mark'}>{fuseMark}</span> : null}
      </div>
    }
  }

  renderIcon(icon, viewBox, i, isLightBackground) {
    const {layout, fuseId, fuse, entity, arrow} = icon;

    if(!layout?.width) {
      return null
    }

    let iconStyle = this.getBoxCss(layout, viewBox);


    let iconClass = `fuseIcon fuseIcon-${entity}`;
    const iconSelected = _.includes(_.map(this.state.selected, 'id'), fuseId) || _.includes(this.props.highlightedIds, fuseId);
    if (this.state.selected?.length || this.props.highlightedIds?.length) {
      if (iconSelected) {
        if(!this.state.hoverSelection) {
          iconClass += ' highlighted'
        } else {
          iconClass += ' hovered'
        }
        iconStyle.zIndex = '1001'
      } else {
        iconClass += ' translucent'
      }
    }

    if(isLightBackground && layout.orientation !== 'overlay') {
      iconClass += ' iconDark';
    }

    if(layout.orientation) {
      iconClass += ` iconOrientation-${layout.orientation}`;
    }

    return <div
      onClick={(e) => this.selectFuse(fuse, e)}
      onMouseEnter={(e) => this.hoverFuse(fuse, e)}
      onMouseLeave={(e) => this.hoverFuse(null, e)}
      key={entity+i}
      className={iconClass} style={iconStyle}>
        <div className={'iconArrow'}></div>
    </div>
  }

  getBoxCss(layout, viewBox) {
    let { left, top, width, height } = viewBox;
    return {
      width: `${layout.width/(width/100)}%`,
      height: `${layout.height/(height/100)}%`,
      left: `${(layout.left - left)/width*100}%`,
      top: `${(layout.top - top)/height*100}%`,
    };
  }

  updateDimensions() {
    let {fitScreen, fitMargins} = this.props;

    fitMargins = {top: 10, left: 0, maxWidth: Infinity, ... (fitMargins || {})};

    if(this.state.showSearchBar) {
      fitMargins.top += 40;
    }

    let $container = fitScreen ? $(window) : $(this.containerRef.current);

    if($container.length) {

      let h = $container.outerHeight();
      let w = $container.outerWidth();
      if(fitScreen) {
        // For some reason, window.outerHeight returns null in recently opened tabs that still remain hidden
        // Also, innerHeight will change in mobile when the url bar appears/dissapears on scroll
        h = window.innerHeight;
      }

      const titleHeight = $(this.titleRef.current).outerHeight();

      let maxHeight = h - (this.state.fullScreen ? 0 : fitMargins.top) - titleHeight;
      let maxWidth = Math.min(fitMargins.maxWidth, w - ((this.state.fullScreen ? 0 : fitMargins.left)));

      // console.warn(`Updating diagram dimensions to ${maxHeight}x${maxWidth} (- title ${titleHeight}px)`)

      this.setState({maxHeight, maxWidth});
    } else {
      console.warn("No container length to update diagram dimensions")
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();

    // Sometimes the scrollbar is not yet renderer, producing incorrect width
    setTimeout(() => this.updateDimensions(), 1)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  toggleFullScreen() {
    const fullScreen = !this.state.fullScreen;
    this.setState({fullScreen}, () => this.updateDimensions())
    if(this.props.onFullScreenToggle) {
      this.props.onFullScreenToggle(fullScreen)
    }
  }

  render() {
    try {

      if(!this.preprocessed || this.props.development || this.lastFusebox !== this.props.fusebox) {
        this.lastFusebox = this.props.fusebox;
        console.time('preprocessDiagram');
        this.preprocessed = this.preprocessDiagram(this.props.fusebox);
        console.timeEnd('preprocessDiagram');
      }

      const { boxName, boxDescription, viewBox, fuses, icons, backgroundColor } = this.preprocessed;

      const isLightBackground = backgroundColor && contrastRequiresBlack(backgroundColor);
      const diagramBackground = backgroundColor ? {backgroundColor, "--diagramBackground": backgroundColor} : {};

      let maxHeight = this.state.maxHeight;
      let maxWidth = this.state.maxWidth;

      let h,w=null;
      if (maxHeight / maxWidth > viewBox.aspectRatio) {
        h = viewBox.aspectRatio * maxWidth
        w = maxWidth
      } else {
        w = maxHeight / viewBox.aspectRatio
        h = maxHeight
      }
      let boxStyle = { height: `${h}px`, width: `${w}px` };

      let containerStyle = this.props.fitScreen ? {} : {height: `100%`, width: `100%`};

      let fullScreenClass = this.state.fullScreen ? 'fusebox-diagram--fullScreen' : '';

      let selectedInfo = null, selectedArrow;

      if (this.state.selected?.length) {
        let selected = _.filter(fuses, f => _.includes(_.map(this.state.selected, 'id'), f.id));
        // In some edge cases, the fuses are changed and an outdated selection remains
        let layout = selected[0]?.layout;
        if(layout) {
          let fuseCenterX = layout.left+layout.width/2;
          let diagramCenter = viewBox.left + viewBox.width/2;
          let fuseToTheLeft = fuseCenterX < diagramCenter;
          let globeSide = (fuseToTheLeft ? ' box-right' : ' box-left')
          selectedInfo = <div className={'selectedfuse ' + globeSide}>
            <div className={'globe'}> {
              // Use fuse.description in key in case fuseId is repeated, happens in some fusebox variations
              _.map(selected, fuse => {
                  let { amp, type, format } = fuse.kind || {};
                  const ampColor = amp ? { backgroundColor: FUSE_COLORS[fuse.kind.format]?.[amp] } : null;

                  const displayType = FUSE_NAMES_LOCALIZED.types[type] || type;
                  const displayFormat = FUSE_NAMES_LOCALIZED?.formats?.[type]?.[format] || '';

                let icons = fuse.icons;
                let iconsImgs = icons.length ? <div className={'fuse-icons'}>
                  { icons.map(entity => {
                    return <div key={entity} className={`fuseIcon fuseIcon-${entity}`}/>;
                  }) }
                </div> : null;

                return <div className={'fuse-info'} key={fuse.id + (fuse.option || '')}>
                  <div className={'fuse-id-format'}>
                    <span className={'fuse-number'}>{fuse.id}</span>

                    <span className={'ml-1 fuse-format'}>
                      {displayType} {displayFormat}
                      {amp ? <span className={'ml-1 amp'} style={ampColor}>{fuse.kind.amp}A</span> : null}
                    </span>
                  </div>

                  <div className={'fuse-icon-description'}>
                    {iconsImgs}

                    <div className={'fuse-description'}>{fuse.description}</div>
                    {fuse.individualFusePages?.length ? fuse.individualFusePages.map(f => {
                      return f.urlWithAnchor? <div style={{fontSize: '12px'}} className={'pt-05'}>
                        <a className={'text-weight-normal'} href={f.urlWithAnchor}>{f.problemsTitle}...</a>
                      </div> : null;
                    }) : null}
                  </div>
                </div>;
                }
              )
            }
            </div>
          </div>;

          let x1 = w*(fuseCenterX-viewBox.left)/viewBox.width;
          let y1 = h*(layout.top+layout.height/2-viewBox.top)/viewBox.height;
          selectedArrow = <svg width={w} height={h}>
            <line x1={x1} y1={y1} x2={fuseToTheLeft ? w*0.8 : w*0.2} y2={h/2} stroke={'white'} strokeLinecap="round" strokeWidth={3}/>
            <circle cx={x1} cy={y1} r={7} stroke={'white'} fill={'none'} strokeWidth={1}/>
          </svg>
        }
      }

      let searchBar;
      if(this.state.showSearchBar) {
        searchBar = <div className={'fusebox-search-bar'}>
          <input type="text" placeholder="Search fuses..." className={'fusebox-search-bar__input'} onChange={(e) => this.search(e.target.value)}/>
        </div>
      }


      return <div ref={this.containerRef} style={containerStyle} className={`fusebox-diagram ${fullScreenClass} ${isLightBackground ? 'fusebox-diagram--light' : ''}`}>
        { searchBar}
        <div ref={this.titleRef} className={'text-center fusebox-title'}>
          {this.props.hideTittle ? null :
          <span>

              { boxName }

            <span onClick={()=> this.toggleFullScreen()} className={'text-white ml-2 margin'} title={'Full screen'}>
              <span alt={'Full screen'} className={`IconSvg IconSvg--${this.state.fullScreen ? 'ExitFullScreen' : 'FullScreen'}`}/>
            </span>
            </span>}

          </div>
          <div style={boxStyle} className={'fusebox-viewport'} onClick={(e) => this.selectFuse(null, e)}>
            <div className={'fusebox'} style={diagramBackground}>
              {
                _.map(fuses, (fuse, i) => this.renderFuse(fuse, viewBox, i))
              }

              {
                _.map(icons, (icon, i) => this.renderIcon(icon, viewBox, i, isLightBackground))
              }

              {selectedArrow}
            </div>
          </div>
          {selectedInfo}
          <span className={`fusebox-diagram__exit-fullscreen`} onClick={()=> this.toggleFullScreen()}>
            Exit full screen
            <span alt={'Close full screen'} className={`IconSvg IconSvg--ExitFullScreen`}/>
          </span>
        </div>
    } catch(err) {
      return <div className={'bg-dark text-white p-5'}>
        <div className={'text-center mb-3'}>There was a problem trying to build fusebox diagram, check that the diagram is finished</div>
        <div className={'alert alert-danger'}>{err.toString()}</div>
      </div>
    }
  }

  search(value) {
    console.log('Searching for', value)
  }

  mergeMultiBlockFuses(box) {
    // Find and merge multiblock fuses
    const blockTypes = ['fuse-block', 'fuse-mpf', 'fuse-mpf2'];
    let blockFusesIds = {};
    let extras = [];
    box.fuses = _.filter(box.fuses, (f, i) => {
      const type = f.kind?.type;

      if (f.layout && (blockTypes.includes(type) || f.kind?.format === 'micro3')) {
        const sameIdBlock = blockFusesIds[type]?.[f.id];
        let collidingBlocks = _.filter(_.values(blockFusesIds[type]), block => areTouching(block.layout, f.layout))

        if (sameIdBlock || collidingBlocks.length) {
          if (sameIdBlock) {
            sameIdBlock.description += '.\n\n' + f.description;
            return false;
          } else {
            let anchorBlock;
            for(const collidingBlock of collidingBlocks) {
              if(anchorBlock) {
                // F was already processed, merge other blocks that appear to be part of the block
                anchorBlock.layout = mergeLayoutBoxes(collidingBlock.layout, anchorBlock.layout);

                // If we are merging a block merely created to draw the block, delete it, the anchor already covers it
                if(collidingBlock.npc) {
                  extras = _.without(extras, collidingBlock)
                } else {
                  collidingBlock.onlyBlockSelection = true;
                }
              } else {
                anchorBlock = collidingBlock;
                if (!anchorBlock.npc) {
                  anchorBlock.onlyBlockSelection = true;
                  let newBlock = {
                    ..._.cloneDeep(anchorBlock),
                    id: 'multi__' + anchorBlock.id,
                    description: 'multiblock',
                    npc: true,
                    onlyBlockSelection: false
                  };
                  blockFusesIds[type][anchorBlock.id] = newBlock;
                  extras.push(newBlock);
                  anchorBlock = newBlock;
                }

                anchorBlock.layout = mergeLayoutBoxes(f.layout, anchorBlock.layout);
                // console.log(`Merged: ${collidingBlock.id} - ${f.id}`)
                f.onlyBlockSelection = true;

                anchorBlock = collidingBlock;
              }
            }
          }
        } else {
          blockFusesIds[type] = blockFusesIds[type] || {};
          blockFusesIds[type][f.id] = f;
        }
      }
      return true;
    });
    return box.fuses.concat(extras);
  }

  preprocessDiagram(originalBox) {
    const box = _.cloneDeep(originalBox)

    const aspectRatio = box.boxAspectRatio || 1;

    // Convert all coordinates to a 1:1 aspect ratio
    // aspectRatio = Height / Width;
    _.each(box.fuses, fuse => {
      let { height, width, top, left } = fuse.layout || {};

      if (height && width) {
        width = width / aspectRatio;
        left = left / aspectRatio;

        fuse.layout.width = width;
        fuse.layout.left = left;
      }
    });

    if (box.boxRotation) {
      _.each(box.fuses, fuse => {
        let { height, width, top, left } = fuse.layout || {};
        if (height) {
          switch (box.boxRotation) {
            case 90:
              fuse.layout.height = width;
              fuse.layout.width = height;
              fuse.layout.top = left;
              fuse.layout.left = 100 - top - height;
              break;
            case 180:
              fuse.layout.top = 100 - top - height;
              fuse.layout.left = 100 - left - width;
              break;
            case 270:
              fuse.layout.height = width;
              fuse.layout.width = height;
              fuse.layout.top = 100 - left - width;
              fuse.layout.left = top;
              break;
          }
        }
      });
    }

    box.fuses = this.mergeMultiBlockFuses(box);

    let fusesWithLayout = _.filter(box.fuses, f => f.layout?.width);

    // Detect which fuses have boxes that are already constructed "rotated" by shape
    _.each(fusesWithLayout, fuse => {
      // Mark used to rotate text in mini/standard vertical fuses
      let { height, width } = fuse.layout;
      let formatSize = fuseImageFormat(fuse);

      if (formatSize?.isVertical) {
        if ((width / 1.1) > height) {
          fuse.vertical = true;
        }
      } else {
        if ((width * 1.1) < height) {
          fuse.vertical = true;
        }
      }

      // Update formatSize in case there is a special image for vertical box
      formatSize = fuseImageFormat(fuse);

      if (fuse.vertical && !formatSize?.dontRotateVertical && !formatSize?.isVertical) {
        // Don't need to swap height and width because it is coming from the rule that it's not rotated
        fuse.layout.rotation = (fuse.layout.rotation || 0) + 90;
      }

      // When rotating the original fusebox, some fuses that were already rotated due to being vertical
      if (formatSize?.asymetrical && box.boxRotation) {
        switch (box.boxRotation) {
          case 90:
            if (!fuse.vertical) {
              fuse.layout.rotation = 180 + (fuse.layout.rotation || 0);
            }
            break;
          case 180:
            // if (!fuse.vertical) {
              fuse.layout.rotation = 180 + (fuse.layout.rotation || 0);
            // }
            break;
          case 270:
            if (fuse.vertical) {
              fuse.layout.rotation = (fuse.layout.rotation || 0) - 180;
            }
            break;
        }
      }

      if(box.flipHorizontal) {
        fuse.layout.left = 100 - fuse.layout.left - fuse.layout.width;

        if(fuse.layout.rotation || formatSize?.asymetrical) {
          let r = (fuse.layout.rotation || 0) % 360;
          // We could flip the images with css, but it would break text on them. So we just rotate them.
          // Some images, such as honda blocks, are symetrical on Y axis, others as battery terminal are not.
          // So, the formula to rotate them symetrically is different for one case or the other
          if(formatSize?.asymetrical && !formatSize?.symetricalYAxis) {
            fuse.layout.rotation = 180 - r;
          } else {
            fuse.layout.rotation = - r;
          }
        }
      }
    });

    // Re-scale fuses according to their type/format real world dimension
    let scaleMultiplier = parseFloat(box.boxScaleMultiplier);
    scaleMultiplier = isNaN(scaleMultiplier) ? 1: scaleMultiplier;

    let scale = this.computeAverageFuseScale(fusesWithLayout) * scaleMultiplier;

    _.each(fusesWithLayout, fuse => this.changeSizeToScaleModel(fuse, scale))

    // Remove all margins, find bounding box containing all fuse boxes
    let minY = 100;
    let minX = 100;
    let maxY = 0;
    let maxX = 0;

    _.each(fusesWithLayout, ({ layout: {top, left, height, width, rotation }, kind, description}) => {
      let [x,y,x2,y2] = [left, top, left+width, top+height];

      if(rotation) {
        [x, y] = rotate(x+width/2, y+height/2, x, y, rotation);
        [x2, y2] = rotate(left+width/2, top+height/2, x2, y2, rotation);
      }

      minY = Math.min(minY, y, y2);
      minX = Math.min(minX, x, x2);
      maxY = Math.max(maxY, y, y2);
      maxX = Math.max(maxX, x, x2);
    });

    box.icons = this.buildIconsMarks(fusesWithLayout, maxX - minX, maxY- minY, scale);

    // Take icons into account when calculating the box size
    _.each(box.icons, ({ layout: {top, left, height, width }}) => {
      let [x,y,x2,y2] = [left, top, left+width, top+height];
      minY = Math.min(minY, y, y2);
      minX = Math.min(minX, x, x2);
      maxY = Math.max(maxY, y, y2);
      maxX = Math.max(maxX, x, x2);
    });

    let margin = Math.max(0.02*(maxX - minX), 0.02*(maxY - minY));
    // Crop everything leaving a % margin horizontally and vertically
    box.viewBox = {
      top: minY - margin,
      left: minX - margin,
      width: maxX - minX+2*margin,
      height: maxY - minY+2*margin,
    };

    box.viewBox.aspectRatio = box.viewBox.height / box.viewBox.width;

    // Find fuse ids with multiple options, and leave a mark so that they can be rendered split in 2 or 3
    let fusesById = _.groupBy(fusesWithLayout, 'id');
    _.each(fusesById, ( fuses, id) => {
      if(fuses.length > 1) {
        _.each(fuses, (f,i) => {
          f.option = i;
          f.totalOptions = fuses.length;
        })
      }
    });

    box.processed = true;

    // Transform the entire box to 0-100 percentages

    return box;
  }

  getSemanticIcons(semantics) {
    return _.uniq(_.compact((semantics || []).map(sem => getSemanticIcon(sem))));
  }

  buildIconsMarks(fuses, boxWidth, boxHeight, scaleInMM) {
    let fusesWithIcons = _.filter(fuses, f => {
      f.icons = this.getSemanticIcons(f.relatedEntities);
      return !f.npc && f.icons.length > 0;
    })

    // Icon height and width in %
    // Scale according to the real size of the diagram once it is cropped
    // Ensure that even the icon is "physically" at least the height of a micro to ensure in small diagrams with few
    // fuses that the icons are not too small;
    let scale = Math.max(boxWidth, boxHeight) / 100;
    let minScaleInMM = scaleInMM*6;
    const iconDiameter = Math.max(5*scale, minScaleInMM);
    const iconRadius = iconDiameter/2;

    const fixRotatedLayout = (layout, format) => {
      let { top, left, width, height, rotation, translateX, translateY} = layout;

      if(rotation === 90) {
        [left, top, width, height] = [left + width / 2, top + height / 2, height, width]

        layout = {
          ...  layout,
          left: left - width / 2 - width*parseFloat(translateY || '0')/100,
          top: top - height / 2 + height*parseFloat(translateX  || '0')/100,
          height,
          width,
          rotation: 0
        };
      }

      // The visible box defines, in relative % of the fuse image, what part is the visible to take into account for icons and collisions
      if(format?.visibleBox) {
        let { height: vH, width: vW, top: vT, left: vL } = format.visibleBox;

        if(rotation === 90) {
          [vT, vL, vW, vH] = [vL, 1 - (vT+vH), vH, vW];
        }

        layout = {
          ...  layout,
          top: layout.top + layout.height * vT,
          left: layout.left + layout.width * vL,
          width: layout.width * vW,
          height: layout.height * vH,
        }
      }

      return layout;
    };

    _.each(fuses, fuse => fuse.iconLayout = fixRotatedLayout(fuse.layout, fuseImageFormat(fuse)));

    const minDistanceToFusesOrIcon = ({centerX, centerY}, self, selfRadius = iconRadius) => {
      let min = Number.MAX_VALUE;

      for(let {layout, iconLayout, kind, id, description, npc} of fuses) {
        if(id === self.id)
          continue;

        layout = { ... layout, ...iconLayout };

        const notUsed = kind?.notUsed === true && kind?.type !== 'fuse';

        let d = distanceToBoxRotated(centerX, centerY, layout.left, layout.top, layout.width, layout.height, layout.rotation || 0);

        let otherOrNonInteractive = (!description && kind?.type !== 'fuse') || kind?.type === 'other' || kind?.type === 'connector' || notUsed;
        if (otherOrNonInteractive) {
          if (d < selfRadius * 0.7) {
            min = Math.min(min, 0);
          }
        } else {
          min = Math.min(min, d - selfRadius * 0.9);
        }
      }

      for(const {layout} of allIcons) {
        let iconX = layout.left + layout.width/2;
        let iconY = layout.top + layout.height/2;
        let iconDistance = Math.hypot(centerX - iconX, centerY - iconY) - (selfRadius*0.9+selfRadius*0.9);
        // If icon dis > certain distance, ignore it. We wann prioritize filling box gaps, avoiding icons only for collisions
        min = Math.min(min, (iconDistance > iconRadius*0.4) ? 100 : iconDistance);
      }

      // All things equal, things more than 1 icon radius away can be ignored and resolved by the direction bias (to make all top-left etc)
      return Math.min(min, iconRadius*0.75);
    };

    let allIcons = [];

    let iconsById = {};

    _.each(fusesWithIcons, fuse => {
      let { layout, iconLayout, id, kind, vertical } = fuse;

      // Do not repeat entity for the same fuse position (checked by the id)
      iconsById[id] = iconsById[id] || new Set();
      let entity = fuse.icons[0];
      if(iconsById[id].has(entity)) {
        return;
      }


      const format = fuseImageFormat(fuse);

      // const { top, left, width, height, rotation} = fixRotatedLayout(layout, format);
      const { top, left, width, height} = iconLayout || layout;

      const posTop = {centerX: left+width/2, centerY: top - iconRadius, orientation: 'top'};
      const posBottom = {centerX: left+width/2, centerY: top + height + iconRadius, orientation: 'bottom'};
      const posRight = {centerX: left+width+iconRadius, centerY: top + height/2, orientation: 'right'};
      const posLeft = {centerX: left-iconRadius, centerY: top + height/2, orientation: 'left'};

      const candidatePositions = [posTop, posBottom, posLeft, posRight];

      let aspectRatio = boxHeight / boxWidth;
      // For vertical boxes, try not to add icons on the top or bottom
      // Always prefer icons towards edges
      const isUp = (top+height/2) < 50;
      const isLeft = (left+width/2) < 50;
      let [prefUp, prefDown] = isUp ? [0.05, 0.0] : [0, 0.05];
      let [prefLeft, prefRight] = isLeft ? [0.05, 0.0] : [0, 0.05];
      const aspectRatioPreference = aspectRatio < 1 ? [prefUp+0.1, prefDown+0.1, prefLeft, prefRight] : [prefUp, prefDown, prefLeft+0.1, prefRight+0.1];

      let iconPos = null;1
      if(format?.iconInside) {
        iconPos = {
          centerX: left+width*(0.5+(format.iconOffsetX || 0)),
          centerY: top+height*(0.5+(format.iconOffsetY || 0)),
          ... (format.iconOverlay ? {overlay: true} : {orientation: 'center'}),
        };
        // Remove relay text and show icon only
        fuse.layout.hideText = true;
      } else {
        let posCandidates = candidatePositions.map((pos, i) => [pos, minDistanceToFusesOrIcon(pos, fuse), aspectRatioPreference[i]]);
        let [pos, minDistance] = _.maxBy(posCandidates, p => p[1] + p[2]);

        const hasHorizontalShape = width > (height * 1.4);

        let DEBUG_IS_SELECTED = _.map(this.state.selected, 'id').includes(id);

        if (minDistance > 0) { // If enough space t put the icon
          iconPos = pos;
        } else { // If not enough space, but there is space to move the icon over the fuse and fix it
          // The distance has some overlap margin, so ensure the icon is separated from the nearest collision.
          // The original position is some distance from the fuse, ensure that at least it is slightly overlapped
          // Also, the icon is shrinked to 80%, so get it 20% of diameter closer to the fuse
          let shrinkage = iconRadius * 0.2;
          let marginToMove = overlapDistance => Math.min(-Math.min(Math.min(width, height) * 0.2 + shrinkage, iconRadius), overlapDistance - shrinkage);
          // Adjust candidates
          posTop.centerY += -marginToMove(posCandidates[0][1]);
          posBottom.centerY += marginToMove(posCandidates[1][1]);
          posLeft.centerX += -marginToMove(posCandidates[2][1]);
          posRight.centerX += marginToMove(posCandidates[3][1]);

          posCandidates = candidatePositions.map((pos, i) => [pos, minDistanceToFusesOrIcon(pos, fuse, iconRadius*0.8), aspectRatioPreference[i]]);

          // DEBUG_IS_SELECTED && console.log(`ID ${id}, rad=${iconRadius.toFixed(1)}`, posCandidates.map(p => p[1].toFixed(1)).join(', '));

          ([pos, minDistance] = _.maxBy(posCandidates.filter(c => c[1] > -0.5), p => {
            let overlapWithFuse = Math.max(0.01, overlap(p[0].centerX, p[0].centerY, iconRadius * 0.8, fuse.layout.width, fuse.layout.height, fuse.layout.left, fuse.layout.top));
            DEBUG_IS_SELECTED && console.log(`OVERLAP ${id}`, (100*overlapWithFuse).toFixed(0) + '%', p[1].toFixed(1));
            return p[1] + p[2] - overlapWithFuse * iconDiameter;
          }) || []);


          if (pos) {
            pos.orientation = 'center';
            iconPos = pos;
          } else {
            console.log(`Could not place icon in box ${id}, no space to avoid collisions.`)
            return;
          }
        }
      }

      const sizeCorrection = iconPos.orientation === 'center' ? 0.8 : 1

      iconsById[id].add(entity);
      allIcons.push({
        layout: {
          height: iconDiameter * sizeCorrection,
          width: iconDiameter * sizeCorrection,
          top: iconPos.centerY - iconRadius * sizeCorrection,
          left: iconPos.centerX - iconRadius * sizeCorrection,
          orientation: iconPos.overlay ? 'overlay' : iconPos.orientation
        },
        fuseId: id,
        fuse: fuse,
        entity,
      })
    })

    return allIcons;
  }

  computeAverageFuseScale(fuses) {
    let scales = [];
    _.each(fuses, (fuse) => {
      let { layout: { height, width }, vertical } = fuse;

      let rule = fuseImageFormat(fuse);

      if (rule && width && rule.width) {
        if (vertical && !rule.isVertical) {
          scales.push(Math.min(height / rule.width, width / rule.height));
        } else {
          scales.push(Math.min(width / rule.width, height / rule.height));
        }
      }
    });
    return scales.length && (_.sum(scales) / scales.length);
  }

  changeSizeToScaleModel(fuse, scaleInMM) {
    // For each fuse, we discard the layout height and width, and only use the location (centerX and centerY)
    // of the diagram, and replace the dimensions with real world "at scale" dimensions.
    let { kind, layout, vertical, onlyBlockSelection } = fuse;

    let rule = fuseImageFormat(fuse);

    if (rule && layout.width) {
      let { width, height, left, top } = layout;
      let centerX = left + width / 2;
      let centerY = top + height / 2;

      let w, h;

      let oX = rule.offsetX || 0;
      let oY = rule.offsetY || 0;

      let pngH = (!onlyBlockSelection && rule.pngH) || 1;
      let pngW = (!onlyBlockSelection && rule.pngW) || 1;

      if(onlyBlockSelection) {
        h = height;
        w = width;
      } else {
        if (rule.width && scaleInMM) {
          h = rule.height * pngH * scaleInMM;
          w = rule.width * pngW * scaleInMM;
        } else {
          if (vertical && !rule.dontRotateVertical && !rule.isVertical) {
            h = width * pngH;
            w = height * pngW;
          } else {
            w = width * pngW;
            h = height * pngH;
          }
        }
      }

      // When rotated, to make the rotation like the original rectangle, no matter the image of the fuse,
      // we first rotate and afterward the image box is translated according to the image definition
      if (layout.rotation) {
        layout.translateX = `${oX * 100}%`;
        layout.translateY = `${oY * 100}%`;

        oX = 0;
        oY = 0;
      }

      layout.width = w;
      layout.height = h;
      layout.left = centerX - w / 2 + w * oX;
      layout.top = centerY - h / 2 + h * oY;
    }
  }
}

// For production
export default FuseboxDiagram

// For development
// function FB(props) {
//   return <FuseboxDiagram {... props}/>
// }
// export default FB
