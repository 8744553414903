import React, { useState } from 'react';
import _ from 'lodash';

import LivePage from './LivePage';

import ScriptRunsTable from '../components/script-runs/ScriptRunsTable';
import { IconButton } from '../components/common/IconButton';
import GridLayoutLeftBar from '../components/common/layout/GridLayoutLeftBar';
import LeftRightLayout from '../components/common/layout/LeftRightLayout';
import Ansi from 'ansi-to-react';
import ScriptParameterModal from '../components/script-runs/ScriptParameterModal';

function ScriptCard({ script: {description, displayName, parametersDefinition, version}, onClick  }) {
  let [expanded, setExpanded] = useState(false);

  return <div className={'bg-light rounded px-2 py-1 mb-1'} onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)}>
    <LeftRightLayout top>
      <div>
        <div> {displayName} </div>
        { expanded ? <div className={'position-fixed shadow mt-2 bg-white rounded p-2 small text-secondary'} style={{ width: '300px' }}> {description} <span
          className={'text-info small'}>v{version}</span></div> : null }
      </div>


      <div style={{flexShrink: 0, alignSelf: 'start'}} className={'no-wrap ml-1'}>
        <IconButton icon={'play_circle'} level={'success'} onClick={onClick}>Run</IconButton>
      </div>
    </LeftRightLayout>
  </div>;
}

export default class ScriptsManager extends LivePage {
  constructor(props) {
    super(props);

    this.fullScreen = true;

    this.scripts = this.service('services/scripts/runs');
    this.onRunUpdate = this.onRunUpdate.bind(this)
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchScripts().catch(alert);

    this.scripts.on('updated', this.onRunUpdate);
    this.scripts.on('created', this.onRunUpdate);
    this.scripts.on('removed', this.onRunUpdate);
    this.scripts.on('scriptProgress', this.onRunUpdate);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.scripts.removeListener('updated', this.onRunUpdate);
    this.scripts.removeListener('created', this.onRunUpdate);
    this.scripts.removeListener('removed', this.onRunUpdate);
    this.scripts.removeListener('scriptProgress', this.onRunUpdate);
  }

  onRunUpdate(update) {
    if(update && update._id) {
      const {_id, progressText, progressData} = update;
      let match = _.find(this.state.runs, { _id });
      if (match && progressText) {
        match.progressText = progressText;
        match.progressData = progressData;
        this.setState({ runs: this.state.runs });
        return;
      }
    }

    this.fetchScripts().catch(alert);
  }

  async fetchScripts() {
    let {availableScripts, runningScripts} = await this.scripts.find();
    this.setState({availableScripts, runs: runningScripts})
  }

  async runScript(script, params) {
    if(!params) {
      params = {};
      if(script.parametersDefinition?.length) {
        this.openModal(<ScriptParameterModal script={script} onSubmit={params => {
          this.runScript(script, params);
          this.closeModal();
        }} onCancel={() => this.closeModal()}/>, {title: `Run script: ${script.name}`});
        return;
      }
    }
    await this.scripts.create({scriptName: script.name, parameters: params});
  }

  async cancelScript(runId) {
    await this.scripts.remove(runId);
  }

  async showLog(runId) {
    let script = await this.scripts.get(runId);

    if (script) {
      this.openModal(<div>
      <pre className={'p-2 text-white small'} style={{ overflow: 'auto', background: '#000' }}>
            <Ansi>{script.log.join('\n')}</Ansi>
      </pre>
      </div>);
    }
  }

  async showOutput(runId) {
    let script = await this.scripts.get(runId);

    if (script) {
      let output = _.isString(script.output) ? script.output : JSON.stringify(script.output, null, 2);

      this.openModal(<div>
      <pre className={'p-2 text-white text-dark small'} style={{ overflow: 'auto' }}>
            {output}
      </pre>
      </div>);
    }
  }

  renderPageBody() {
    let {filter, runs, availableScripts} = this.state;

    if(!runs) {
      return <div className={'p-5 text-center mt-2'}>Loading runs...</div>;
    }

    let running = _.filter(runs, r => ['starting', 'running'].includes(r.state));
    let past = _.difference(runs, running);

    /** @type {LiveScriptSignature[]} */
    let available = availableScripts;

    let re = filter && new RegExp(_.escapeRegExp(filter), 'i');
    let filteredScripts = _.map(available, ({group, scripts}) => {
      return {group, scripts: _.filter(scripts, s => !filter || s.name.match(re) || s.displayName.match(re) || s.description.match(re))}
    });

    const onShowLog = id => this.showLog(id);
    const onShowOutput = id => this.showOutput(id);

    const onRerun = async (id, params) => {
      let script = _.find(_.flatMap(availableScripts, s => s.scripts), s => s.name === id);
      if(script) {
        await this.runScript(script, params);
      } else {
        debugger;
        alert(`Script ${id} not found`);
      }
    }

    return  <GridLayoutLeftBar className={'bg-secondary'}>
      <div style={{width: '400px'}} className={'p-3'}>
        <LeftRightLayout>
          <input type={'text'} value={filter || ''} placeholder={'Search available scripts...'}
                 className={'form-control form-control-sm bg-light-white'}
                 onChange={(e) => this.setState({filter: e.target.value}) }
          />
        </LeftRightLayout>
        {
          _.map(filteredScripts, ({scripts, group}) => {
            return <div key={group}>
              <div className={'small text-white my-2 text-uppercase'}>{group} {filter ? `(${scripts.length})` : null}</div>
              {
                _.map(scripts, (script) => <ScriptCard key={script.name} script={script}
                                                      onClick={() => this.runScript(script)}/>)
              }
            </div>
          })
        }
      </div>

      <div className={'p-3 bg-white'}>
        <h5>Running now</h5>
        {
          running.length
            ?
        <ScriptRunsTable runs={running} onCancel={id => this.cancelScript(id)} onRerun={onRerun} onShowLog={onShowLog} onShowOutput={onShowOutput}/>
            :
            <div className={'text-left text-secondary'}>There are no scripts running at the moment</div>
        }

        {/*<pre>{JSON.stringify(available, null, 2)}</pre>*/}

        <h5 className={'mt-4'}>Archive of past runs</h5>
        <ScriptRunsTable runs={past} onShowLog={onShowLog} onShowOutput={onShowOutput} onRerun={onRerun}/>
      </div>
    </GridLayoutLeftBar>
  }
}
