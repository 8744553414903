import React from 'react'


export const legoStateColors = {
  published: 'success',
  inprogress: 'warning',
  unpublished: 'danger',
}

const compactStates = {
  'published': '✅',
  'inprogress': '🚧',
  'unpublished': '⛔',
};

export function LegoStateBadge({ state, compact }) {
  state = state || 'published';
  let color = legoStateColors[state] || 'dark';
  let text = compact ? (compactStates[state] || state) : state;
  return <span title={state} className={`badge badge-${color}`}>{text}</span>;
}
