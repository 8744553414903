import { ImagePreview } from '../../images/ImagePreview';
import { useContext, useState } from 'react';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import TirePressureLegoPreview from './TirePressureLegoPreview';
import { IconButton } from '../../common/IconButton';
import { SwitchInput } from '../../common/SwitchInput';

const sampleRes = {
  "message": "{\n  \"pressuresByTire\": [\n    {\n      \"tires\": [\"185/65R15 88H\"],\n      \"pressures\": [\n        {\n          \"wheels\": \"front\",\n          \"pressure\": {\n            \"kpa\": 240,\n            \"psi\": 35\n          },\n          \"condition\": \"loadNormal\"\n        },\n        {\n          \"wheels\": \"rear\",\n          \"pressure\": {\n            \"kpa\": 240,\n            \"psi\": 35\n          },\n          \"condition\": \"loadNormal\"\n        }\n      ]\n    },\n    {\n      \"tires\": [\"T115/70R15 90M\"],\n      \"pressures\": [\n        {\n          \"wheels\": \"spare\",\n          \"pressure\": {\n            \"kpa\": 420,\n            \"psi\": 60\n          }\n        }\n      ]\n    }\n  ],\n  \"labelId\": \"LSGEN5307PD040874 1GA291 6918\"\n}",
  "usage": {
    "prompt_tokens": 1267,
    "completion_tokens": 208,
    "total_tokens": 1475
  },
  "responseTime": 8201
};

export async function parseTirePressureContribution({ contribution, cropCorners, useClaude, useGemini, page }) {
  let imgUrl = contribution.data?.images?.[0]?.url;

  let query = {
    imageUrl: imgUrl,
    // imageUrl: getImageThumbnailMedium(imgUrl),
    context: contribution.vehicleContext,
    cropCorners,
    useClaude,
    useGemini
  };

  let response = await page.service(`services/ai/read-tire-pressure-label`).find({ query: query });

  if(response.message) {
    try {
      response.data = JSON.parse(response.message);

      return response;
    } catch(e) {
      throw new Error("Error parsing response: "+e.message);
    }
  }
}

export default function ParseTirePressureModal({contribution, onClose}) {
  const [res, setRes] = useState();
  const [parsing, setParsing] = useState();
  const [resId, setResId] = useState();
  const [error, setError] = useState();
  const [useClaude, setUseClaude] = useState(false);

  const {page} = useContext(LegoAdminPageContext);

  let imgUrl = contribution.data?.images?.[0]?.url;


  let parseImage = async () => {
    try {
      setParsing(true);
      let res = await parseTirePressureContribution({ contribution, useClaude, page});
      console.log(res);
      setRes(res);
      setResId(new Date().valueOf());
      setError(null);
    } catch(e) {
      setError("ERROR: "+e.message);
    } finally {
      setParsing(false);
    }
  };

  useAsyncEffect(parseImage, [contribution._id, useClaude]);

  let content =  <h5>{parsing ? 'Parsing with AI...': 'Done'}</h5>;
  if(res) {
    content = <>
      <h5>
          Response in {(res.responseTime / 1000).toFixed(1)}s<span className={'text-info'}>
          &nbsp;[{res.usage.total_tokens} tokens]</span>
          &nbsp;<IconButton icon={'photo_filter'} disabled={parsing} title="Reparse" onClick={parseImage}>Reparse</IconButton>
          {parsing ? ' Parsing with AI...' : null }
        <span className={'zoom-75 ml-4 align-middle'}>
          <SwitchInput value={useClaude} onChange={setUseClaude} label={'Use Claude?'}/>
          </span>
      </h5>

      <div>
        Label: <span className={'badge badge-primary'}>{res.data.labelId}</span>
      </div>
      <TirePressureLegoPreview data={res.data}/>
      <hr/>
      <pre className={'small mt-4 p-1 bg-light text-secondary'} style={{maxHeight: '400px'}}>{res.message}</pre>
    </>
  } else if(error) {
    content = <div className={'alert alert-danger'}>{error}</div>
  }

  return <div className={'row no-gutters'} style={{minWidth: '50vw'}}>
    <div className={'col-6'}>
      <ImagePreview fluidContain zoom={true} url={imgUrl}/>
    </div>
    <div className={'col-6 pl-3'}>
      {content}
    </div>
  </div>
}

