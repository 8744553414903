import _ from 'lodash';
import React, { Component } from 'react';

import LivePage from './LivePage';
import BatchTaskEditor from "../components/dataprocess/BatchTaskEditor";
import TaskEditorManualDateExtraction from "../components/tasks/TaskEditorManualDateExtraction";
import TaskEditorManualUsersUpload from "../components/tasks/TaskEditorManualUsersUpload";
import TaskEditorManualLegoOil from "../components/tasks/TaskEditorManualLegoOil";
import submenu from './menus/submenu-tasks';
import TaskEditorManualLegoOilQuantity from "../components/tasks/TaskEditorManualLegoOilQuantity";
import TaskEditorManualFuseboxExtraction from '../components/tasks/TaskEditorManualFuseboxExtraction';
import TaskEditorManualFuseboxLocationExtraction from '../components/tasks/TaskEditorManualFuseboxLocationExtraction';
import TaskEditorImageUserUpload from '../components/tasks/TaskEditorImageUserUpload';
import TaskEditorLegoEdit from '../components/tasks/TaskEditorLegoEdit';
import TaskEditorImgFuseboxClustering from '../components/tasks/TaskEditorImgFuseboxClustering';
import TaskEditorManualWarningLightsExtraction from '../components/tasks/TaskEditorManualWarningLightsExtraction';
import TaskEditorWarningLightsClustering from '../components/tasks/TaskEditorWarningLightsClustering';
import TaskEditorWarningLightsAssignSemantics from '../components/tasks/TaskEditorWarningLightsAssignSemantics';
import TaskEditorManualLegoTirePressure from '../components/tasks/TaskEditorManualLegoTirePressure';
import TaskEditorTirePressureParse from '../components/tasks/TaskEditorTirePressureParse';

export default class DataTaskBatchWork extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.submenu = submenu;
    this.fullScreen = true;

    this.tasksService = this.service('services/data-tasks');
    this.notificationsService = this.service('/services/common/backend-notifications');
    this.handleBackendNotification = this.handleBackendNotification.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.notificationsService.on('backend-notification', this.handleBackendNotification);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.notificationsService.removeListener('backend-notification', this.handleBackendNotification);
  }

  handleBackendNotification({ level, userLevel, userId, title, message }) {
    console.log('llego notificacion')
    if (userLevel === 'admin' && window.user.level !== 'admin') {
      return;
    }

    if (userId && window.user.id !== userId) {
      return;
    }

    this.showNotification({level, title, body: message, time: new Date()});

    console.log(level, title, message);
  }

  async loadBatch() {
    const batchId = this.props.match.params.batchId;
    let query = {};

    let hasMultipleBatches = batchId.includes('_+_');

    if(hasMultipleBatches) {
      query = {
        batchId: {$in: batchId.split('_+_')},
        state: {$ne: 'complete'}
      };
    } else {
      query = {batchId};
    }

    let tasks = await this.tasksService.find({query});
    if(hasMultipleBatches && ['imgFuseboxClustering'].includes(tasks[0].typeId)) {
      tasks = _.sortBy(tasks, ['context.0.modelId', 'createdAt', '_id']);
    } else if(tasks.length && ['manualUsersUpload', 'imageUserUpload', 'warningLightsAssignSemantics', 'warningLightsClustering', 'imgFuseboxClustering', 'tirePressureParse'].includes(tasks[0].typeId)) {
      tasks = _.sortBy(tasks, ['createdAt', '_id'])
    } else {
      tasks = _.sortBy(tasks, 'context.0.modelId', 'context.0.year')
    }
    return tasks;
  }

  getTaskComponent(task, tasks, batchEditor) {
    switch (task.typeId) {
      case  'manualExtractDate':
        return <TaskEditorManualDateExtraction {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualExtractOil':
        return <TaskEditorManualLegoOil {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualExtractTirePressure':
        return <TaskEditorManualLegoTirePressure {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualExtractOilQuantity':
        return <TaskEditorManualLegoOilQuantity {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualExtractFusebox':
        return <TaskEditorManualFuseboxExtraction {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualExtractFuseboxLocation':
        return <TaskEditorManualFuseboxLocationExtraction {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualExtractWarningLights':
        return <TaskEditorManualWarningLightsExtraction {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'manualUsersUpload':
        return <TaskEditorManualUsersUpload {...this.props} task={task} tasks={tasks} batchEditor={batchEditor}/>;
      case 'fuseboxParseTable':
      case 'fuseboxFormat':
      case 'fuseboxRevision':
      case 'fuseboxSearchTable':
      case 'fuseboxSearchDiagram':
        return <TaskEditorLegoEdit {...this.props} task={task} key={task._id} tasks={tasks} batchEditor={batchEditor}/>;
      case 'imageUserUpload':
        return <TaskEditorImageUserUpload {...this.props} task={task} key={task._id} tasks={tasks} batchEditor={batchEditor}/>;
      case 'imgFuseboxClustering':
        return <TaskEditorImgFuseboxClustering {...this.props} task={task} key={task._id} tasks={tasks} batchEditor={batchEditor}/>;
      case 'warningLightsClustering':
        return <TaskEditorWarningLightsClustering {...this.props} task={task} key={task._id} tasks={tasks} batchEditor={batchEditor}/>;
      case 'warningLightsAssignSemantics':
        return <TaskEditorWarningLightsAssignSemantics {...this.props} task={task} key={task._id} tasks={tasks} batchEditor={batchEditor}/>;
      case 'tirePressureParse':
        return <TaskEditorTirePressureParse {...this.props} task={task} key={task._id} tasks={tasks} batchEditor={batchEditor}/>;
      default:
        return <div className={'text-white full-screen-center'}>Task typeId "{task.typeId}" not supported.</div>;
    }
  }

  renderPageBody() {
    return <BatchTaskEditor user={this.getLoggedUserSignature()}
                     loadBatchTasks={this.loadBatch.bind(this)}
                     getTaskComponent={this.getTaskComponent.bind(this)}
                     onCurrentTaskChange={index => this.setUrlParam('taskIndex', index)}
                     initialTask={this.getUrlParam('taskIndex')}
    />
    ;
  }
}
