import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import LegoContextSummary from '../LegoContextSummary';
import LegoEditButton from '../LegoEditButton';

export function FuseboxTemplateReferences({ templateId }) {
  let { page } = useContext(LegoAdminPageContext);

  let [references, setReferences] = useState(null);

  useEffect(() => {
    if(templateId) {
      page.service('services/legos').find({
        query: {
          // $or: [{ type: 'fuseboxTemplate' }, { type: 'fusebox' }],
          'data.templateId': templateId,

          $select: {
            'context': 1,
            'data.templateId': 1,
          },
        }
      }).then(res => setReferences(res));
    }
  }, [templateId]);

  let results = <div className={'text-secondary'}>Loading references...</div>;

  if (references) {
    if(references.length) {
      results = <table className={'algin-middle'}><tbody>
        {_.map(references, fusebox => {
          const boxIndex = _.findIndex(fusebox.data, { templateId });

          return <tr key={fusebox._id} className={'border-top border-secondary'}>
            <td><LegoContextSummary className={'mt-1'} context={fusebox.context}/></td>
            <td className={'small text-secondary pr-2'}>
              {/*<a target={'_blank'} href={`/legos/fusebox?editing="${fusebox._id}"&openedFusebox=${boxIndex}`}></a>*/}
              <LegoEditButton legoId={fusebox._id} editorProps={{openedFusebox: boxIndex}} extraQuery={`openedFusebox=${boxIndex}`} lego={{type: 'fusebox', context: fusebox.context}}>
                {fusebox._id}
              </LegoEditButton>
            </td>
            <td><span className={'badge badge-secondary'}>#{boxIndex}</span></td>
          </tr>;
        })}
      </tbody>
      </table>;
    } else {
      results = <div className={'text-secondary p-2 text-center'}>Didn't find any fusebox using this template</div>
    }
  }

  return <div className={'bg-light rounded p-2'} style={{ width: '600px', maxHeight: '300px', overflowY: 'auto', overflowX: 'auto' }}>
    {results}
  </div>;
}
