import _ from 'lodash';

export function buildFuseWithTemplate(fuse, templateFuse) {
  // cloneDeep templateFuse in case there are multiple fuses with the same id so they don't point to the same objects
  // kind usually has some fields in template (type/subtype) and other fields in table (amp)
  let { kind, relatedEntities, ...template } = _.pickBy(_.cloneDeep(templateFuse), (v, k) => !_.isEmpty(v) || v === false); // || k == 'empty');
  let cleanFuse = _.pickBy(fuse, (v,k) => !_.isEmpty(v) || v === false)

  let cleanKind = { ..._.pickBy(kind, (v,k) => v || v === false || k === 'amp') };

  cleanKind = { ... cleanKind, ..._.pickBy(cleanFuse.kind, (v,k) => v || v === false || k === 'amp') };

  // Remove empty props from match
  const res = { ...template, ...cleanFuse, kind: cleanKind, id: templateFuse.id };

  // Template could explicitly say notUsed and fuse have amp but
  if(res.kind.amp && res.kind.notUsed && res.kind.type === 'fuse')
    delete res.kind.notUsed;

  // console.log(fuse,templateFuse, res);
  return res;
}

export function refactorFuseboxToUseTemplate(fusebox, template) {
  let newFusebox = _.cloneDeep(fusebox);

  let templateById = _.keyBy(template.fuses, 'id');

  for(const f of newFusebox.fuses) {
    // Layout will only come from the template
    delete f.layout;

    let templateMatch = templateById[f.id];
    if(templateMatch) {
      for(const field of ['format', 'type', 'variation']) {
        if(templateMatch.kind[field] === f.kind[field]) {
          delete f.kind[field];
        }
      }
    }
  }

  for(const field of ['boxRotation', 'boxScaleMultiplier', 'boxAspectRatio', 'flipHorizontal']) {
    if(newFusebox[field] === template[field]) {
      delete newFusebox[field];
    }
  }

  return newFusebox;
}

export function extendFusesWithTemplate(fuses, templateFuses, keepReferences = false, renameMap = null) {
  templateFuses = templateFuses || [];

  let id = fuse =>  renameMap?.[fuse.id] || fuse.id;

  let templateFusesById = _.keyBy(templateFuses, 'id');
  let fusesById = _.groupBy(fuses, id);

  let allFuses = [];
  _.each(templateFuses, (templateFuse) => {
    const fuses = fusesById[templateFuse.id];
    if(fuses) {
      for(const f of fuses) {
        if(keepReferences) {
          const unmappedId = renameMap?.[f.id] ? f.id : undefined;
          allFuses.push({
            ...buildFuseWithTemplate(f, templateFuse), unmappedId, mergedFromFuse: f, mergedFromTemplate: templateFuse
          });
        } else {
          allFuses.push(buildFuseWithTemplate(f, templateFuse))
        }
      }
    } else {
      allFuses.push(keepReferences ? {... templateFuse, mergedFromTemplate: templateFuse} : templateFuse);
    }
  })

  for(const f of (fuses || [])) {
    if(!templateFusesById[id(f)]) {
      const unmappedId = renameMap?.[f.id] ? f.id : undefined;
      allFuses.push(keepReferences ? {... f, id: id(f), mergedFromFuse: f, unmappedId } : {... f, id: id(f)});
    }
  }

  return allFuses;
}

export function extendFuseboxWithTemplate(fusebox, templateFusebox) {
  fusebox.boxAspectRatio = templateFusebox.boxAspectRatio;

  return {
    ... fusebox,
    backgroundColor: fusebox.backgroundColor || templateFusebox.backgroundColor,
    imageClusters: fusebox.imageClusters || templateFusebox.imageClusters,
    boxScaleMultiplier: fusebox.boxScaleMultiplier || templateFusebox.boxScaleMultiplier,
    boxRotation: fusebox.boxRotation || templateFusebox.boxRotation,
    flipHorizontal: fusebox.flipHorizontal || templateFusebox.flipHorizontal,
    fuses: extendFusesWithTemplate(fusebox.fuses, templateFusebox.fuses || {}, false, fusebox.templateRenameMap)
  };
}

function getAllFusesAndDescriptions(fuseboxData) {
  const allDescriptions = [];
  const allFuses = [];
  for (const { fuses, useAcronyms } of fuseboxData) {
    for (const fuse of fuses) {
      allDescriptions.push(useAcronyms ? fuse.extendedDescription || fuse.description : fuse.description);
      allFuses.push(fuse);
    }
  }
  return { allDescriptions, allFuses };
}

export async function parseFuseboxDescriptions(fuseboxData, nlpService, language) {
  const { allFuses, allDescriptions } = getAllFusesAndDescriptions(fuseboxData);
  const res = await nlpService.find({ query: { operation: 'parseFusebox', params: { language, descriptions: allDescriptions } } });

  for (let i = 0; i < res.length; i++) {
    allFuses[i].relatedEntities = res[i];
  }
}

export async function parseFuseboxDescriptionsInferringLanguage(fuseboxData, nlpService, parsePTWithLLM = false) {
  const { allFuses, allDescriptions } = getAllFusesAndDescriptions(fuseboxData);
  const [parseEs, parseEn, parsePt] = await Promise.all([
    nlpService.find({ query: { operation: 'parseFusebox', params: { language: 'es', descriptions: allDescriptions } } }),
    nlpService.find({ query: { operation: 'parseFusebox', params: { language: 'en', descriptions: allDescriptions } } }),
    parsePTWithLLM ? nlpService.find({ query: { operation: 'parseFusebox', params: { language: 'pt', descriptions: allDescriptions } } }) : undefined,
  ]);

  const countEs = _.compact(_.flatten(parseEs)).length;
  const countEn = _.compact(_.flatten(parseEn)).length;
  const countPt = _.compact(_.flatten(parsePt)).length;

  let res = parseEs;
  if (countEn > countEs) {
    res = parseEn;
  }
  if(countPt > countEs && countPt > countEn) {
    res = parsePt;
  }

  let modified = false;
  for (let i = 0; i < res.length; i++) {
    if (!_.isEqual(allFuses[i].relatedEntities, res[i])) {
      allFuses[i].relatedEntities = res[i];
      modified = true;
    }
  }

  return modified;
}
