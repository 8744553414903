/* eslint-disable */
// TODO: Repeated code from TaskEditorTirePressureParse
import _ from 'lodash';

export function normalizePressures({ pressuresByTire, tires, pressures }) {
  if (!pressuresByTire && pressures) {
    pressuresByTire = [{ tires, pressures }];
  }
  // Normalize pressuresByTire by forming and sorting a list of tuples [tire, wheel, condition, pressure] and comparing them
  let normalized = [];
  for (let { tires, pressures } of (pressuresByTire || [])) {
    for (let groupTire of (tires?.length ? tires : ['ALL'])) {
      groupTire = groupTire.replaceAll(' ', '');
      for (let { wheels, pressure, tire, condition } of pressures) {
        normalized.push([tire || groupTire, wheels, condition || 'loadNormal', pressure?.psi || pressure?.kpa || pressure?.bar]);
      }
    }
  }
  return _.sortBy(normalized, (tuple) => JSON.stringify(tuple));
}

export function haveEqualInformation(a, b) {
  // Compare the normalized data from two legos
  if (!a || !b) {
    return false;
  }
  let normalized1 = normalizePressures(a);
  let normalized2 = normalizePressures(b);
  return _.isEqual(normalized1, normalized2);
}

export const normalizeLabel = s => (s || '').toString().replaceAll('O', '0').toLowerCase().replace(/[\s.,]/g, '');

export function includesLabelId(list, labelId) {
  return _.map(list, normalizeLabel).includes(normalizeLabel(labelId));
}

export function normalizeTireSpec(tire) {
  return tire
    .replace(/(\d{3}\/\d{2})\/([12]\d)\b/ig, '$1 R$2')
    .replace(/([RD]) ?(1\d)C?/ig, '$1$2')
    .replace(/([RD]) ?(1\d)C?/ig, '$1$2')
    .replace(/(\d{3}\/\d\d)\/(1[45678])[\b$]/ig, '$1 R$2')
    .replace(/\b[A-Z]([RD][12]\d)/ig, '$1') // Remove speed rating before construction
    .replace(/(\S)([RD][12]\d)/ig, '$1 $2') // Separate R15 from previous strings


   .replace(/P([12]\d\d)\b/ig, '$1') // Remove PXXX that denotes Passenger wheel
   .replace(/ ?\(SNOW\)/ig, '') // Remove snow info
   .replace(/[ \-][68][ \-]?PR(\b|$)/ig, '') // Remove Ply Rating
   .replace(/(([789]\d|1[012345]\d)[A-Z]?\/)?([789]\d|1[012345]\d) ?[A-Z]$/ig, '') // Remove load index and speed rating such as 89H/89V
   .replace(/ \d\d[HVQ]/ig, '')
   .replace(/\s+/ig, ' ')
            .trim();
}

export function createTireLabelLegoFromContribution(parsedLabelData, contribution, user) {
  let data =  {... parsedLabelData, countries: _.compact([contribution.vehicleContext.countryCode])}
  if(parsedLabelData.labelId) {
    data.labelIds = [parsedLabelData.labelId];
    delete data.labelId;
  }
  delete data.model;
  delete data.date;

  const newLego = {
    semantics: ['PRESION_INFLADO'],
    intentions: ['spec'],
    type: 'data',
    context: [{
      modelId: contribution.vehicleContext.modelId,
      year: contribution.vehicleContext.year
    }],
    data,
    labels: [],
    locales: ['es', 'en', 'pt'],
    source: [{
      type: 'contribution',
      id: contribution._id.toString()
    }],
    state: 'unpublished'
  }

  if(user) {
    newLego.createdBy = user;
    newLego.updatedBy = user;
  }

  return newLego;
}

export function getUpdateAddingContributionToTireLabelLego(lego, contribution, labelIdToAdd = null) {
  let update = {
    // Add the contribution as a source
    $push: { source: { type: 'contribution', id: contribution._id.toString() } } ,

    // Add country to set of countries in data.country
    $addToSet: {
      "data.countries": contribution.vehicleContext.countryCode,
      "context": {modelId: contribution.vehicleContext.modelId, year: contribution.vehicleContext.year }
    }

    // Add modelId and year to contexts
  };
  if(labelIdToAdd) {
    update.$addToSet = { 'data.labelIds': labelIdToAdd };
  }
  return update;
}
