import React, { Component } from 'react';

import LivePage from './LivePage';
import { FUSE_SIZES } from '../components/common/FuseboxData.mjs';
import _ from 'lodash';
import { LeftRightBox } from '../../../components/common/LeftRightBox';
import LeftRightLayout from '../components/common/layout/LeftRightLayout';
import JsonGUIEditor from '../components/common/editors/JsonGUIEditor';


class TestFuseboxCalibration extends LivePage {
  constructor(props, serviceEndpoint) {
    super(props);

    let type= this.getUrlParam('type');
    let format= this.getUrlParam('format');

    this.state = {
      ...this.state,

      selectedType: type || 'fuse',
      selectedFormat: format || 'mini',
      amp: ''
    };
  }

  select(type, format) {
    this.setUrlParams({ type, format });
    this.setState({ selectedType: type, selectedFormat: format });
  }

  renderPageBody() {
    let { selectedType, selectedFormat, amp } = this.state;

    let expandedSizes = _.flatMap(FUSE_SIZES, (subsizes, name) => _.map(subsizes, (config, sizeName) => [name, sizeName, config]));


    let knownColumns = ['height', 'width', 'pngW', 'pngH', 'offsetX', 'offsetY'];

    let selected = FUSE_SIZES[selectedType][selectedFormat];

    let fuse;
    if (selected) {
      let {
        width, height, pngW, pngH, offsetX, offsetY, visibleBox,
        iconOffsetX, iconOffsetY, iconOverlay, iconInside, iconSize, iconHorizontal,
        asymetricalYAxis, rotation, inverted
      } = selected;

      width = width || 0.5;
      height = height || 0.25;
      pngW = pngW || 1;
      pngH = pngH || 1;
      offsetX = offsetX || 0;
      offsetY = offsetY || 0;


      let S = 100 / Math.max(width * pngW, height * pngH) * 0.7;

      let w = width * S;
      let h = height * S;
      console.log('SIZE', w, h);

      let fuseSize = {
        width: w + '%',
        height: h + '%',
        left: ((100 - w) / 2) + '%',
        top: ((100 - h) / 2) + '%',
        zIndex: 1
      };

      let spriteW = w * pngW;
      let spriteH = h * pngH;

      let fuseSpriteSize = {
        width: spriteW + '%',
        height: spriteH + '%',
        left: ((100 - spriteW * (1 - offsetX*2)) / 2) + '%',
        top: ((100 - spriteH * (1 - offsetY*2)) / 2) + '%',
        zIndex: 1
      };


      let visibleBoxSize;
      if (visibleBox) {
        visibleBoxSize = {
          width: spriteW*visibleBox.width + '%',
          height: spriteH*visibleBox.height + '%',
          left: ((100 - spriteW * (1 - offsetX*2 -visibleBox.left*2)) / 2) + '%',
          top: ((100 - spriteH * (1 - offsetY*2 - visibleBox.top*2)) / 2) + '%',
          zIndex: 1
        };
      }

      if(selectedFormat.endsWith('-vertical')) {
        selectedFormat = selectedFormat.replace('-vertical', ' vertical');
      }

      fuse = <>
        <div className={`fuse fuse-${selectedType}  fuse-amp-${amp} ${selectedType}-${selectedFormat}`}
             style={{ ...fuseSpriteSize, border: '1px solid yellow', position: 'absolute' }}>
          <div className="inner">
            <div className="amp">5<span>A</span></div>
          </div>
        </div>
        <div style={{ ...fuseSize, border: '1px solid cyan', position: 'absolute' }}/>
        {visibleBoxSize ? <div style={{ ...visibleBoxSize, border: '1px solid red', position: 'absolute' }}/> : null}
      </>;
    }

    return <div className={'d-flex'}>
      <table className={'table table-sm'} style={{width: 'calc(100% - 450px)'}}>
        <thead>
        <tr className={'bg-dark text-white'} style={{position: 'sticky', top: 0}}>
          <th>Type</th>
          <th>Format</th>
          {
            _.map(knownColumns, (column) => <th className={'small text-info'} key={column}>{column}</th>)
          }
          <th>Other</th>
        </tr>
        </thead>
        <tbody>
        {
          _.map(expandedSizes, ([name, sizeName, config]) => {
            let other = _.omit(config, knownColumns);

            return <tr className={'clickable-row'} onClick={() => this.select(name, sizeName)}>
              <td>{name}</td>
              <td>{sizeName}</td>
              {_.map(knownColumns, (column) => <td className={'small text-secondary text-right'}>
                {JSON.stringify(config[column], true, 2)}
              </td>)}

              <td className={'small text-secondary text-break-anywhere'}>
                <JsonGUIEditor value={other} onChange={(newConfig) => this.setState({
                  selectedType, selectedFormat, config: newConfig
                })}/>
              </td>
            </tr>;
          })}
        </tbody>
      </table>

      <div
        style={{ width: '400px', height: '400px', background: '#BBB', position: 'fixed', right: '0px', flexShrink: 0 }}
        className={'fusebox-diagram'}>
        <div className={'fusebox'} style={{ width: '100%', height: '100%', position: 'relative' }}>
          {fuse}
        </div>
      </div>
    </div>;
  }
}

// For development
function FB(props) {
  return <TestFuseboxCalibration {...props}/>;
}

export default FB;
