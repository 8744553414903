import React, { Component, useContext, useState } from 'react';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import _ from 'lodash';
import { DataTaskTypeSelector } from '../tasks/DataTaskTypeSelector';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import { SwitchInput } from '../common/SwitchInput';

export default function ModalCreateTasksFromTiresLabelPhoto({ contributions, onCancel }) {
  let { page } = useContext(LegoAdminPageContext);

  let [taskType, setTaskType] = useState('tirePressureParse');
  let [batchName, setBatchName] = useState('batchTirePressureParse-beta2');
  let [byModel, setByModel] = useState(true);
  let [skipRepeated, setSkipRepeated] = useState(true);

  const createTasks = async () => {
    const params = {
        batchPrefix: batchName,
        taskTypeId: taskType,
        contributionIds: _.map(contributions, '_id'),
        batchByModel: byModel,
        skipRepeated: skipRepeated
    };

    const res = await page.service('/services/data-tasks/batches').create(params);
    if(res?.length) {
      alert(`Created ${res?.length || 0} tasks!`);
      onCancel();
    } else {
      alert(`Did not create any task. Maybe the batch ID already exists?`);
    }
  };

  return <div>
    <h4>Create tasks for {contributions.length} contributions</h4>

    <div className={'py-3'}>
      Batch name:
      <SingleTextEditor small value={batchName} onChange={setBatchName} className={'mb-2'}/>

      <div>
      <SwitchInput value={byModel} onChange={setByModel}>Create one different batch for each Model Id</SwitchInput>
      </div>

      <div>
      <SwitchInput className={'mt-2'} value={skipRepeated} onChange={setSkipRepeated}>
        Skip contributions that already have a task with batch name "{batchName}"
      </SwitchInput>
      </div>
    </div>
    <span className={'btn btn-primary'} onClick={() => page.runAsync(createTasks)}>
      Try to create {contributions.length} tasks
    </span>
  </div>;
}
