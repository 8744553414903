import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Icon } from '../common/Icon';
import { IconButton } from '../common/IconButton';
import LegoContextSummary from '../lego/LegoContextSummary';
import { computeMatrix3DCss, PerspectiveTransform } from './PerspectiveMath';

const colors = ['#89fd0d99', '#0de9fd99', '#0d6efd99', '#fd0db999'];

function isClockwise(points) {
  // https://stackoverflow.com/questions/1165647/how-to-determine-if-a-list-of-polygon-points-are-in-clockwise-order
  let area = 0;
  const n = points.length;
  for (let i = 0; i < n; i++) {
    let j = (i + 1) % n;
    area += (points[i].x * points[j].y) - (points[j].x * points[i].y);
  }
  return area < 0;
}

function computeCssMatrix(pointsPercent, imageWidth, imageHeight, displayWidth, displayHeight) {
  // If the points are not in clockwise order, reverse them. This is because the css transform matrix maps
  // to a clockwise order of points
  if(isClockwise(pointsPercent)) {
    pointsPercent = [pointsPercent[0], ... pointsPercent.slice(1).reverse()];
  }
  let src = _.flatten(pointsPercent.map(p => [p.x * imageWidth / 100, p.y * imageHeight / 100]));

  let [ax, ay, bx, by, cx, cy, dx, dy] = src;
  let newWidth = Math.min(Math.hypot(ax - bx, ay - by),Math.hypot(cx - dx, cy - dy));
  let newHeight = Math.min(Math.hypot(bx - cx, by - cy),Math.hypot(dx - ax, dy - ay));

  let zoom = Math.min(displayWidth/newWidth, displayHeight/newHeight);
  newWidth *= zoom;
  newHeight *= zoom;

  let dst = [0, 0, newWidth, 0, newWidth, newHeight, 0, newHeight];

  let perspectiveTransform = new PerspectiveTransform(src, dst);
  let transformationMatrix = perspectiveTransform.coeffs;

  // debugger;
  return {transform: computeMatrix3DCss(transformationMatrix), width: newWidth, height: newHeight};
}


export function ImageLabelSelector({ url, points, onPointsChange }) {
  const [isDragging, setIsDragging] = useState(false);
  const [activePointIndex, setActivePointIndex] = useState(null);
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const imgRef = useRef(null);
  const [closestPointIndex, setClosestPointIndex] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);
  const [zoomedIn, setZoomedIn] = useState(false);

  const creating = points?.length !== 4;

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging && activePointIndex !== null) {
        const { x, y } = getMouseCoordinates(e);
        const newPoints = [...points];
        newPoints[activePointIndex] = { x: Math.max(0, Math.min(100, x)), y: Math.max(0, Math.min(100, y)) };
        onPointsChange(newPoints);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setActivePointIndex(null);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, activePointIndex, points, onPointsChange]);


  function getMouseCoordinates(e) {
    const rect = svgRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    return { x, y };
  }


  const handleMouseDown = (e, index) => {
    setIsDragging(true);
    setActivePointIndex(index);
    // e.stopPropagation();
  };

  const handleSvgMouseDown = (e) => {
// If creating, add a new point with the mouse position relative to the svg rect
    const { x, y } = getMouseCoordinates(e);

    if (creating) {
      onPointsChange([...(points || []), { x, y }]);
    } else if (!isDragging && points?.length === 4) {
      points[closestPointIndex].x = x;
      points[closestPointIndex].y = y;
      onPointsChange([...points]);
      setIsDragging(true);
      setActivePointIndex(closestPointIndex);
    }
  };

  const handleSvgMouseMove = (e) => {
    const { x, y } = getMouseCoordinates(e);
    setMousePosition({ x, y });
    if (!creating && !isDragging) {
      let closestPoint = _.minBy(points, (p) => Math.hypot(p.x - x, p.y - y));
      setClosestPointIndex(points.indexOf(closestPoint));
    } else if(closestPointIndex >= 0) {
      setClosestPointIndex(null);
    }
  };

  const rotatePoints = () => onPointsChange([points[3], ...points.slice(0, 3)]);

  const clearPoints = () => onPointsChange(null);;

  const d = (a, b) => Math.hypot(a.x - b.x, a.y - b.y);

  let rotateBtn;
  if(points?.length === 4) {
    let centerX = (points[0].x + points[1].x + points[2].x + points[3].x) / 4;
    let centerY = Math.max(... _.map(points, 'y'))+5;
    if(centerY > 90) {
      centerY = (centerY + Math.min(... _.map(points, 'y')))/2;
    }

    if(mousePosition && d(mousePosition, { x: centerX, y: centerY }) < 100) {
      rotateBtn = <span className={'position-absolute text-center'} title={'Rotate right 90'} style={{
        width: '80px', height: '32px', zIndex: 9, margin: '-16px 0 0 -40px', top: `${centerY}%`,
        left: `${centerX}%`
      }}>
              <span className={'btn-xs btn btn-primary'} onClick={rotatePoints}>
                <Icon size={'sm'} icon={'rotate_90_degrees_cw'} level={'white'}>Rotate</Icon>
              </span>

          <span className={'btn-xs btn btn-danger ml-2'} onClick={clearPoints}>
                <Icon size={'sm'}  icon={'delete'} level={'white'}>Rotate</Icon>
              </span>
      </span>;
    }
  }

  let zoomOverlay;
  if (zoomedIn && points?.length === 4 && imgRef.current) {
    let imageWidth = imgRef.current.naturalWidth;
    let imageHeight = imgRef.current.naturalHeight;
    let displayWidth = window.innerWidth/2-100;
    let displayHeight = window.innerHeight-300;
    let {transform, width, height} = computeCssMatrix(points, imageWidth, imageHeight,displayWidth, displayHeight);
    zoomOverlay = <div className={`HalfscreenImgPreview`} style={{ overflow: 'hidden' }}>
      <div style={{ width: width + 'px', height: height + 'px', overflow: 'hidden', position: 'relative' }}>
        <img src={url}
             style={{ position: 'absolute', left: 0, top: 0, transform, transformOrigin: 'top left' }}
             className={''}
        />
      </div>
    </div>;
  }

  // console.log(points?.map(p => `(${p.x.toFixed(0)}, ${p.y.toFixed(0)})`).join(', '));

  let showMoveHelper = closestPointIndex >= 0 && points?.length === 4 && points[closestPointIndex] && mousePosition && d(mousePosition, points[closestPointIndex]) < 100;

  return (
    <div className="">
      {zoomOverlay}
      <div ref={containerRef} className="pos-relative w-100 border border-primary"
           onMouseLeave={() => setZoomedIn(false)}
           onMouseEnter={() => setZoomedIn(true)}
      >
        <img src={url}
             style={{ width: '100%', top: 0, left: 0, userSelect: 'none', userDrag: 'none', pointerEvents: 'none' }}
             ref={imgRef}
             alt="Control points image" className="position-relative"/>

        { rotateBtn }


        <svg className="position-absolute"
             viewBox={`0 0 100 100`}
             preserveAspectRatio="none"
             ref={svgRef}
             style={{
               userDrag: 'none',
               cursor: creating ? 'crosshair' : (showMoveHelper ? 'move' : null),
               zIndex: 2, width: '100%', height: '100%', top: 0, left: 0
             }}

             onMouseDown={handleSvgMouseDown}
             onMouseLeave={() => setClosestPointIndex(null)}
             onMouseMove={handleSvgMouseMove}>

          {/*<circle cx="0" cy="0" r="1.5" fill="red" stroke="white" strokeWidth="0.1"/>*/}
          {/*<circle cx="100" cy="0" r="1.5" fill="red" stroke="white" strokeWidth="0.1"/>*/}
          {/*<circle cx="100" cy="100" r="1.5" fill="red" stroke="white" strokeWidth="0.1"/>*/}
          {/*<circle cx="0" cy="100" r="1.5" fill="red" stroke="white" strokeWidth="0.1"/>*/}

          {points?.length === 4 ?
            <polygon points={points.map((p, i) => `${p.x},${p.y}`).join(' ')} fill="none" stroke="#ffffff"
                     strokeWidth="0.2"/> : null}

          {showMoveHelper ?
            <polyline points={[points[(4+closestPointIndex-1) % 4], mousePosition, points[(closestPointIndex+1) % 4]].map((p, i) => `${p.x},${p.y}`).join(' ')} fill="none" stroke="#ffff0099"
                      strokeWidth="0.2"/> : null}

          {creating && points?.length === 3 ?
            <polyline points={[mousePosition, points[0]].map((p, i) => `${p.x},${p.y}`).join(' ')} fill="none" stroke="#ffff0099"
                      strokeWidth="0.2"/> : null}

          {creating && points?.length > 0 ? <polyline points={[... points, mousePosition].map((p, i) => `${p.x},${p.y}`).join(' ')} fill="none" stroke="#ffffff"
                                                      strokeDasharray={'1,1'}
                                                     strokeWidth="0.2"/> : null}

          {(points || []).map((point, index) => (
            <g key={index}>
              <circle

                cx={`${point.x}`}
                cy={`${point.y}`}
                r="1.5"
                fill={colors[index]}
                stroke={closestPointIndex === index ? 'blue' : 'white'}
                strokeWidth={closestPointIndex === index ? '0.2' : '0.1'}
                style={{ cursor: 'move', userDrag: 'none' }}
                onMouseDown={(e) => handleMouseDown(e, index)}
              />
              <text x={`${point.x}`} y={`${point.y}`} textAnchor="middle" alignmentBaseline="middle" fill="white" fontSize="10%" textAnchor="middle"
                    style={{ pointerEvents: 'none', userSelect: 'none' }}>
                {index + 1}
              </text>
            </g>
          ))}
        </svg>
      </div>
    </div>
  );
}
