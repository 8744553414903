import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';

import MultilineTextEditor from '../../common/editors/MultilineTextEditor';
import { IconButton } from '../../common/IconButton';
import LightSelect from '../LightSelect';
import { MultipleImagesEditor } from '../../common/editors/MultipleImagesEditor';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import MultiButtonSwitch from '../../common/editors/MultiButtonSwitch';
import { FUSE_TYPE_FORMATS } from '../../common/FuseboxData.mjs';
import { SwitchInput } from '../../common/SwitchInput';
import SingleTextEditor from '../../common/editors/SingleTextEditor';
import TextDiff from '../../test-sets/TextDiff';
import { FuseDescription } from './FuseboxTableEditor';

const regexCircuitBoard = /printed circuit board/i;
const regexCircuitBreaker = /circuit breaker/i;

const regexAmp = /^(\d[\d.,]*) ?(?:a|amp\.?|amps|amper|amperios)? ?(\*{0,4}|\*[1234]|[¹²³⁴])$/i;
const regexRelay = /(?:(?:full|half) iso )?relay/;
const regexDiode = /diode|diodo/i;
const regexNotUsed = /^([—\-\s]|not used|sin usar|sin uso|no utilizado|libre|empty|no se (utiliza|usa)|\.\.\.)+(\.)?$/i;
const regexPuller = /fuse ?puller/i;

const regexNumber = /^(fuse ?(# ?)?)?(\w?\d+[AB]?|\w)$/i;

const regexMultipleNumber = /^\s*(\w?\d+(?:[\s,\.\-]+|$)){2,}/i;

const diode = ['diode', 'diodo']
const relay = ['relay', 'relé', 'rele', 'full', 'half', 'relevador']
const fuse = ['fuse', 'maxi', 'mini', 'cartridge', 'ato', 'standard', 'case', 'm', 'j', 'puller']
const circuitBreaker = ['circuit', 'breaker']

const typeKeywords = [... diode, ... relay, ... fuse, ... circuitBreaker]

function filterIfMany(testFunction, minPercent = 20, minPercentToOverride = minPercent) {
  return (values, currentColumn) => {
    let passing = _.filter(values, testFunction);
    if(currentColumn)
      return passing.length / values.length > (minPercentToOverride/100);
    else
      return passing.length / values.length > (minPercent/100);
  };
}

function intersects([ax1, ay1, ax2, ay2], [bx1, by1, bx2, by2]) {
  return ax1 < bx2 && // a's left edge is to the left of b's right edge
    ax2 > bx1 && // a's right edge is to the right of b's left edge
    ay1 < by2 && // a's top edge is above b's bottom edge
    ay2 > by1    // a's bottom edge is below b's top edge
}

const getDescription = text => text;
const getFuseIdName = text => text;
const getFuseIdNumber = text => text && text.length > 0 && text.match(regexNumber) && text

const getFuseMultipleNumbers = text => text && text.length > 0 && text.match(regexMultipleNumber)
const getFuseType = text => {
  let type = (text || "").toLowerCase().match(new RegExp(`^(${[...typeKeywords].join('|')})$`, 'i'));
  return type;
};
const getFuseAmp = text => {
  if(!text)
    return [false];

  let [,amp, extras] = text.toLowerCase().match(regexAmp) || []
  if(amp) {
    return [amp, extras];
  } else {
    return [''];
  }
};

const isFuseIdName = text => text && text.length >= 3 && text.length < 12 && text;
const isDescription = text => text && text.length > 10 && text;
const isFuseAmp = text => {
  let [amp] = getFuseAmp(text);
  if(amp) {
    // Check against common amps number, to differentiate it from fuse numerations
    return _.includes(['1','2','3','4','5', '7', '7,5', '7.5', '10', '15', '20', '25', '30', '35', '40', '50', '60', '70', '80', '100', '120'], amp)
  } else {
    let notUsed = text.match(regexNotUsed)
    return !!notUsed;
  }
};const isEmpty = text => !text || text.trim().length === 0 || text.trim().match(/^[*-]+$/)

const typeTesters = [
  ['description', filterIfMany(isDescription, 40)],

  ['idNumber', filterIfMany(getFuseIdNumber, 60)],

  ['idName', filterIfMany(isFuseIdName, 60)],

  ['amp', filterIfMany(isFuseAmp, 20, 50)], // Should be after idNumber, as it is more specific

  // This is in the case ids are consecutive numbers from 1 to 10 that could seem like amps too
  ['idNumber', vs => filterIfMany(getFuseIdNumber, 60)(vs) && _.uniq(vs).length === vs.length],

  ['idNumbersList', vs => filterIfMany(getFuseMultipleNumbers, 50)(vs)],

  ['type', filterIfMany(getFuseType, 25)],

  ['empty', filterIfMany(isEmpty, 50)]
]

const fuseFormats = {
  '**': 'cartridge',
  '*': 'mini',
  ' circuit breaker': 'circuitbreaker',
  ' fuse puller': 'fusepuller'
};

const sampleText = `1  |  20 A | Limpiaparabrisas.
2  |  20 A | Levantavidrios electrico delantero izquierdo.
3  |  20 A | Levantavidrios eléctrico delantero derecho.
4  |  10 A | Faro alto izquierdo.
| | El farp
5  |   10 A | alto derecho
 | | esta muy bien
6  |   15 A | Central de los levantavidrios electricos.
7  |  7,5 A | Luces de posicion delantera izquierda y trasera derecha, luz de matricula, iluminacion de los comandos.
8  |   7,5 A | Luces de posicion delantera derecha y trasera izquierda.
9  |   15 A | Faros antiniebla.
10  |   20 A | Cierre centralizado de las puertas.`;

const commonSpellingErrors = [
  ['Acompanante', 'Acompañante'],
  ['Alimentacion','Alimentación'],
  ['Angulo', 'Ángulo'],
  ['Asi', 'Así'],
  ['Atras', 'Atrás'],
  ['Automatica', 'Automática'],
  ['Automatico', 'Automático'],
  ['Bateria', 'Batería'],
  ['Bujia','Bujía'],
  ['Cajetin ', 'Cajetín '],
  ['Calefaccion', 'Calefacción'],
  ['Carroceria', 'Carrocería'],
  ['Cinturon ', 'Cinturón '],
  ['Cortesia', 'Cortesía'],
  ['Cámara', 'Cámara'],
  ['Desempanador', 'Desempañador'],
  ['Desempanante', 'Desempañante'],
  ['Despues', 'Después'],
  ['Deteccion', 'Detección'],
  ['Diagnostico', 'Diagnóstico'],
  ['Direccion', 'Dirección'],
  ['Electrica', 'Eléctrica'],
  ['Electrico', 'Eléctrico'],
  ['Electromagnetico ', 'Electromagnético '],
  ['Electronica', 'Electrónica'],
  ['Electronico', 'Electrónico'],
  ['Energia', 'Energía'],
  ['Esta', 'Está'],
  ['Exportacion', 'Exportación'],
  ['Funcion', 'Función'],
  ['Habitaculo', 'Habitáculo'],
  ['Ignicion', 'Ignición'],
  ['Iluminacion ', 'Iluminación '],
  ['Iluminacion', 'Iluminación'],
  ['Inalambrico', 'Inalámbrico'],
  ['Informacion ', 'Información '],
  ['Inyeccion', 'Inyección'],
  ['Liquido', 'Líquido'],
  ['Lluminacion', 'Iluminación'],
  ['Logica', 'Lógica'],
  ['Logico ', 'Lógico '],
  ['Matricula', 'Matrícula'],
  ['Modulo', 'Módulo'],
  ['Multifuncion', 'Multifunción'],
  ['Navegacion ', 'Navegación '],
  ['Obstaculo', 'Obstáculo'],
  ['Opcion ', 'Opción '],
  ['Operacion ', 'Operación '],
  ['Oxigeno','Oxígeno'],
  ['Pocision', 'Pocisión'],
  ['Posicion', 'Posición'],
  ['Presion', 'Presión'],
  ['Refrigeracion ', 'Refrigeración '],
  ['Regulacion ', 'Regulación '],
  ['Rele', 'Relé'],
  ['Senal', 'Señal'],
  ['Senalizador', 'Señalizador'],
  ['Servodireccion ', 'Servodirección '],
  ['Suspension', 'Suspensión'],
  ['Telefono', 'Teléfono'],
  ['Termica', 'Térmica'],
  ['Termico', 'Térmico'],
  ['Traccion', 'Tracción'],
  ['Transmision', 'Transmisión'],
  ['Vacio', 'Vacío'],
  ['Valvula','Válvula'],
  ['Vehiculo','Vehículo'],
  ['Velocimetro', 'Velocímetro'],
  ['Ventilacion','Ventilación'],
  ['Version ', 'Versión '],
  ['Volumetrico', 'Volumétrico'],
];
// const resultTest = require('./test.json')

const PROMPT_FORMAT_TABLE = ocrOutput => `Given the following raw text of an OCR of a table with a list of fuses, do your best to interpret each line and properly format it to:
number|amperage|fuse name|fuse description 

If there are no descriptions, omit that column.
BEWARE, there might be OCR errors. Don't remove any data. 

Make sure all rows have the same column order. Even if amperage is found at the end of the line, put it in the correct order. 

For example:

5|SOUND 7..5 A
7 | EMPTY
22 | ENGINE: Main engine valves 40 Amps
35|3.0A ABS
-->
5|7.5A|SOUND|
7 || EMPTY
22|40A|ENGINE|Main engine valves
35|30A|ABS|

Raw ocr output:
"${ocrOutput}"
`;

const PROMPT_LIST_YEARS = tableText => `Given this list of fuses and their descriptions, reproduce the same list verbatim again but detecting and putting in brackets all the different 
conditional specifications depending on the vehicle years, engine type, trim, market or any other modifier specified in the descriptions. 
For example:
"In diesel engines brake pedal." -> "Brake pedal [diesel engines]."
"Radiator fan (Canada only)" -> "Radiator fan [Canada]"
"Steering column combination switch (depending on equipment)" -> "Steering column combination switch [Depending on equipment]"
"F15|fuse|10|Circulation pump (From April 2005)" -> "F15|fuse|10|Circulation pump [From April 2005]"


INPUT:
"${tableText}"


OUTPUT with conditionals in brackets:
`;



const OCRCache = {};

function padAllNumbers(line) {
  return line.replaceAll(/\d+/g, digits => digits.padStart(5, '0'));
}

class FuseboxTableImgProcessor extends Component {
  constructor(props) {
    super(props);

    const defaultTypes = {
      'default': { type: 'fuse' },
      '*': { type: 'fuse', format: 'mini' },
      '**': { type: 'fuse', format: 'ato' },
      '***': { type: 'fuse', format: 'maxi' },
      '¹': { type: '', format: '' },
      '²': { type: '', format: '' },
      '³': { type: '', format: '' }
    };


    const ocrModes = ['TABLE', 'TEXT'];
    if(this.props.fuses?.length > 1) {
      ocrModes.push('DIAGRAM');
    }

    this.state = {
      text: props.initialText || '',
      fuseTableImgs: this.props.existingImages || [],
      ocrMode: 'TABLE',
      ocrModes,
      defaultTypes,
      tryToFollowLines: true,
      searchText: '',
      replaceText: '',
      forceParsingColumns: this.props.forceParsingColumns || null,
      useRegex: false,
    };

    this.commonSpellingMistakes = [];
    // noinspection SpellCheckingInspection

    commonSpellingErrors.forEach(([from, to]) => {
      this.commonSpellingMistakes.push([new RegExp(`\\b${from}(e?s)?\\b`, 'g'), `${to}$1`]);
      this.commonSpellingMistakes.push([new RegExp(`\\b${from.toLowerCase()}(e?s)?\\b`, 'g'), `${to.toLowerCase()}$1`]);
    });

    this.parseTableService = window.client.service('services/s3-parse-table');
    this.azureOCRService = window.client.service('services/azure-parse-table');
    this.gtpService = window.client.service('services/gpt/prompt');

    // alert(this.extractTextFromAWSTextractResult(resultTest).map(row => row.join(' | ')).join('\n'))

    if (this.props.existingImages?.length) {
      setTimeout(() => this.parseTableImage(_.map(this.props.existingImages, 'url')), 200);
    }

    // For Development tests
    // setTimeout(() => this.setState({text: sampleText+'\n'+sampleText+'\n'+sampleText}), 200)

    this.updateSelectedLineInTable = _.throttle(this.updateSelectedLineInTable.bind(this), 100);
  }

  textChanged(text) {
    this.setState({ text });
  }

  parseKind(row, typeColumns) {
    let kind = {};

    let { amp, type, description } = typeColumns;

    if (amp !== undefined) {
      let [fuseAmp, extras] = getFuseAmp(row[amp]);
      if (fuseAmp) {
        kind.amp = parseFloat(fuseAmp.replace(/,/, '.'));

        if (extras && this.state.defaultTypes[extras]?.type) {
          kind = { ...kind, ...this.state.defaultTypes[extras] };
        } else {
          kind.type = this.state.defaultTypes.default.type;
          kind.format = this.state.defaultTypes.default.format;
        }
      }
    }

    if (!kind.type) {
      let fuseDesc = row[description];
      if (fuseDesc && fuseDesc.match(new RegExp(relay.join('|'), 'i')) && !kind.amp) {
        kind.type = 'relay';
      }
    }

    return kind;
  }

  spellcheckCommonSpanishIssues(text) {
    let res = text;
    for(const [regex, replacement] of this.commonSpellingMistakes) {
      res = res.replace(regex, replacement);
    }
    return res
  }

  parseTableText(forceParsingColumns) {
    let input = this.state.text;

    if(!input || !input.length)
      return [];


    let text = input.replace(/[ \t]+/g, ' ').replace(/(\n[\s\r]*)+/g, '\n');
    let rows = _.map(text.split('\n'), rowText => _.map(rowText.split('|'), s => s.trim()))


    // Group cells by colummn
    let columns = {};
    _.each(rows, row => _.each(row, (cell, i) => columns[i] = [ ... (columns[i] || []), cell]))


    // Try to determine which data has each column
    let columnIndexType = forceParsingColumns || _.map(columns, () => null);

    // If the columns are not given, infer them by type-testing them
    if(!forceParsingColumns) {
      _.each(columns, (column, index) => {
        _.each(typeTesters, ([typeName, typeTester]) => {
          if (typeTester(column, columnIndexType[index])) {
            columnIndexType[index] = typeName
          }
        })
      })
    }

    let typeColumnIndex = _.invert(columnIndexType);

    // Autofix: If inferring columns, and there is idNumber, no description but idName, use idName as description
    if(!forceParsingColumns && typeColumnIndex['idName'] && typeColumnIndex['idNumber'] && !typeColumnIndex['description']) {
      typeColumnIndex['description'] = typeColumnIndex['idName'];
      delete typeColumnIndex['idName'];
      columnIndexType[typeColumnIndex['description']] = 'description';
    }

    let {idName, idNumber, idNumbersList, description, amp, type, other} = typeColumnIndex;
    let hasName = idName !== undefined;
    let hasNumber = idNumber !== undefined;
    let hasNumbers = idNumbersList !== undefined;

    let fuses = [];
    _.each(rows, row => {
      let id = hasName ? (hasNumber ? row[idNumber] : row[idName]) : (hasNumbers ? row[idNumbersList] : row[idNumber]);

      if(hasNumbers && id) {
        id = [ ... id.matchAll(/\b(\w?\d+)\b/gi)].map(m => m[0]) || id;
      }

      let fuse = {
        'id': id,
        'kind': this.parseKind(row, typeColumnIndex),
        'description': row[description],
      };

      const defaultFormat = this.state.defaultTypes.default;

      if((fuse.description || '').match(regexNotUsed)) {
        fuse.kind.type = fuse.kind.type || defaultFormat.type;
        fuse.kind.format = fuse.kind.format || defaultFormat.format;
        fuse.kind.notUsed = true;
      } else if(!fuse.kind.type && defaultFormat.type === 'relay') {
        fuse.kind.type = fuse.kind.type || defaultFormat.type;
        fuse.kind.format = fuse.kind.format || defaultFormat.format;
      }

      if(hasName && hasNumber && row[idName]) {
        fuse.description = `(${row[idName]}) ${fuse.description}`
      }

      if(fuse.kind.type === 'fuse' && !fuse.kind.amp && !fuse.kind.notUsed) {
        fuse.kind.amp = ''
      }

      if(idNumbersList && id?.length) {
        for(const idNum of id) {
          fuses.push({... _.cloneDeep(fuse), id: idNum});
        }
      } else {
        fuses.push(fuse);
      }
    });

    return {parsedFuses: fuses, tableColumns: columnIndexType};
  }

  extractTablesFromAWSTextractResult({ Blocks }) {
    let blocks = _.keyBy(Blocks, 'Id');

    let cells = _.map(_.filter(Blocks, { BlockType: 'CELL' }), ({ RowIndex, ColumnIndex, Relationships }) => {
      let children = _.map(Relationships, ({ Type, Ids }) => _.map(Ids, id => blocks[id].Text).join(' ').replace(/\b-\s/g, ''));
      return { RowIndex, ColumnIndex, children };
    });

    let rows = _.groupBy(cells, 'RowIndex');

    return _.map(rows, (rowCells, i) => {
      let cells = _.sortBy(rowCells, 'ColumnIndex');
      return _.map(cells, c => (c.children || [''])[0]);
    });
  }

  extractTextFromAWSTextractResult({ Blocks }) {
    let lines = _.map(_.filter(Blocks, { BlockType: 'LINE' }));

    // Average height of lines with more than 3 chars, to prevent dashes and puctuation
    let averageLineHeight = _.sum(_.map(_.filter(lines, l => l.Text.length > 3), l => l.Geometry.BoundingBox.Height)) / lines.length;

    let rows = _.groupBy(lines, ({ Geometry: { BoundingBox: { Top, Height } } }) => Math.round((Top + Height / 2) / averageLineHeight));

    // Sort from left to right
    rows = _.mapValues(rows, row => _.sortBy(row, ({ Geometry: { BoundingBox: { Left } } }) => Left));
    return _.map(_.values(rows), row => _.map(row, block => block.Text));
  }

  extractTextFromAzureOcrResult([{ lines }]) {
    if(this.state.tryToFollowLines) {
      _.each(lines, l => {
        //let [topLeftX, topLeftY, topRightX, topRightY, bottomRightX, bottomRightY, bottomLeftX, bottomLeftY] = l.boundingBox;
        let [x1, y1, x2, y2, x3, y3, x4, y4] = l.boundingBox;
        l.left = _.min([x1, x2, x3, x4]);
        l.right = _.max([x1, x2, x3, x4]);
        l.top = _.min([y1, y2, y3, y4]);
        l.bottom = _.max([y1, y2, y3, y4]);
        // l.height = Math.sqrt((x1-x3)**2+(y1-y3)**2);
        l.height = l.bottom - l.top;
      });

      let sortedByYX = lines.sort((la, lb) => {
        let [ax, ay] = [la.left, la.top];
        let [bx, by] = [lb.left, lb.top];
        if (ay < (by - lb.height / 2)) {
          return -1
        } else if (by < (ay - la.height / 2)) {
          return 1
        } else {
          return ax < bx ? -1 : 1;
        }
      });

      let txt = '';
      let lastX = 0;
      for (const line of sortedByYX) {
        let [x1, y1, x2, y2, x3, y3, x4, y4] = line.boundingBox;
        if (x1 < lastX) {
          txt += '\n';
        }
        txt += line.text + ' ';
        lastX = x3;
      }
      return txt;
    } else {
      return _.map(lines, line => line.text).join('\n');
    }
    // return _.map(sortedByYX, line => line.text).join('\n');
  }

  extractTextFromOcrInCurrentRectangles([{ lines, width: imgWidth, height: imgHeight }], fuses) {
    // Average height of lines with more than 3 chars, to prevent dashes and puctuation
    let linesById = {};

    for(const line of lines) {
      for(const word of line.words) {
        let [x1, y1, x2, y2, x3, y3, x4, y4] = word.boundingBox;
        // Assume it is just a box with no weird angles
        let lineBox = [_.min([x1, x2, x3, x4]), _.min([y1, y2, y3, y4]), _.max([x1, x2, x3, x4]), _.max([y1, y2, y3, y4])]

        // l.height = Math.sqrt(Math.pow(x1-x3, 2)+Math.pow(y1-y3, 2));
        for (const { id, layout: { top, left, height, width } } of fuses) {
          let fuseBBox = [left * imgWidth / 100, top * imgHeight / 100, (left + width) * imgWidth / 100, (top + height) * imgHeight / 100];
          if (intersects(lineBox, fuseBBox) && word.text.trim() !== id) {
            linesById[id] = (linesById[id] || '') + ' ' + word.text;
          }
        }
      }
    }

    return _.map(linesById, (line, id) => `${id}|${line.trim()}`).join('\n');
  }


  async parseTableImage(imgS3Urls) {
    let ocrMode = this.state.ocrMode;

    if(ocrMode === 'DIAGRAM' && imgS3Urls.length !== 1) {
      return alert('The DIAGRAM parse mode requires exactly one image, the same image of the diagram');
    }

    try {
      this.setState({ err: null, parsingTable: true })
      let promises = [];
      for(const imgS3Url of imgS3Urls) {
        console.log(`Parsing table img as ${ocrMode} ${imgS3Url}...`)

        const [,bucket, s3ImageKey] = imgS3Url.match(/https:\/\/([\w-]+)\.s3\.amazonaws\.com\/(.*)/)
        if(!bucket || !s3ImageKey)
          throw new Error('Invalid img url', imgS3Url)

        promises.push((async () => {
          let ocrService = ocrMode === 'TABLE' ? 'aws' : 'azure' ;
          let res = OCRCache[imgS3Url+ocrService];

          if(!res) {
            if(ocrService === 'azure') {
              res = await this.azureOCRService.create({ s3ImageKey, bucket });
            } else {
              res = await this.parseTableService.create({ s3ImageKey, bucket });
            }
            OCRCache[imgS3Url+ocrService] = res;
          }

          let text;

          if(ocrMode === 'TABLE') {
            let table = this.extractTablesFromAWSTextractResult(res);
            if (table.length) {
              console.log(`Parsing Textract response for ${imgS3Url}...`)
              text = _.map(table, row => row.join(' | ')).join('\n');
            } else {
              // Fallback to extracting text
              this.setState({ err: 'No table found, parsing just text' })
              text = _.map(this.extractTextFromAWSTextractResult(res), row => row.join(' | ')).join('\n')
            }
          } else {
            if(ocrMode === 'DIAGRAM') {
              text = this.extractTextFromOcrInCurrentRectangles(res.analyzeResult.readResults, this.props.fuses);
            } else {
              text = this.extractTextFromAzureOcrResult(res.analyzeResult.readResults);
            }
          }

          text = this.spellcheckCommonSpanishIssues(text);
          return text;
        })())
      }
      const allText = await Promise.all(promises);
      if(this.state.ocrMode === ocrMode) {
        this.setState({ text: allText.join('\n'), parsingTable: false })
      } else {
        console.log('Discarding stale OCR result');
      }
    } catch(error) {
      console.error(error)
      this.setState({parsingTable: false, err: error.toString()})
    }
  }

  fuseTableImgUrlChanged(imgUrls) {
    this.setState({fuseTableImgs: imgUrls, forceParsingColumns: null});

    this.parseTableImage(_.map(imgUrls, 'url')).then(() => {
      console.log('Done.')
    });
  }

  updateDefaultType(mark, type, format) {
    this.state.defaultTypes[mark] = {type, format: format || ''};
    this.setState({defaultTypes: this.state.defaultTypes})
  }

  getDefaultTypesRows() {
    return _.map(this.state.defaultTypes, ({type, format}, key) => {
      return <tr key={key}>
        <td className={'text-right text-primary align-middle align-middle py-0'}>{key}</td>

        <td className={'py-0'}>
          <LightSelect
          className={'form-control form-control-sm form-control-plaintext m-0 p-0'}
          onChange={newType => this.updateDefaultType(key, newType, null)}
          options={['', ... _.keys(FUSE_TYPE_FORMATS)]}
          placeholder="Pick a type..."
          value={type}
        />
        </td>

        <td className={'py-0'}>
          <LightSelect
          className={'form-control form-control-sm form-control-plaintext  m-0 p-0'}
          onChange={newFormat => this.updateDefaultType(key, type, newFormat)}
          options={FUSE_TYPE_FORMATS[type] || []}
          placeholder="Pick a format..."
          value={format}
        />
        </td>
      </tr>
    })
  }

  onKeyPressMergeLines(e) {
    let target = e.target;

    // Compute current caretPosition, if the selectedLine changed, setState
    this.updateSelectedLineInTable(target);

    if(e.shiftKey && e.altKey) {
      let key = e.key;
      let caret = target.selectionStart;
      setTimeout(() => {
        let text = this.state.text;

        const updateTextKeepingUndo = (newText, newCaretPosition) => {
          // Hack to keep UNDO history, instead of changing the state via react we use exeCommand
          // target.setSelectionRange(0, text.length)
          document.execCommand('selectAll',false);
          const el = document.createElement('p');
          el.innerText = newText;
          document.execCommand('insertHTML',false,el.innerHTML);
          // Previously was using "insertText", but it was 10X slower!! (taking almost 1 second to do the operation)
          // Idea from: https://www.py4u.net/discuss/1017807
          // document.execCommand("insertText", false, newText);
          console.log('exec command')
          setTimeout(() => target.setSelectionRange(newCaretPosition, newCaretPosition), 10)
        }

        if (key === 'ArrowUp') {
          // Merge line up, eat up leading | ... |, add space (except there is a hyphen)
          const previousLineBreak = text.substring(0, caret).lastIndexOf('\n') || 0;
          let before = text.substring(0, previousLineBreak);
          before = (before.trim()+' ').replace(/- /, '');
          const changedText = before + text.substring(previousLineBreak + 1).replace(/^(\s*\|)*\s*/, '');

          updateTextKeepingUndo(changedText, previousLineBreak);
        } else if (key === 'ArrowDown') {
          // Merge line down, put it at the beginning of the last column
          const currentLineStart = text.substring(0, caret).lastIndexOf('\n') || 0;
          const nextLineStart = text.indexOf('\n', caret);
          if (nextLineStart >= 0) {
            let nextLineEnd = text.indexOf('\n', nextLineStart + 1);
            let nextLineLastPipe = text.substring(0, nextLineEnd).lastIndexOf('|');

            let currentLine = text.substring(currentLineStart, nextLineStart);
            let nextLineUntilPipe = text.substring(nextLineStart, nextLineLastPipe + 1);
            const changedText = text.substring(0, currentLineStart)
              + nextLineUntilPipe + ' ' + (currentLine.replace(/^(\s*\|)*\s*/, '').trim()+' ').
                     replace(/- $/, '')
              + text.substring(nextLineLastPipe + 1).replace(/^\s*/, '');

            updateTextKeepingUndo(changedText, caret - currentLineStart + nextLineLastPipe);
          }
        }
      }, 0);
    }
  }

  updateSelectedLineInTable(textarea, scrollTable = true) {
    let caret = textarea.selectionStart;

    // Select the line of text corresponding to lineNumber
    let lines = textarea.textContent.slice(0,caret).split(`\n`);
    let currentLine = lines.length - 1;
    if(this.state.selectedLine !== currentLine) {
      this.setState({selectedLine: currentLine});

      if(scrollTable) {
        // Ensure selected table row is viewable, +1 due to the header row
        $('#fuse-table-preview tr')[currentLine + 1]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  scrollTextAreaToLine(lineNumber) {
    // Lazy hack. Assuming only one instance in the window.
    // This should be done with React createRef, requires changes downstream in textarea component wrappers
    let textarea = document.getElementById('fuse-table-raw-input');

    // Select the line of text corresponding to lineNumber
    let lines = textarea.textContent.split(`\n`);
    let offset = _.sumBy(lines.slice(0, lineNumber), l => l.length + 1)
    textarea.setSelectionRange(offset, offset + (lines[lineNumber]?.length || 0));
    textarea.focus();

    // Scroll to the line. Pretty hacky, but should work at least in Chrome
    const areaHeight = textarea.clientHeight;
    const lineHeight = parseInt(getComputedStyle(textarea, null).getPropertyValue('line-height'), 10);
    // Scroll so that the line is in the middle of the textarea
    textarea.scrollTop = Math.max(0, (lineNumber - 1) * lineHeight - areaHeight / 2);
    textarea.scrollLeft = 0;

    this.updateSelectedLineInTable(textarea, false);
  }

  sortText() {
    const sortedText = _.sortBy(this.state.text.split('\n'), padAllNumbers).join('\n');
    this.setState({ text: sortedText })
  }

  removeNumberPrefixes() {
    let lines = this.state.text.split('\n');
    lines = lines.map(l => l.replace(/^\s*[a-z](\s*\d+)\s*\|/i, '$1|'));
    this.setState({ text: lines.join('\n') })
  }


  async tryParseGPT() {
    let partialOutput = '';
    const promptProgress = ({promptId, newOutput}) => {
      partialOutput = partialOutput + newOutput;
      this.setState({ text: partialOutput });
    };

    this.gtpService.on('prompt-progress', promptProgress)
    try {
      let res = await this.gtpService.create({ prompt: PROMPT_FORMAT_TABLE(this.state.text) });
      this.setState({ text: res });
    } finally {
      this.gtpService.removeListener('prompt-progress', promptProgress)
    }
  }

  async tryExtractYears() {
    let partialOutput = '';
    const promptProgress = ({promptId, newOutput}) => {
      partialOutput = partialOutput + newOutput;
      this.setState({ text: partialOutput });
    };

    this.gtpService.on('prompt-progress', promptProgress)
    try {
      let res = await this.gtpService.create({ prompt: PROMPT_LIST_YEARS(this.state.text) });
      console.log(res)
      this.setState({ text: res });
    } finally {
      this.gtpService.removeListener('prompt-progress', promptProgress)
    }
  }



  render() {
    let fuses = [];

    let {parsingTable, err, fuseTableImgs, text, ocrMode, forceParsingColumns, useRegex, ocrModes, replaceText, searchText, selectedLine, tryToFollowLines  } = this.state;

    let  error = null;

    if(err) {
      error = <div className={'alert alert-danger'}>{err}</div>
    }

    let parsingColumns = null;
    try {
      let {parsedFuses, tableColumns} = this.parseTableText(forceParsingColumns);
      fuses = parsedFuses;

      if (!forceParsingColumns) {
        parsingColumns = <div className={'mb-2'}>{
          _.map(tableColumns, (col, i) => {
            return <span key={i} className={'mr-1 badge badge-info'}>{col || '???'}</span>;
          })
        }
          <IconButton level={'secondary'} icon={'edit'} onClick={() => this.setState({forceParsingColumns: tableColumns})}/>
        </div>;
      } else {
        let columnTypes = _.map(typeTesters, ([colType,],i) => ({label: colType, value: [i,colType]}));

        parsingColumns = <Select
          closeOnSelect={false}
          isMulti
          onChange={(options) => this.setState({forceParsingColumns: _.map(options, v => v.value[1])})}
          options={columnTypes}
          menuShouldScrollIntoView={false}
          // simpleValue
          value={forceParsingColumns.map((colType,i) => ({label: colType, value: [i,colType]}))}
          className={'zoom-90 mb-2'}
          onBlur={() => this.setState({forceParsingColumns: forceParsingColumns.length ? forceParsingColumns : null})}
        />;
      }

    } catch (err) {
      console.error(err)
      error = <div className={'alert alert-danger'}>{err.toString()}</div>
    }

    const updateOCRMode = (mode) => {
      this.setState({ocrMode: mode}, () => this.parseTableImage(_.map(fuseTableImgs, 'url')).catch(alert));
    };

    const updateFollowLines = (v) => {
      this.setState({tryToFollowLines: v}, () => this.parseTableImage(_.map(fuseTableImgs, 'url')).catch(alert));
    }

    let replacePreview = (text) => text;
    let searchTextOrRegex = searchText;
    if(searchText) {
      if(useRegex) {
        try {
          searchTextOrRegex = new RegExp(searchText, 'g');
        } catch (err) {
          console.log('Invalid regex '+searchText);
        }
      }

      replacePreview = text => {
        let newText = text.replaceAll(searchTextOrRegex, replaceText);
        return <TextDiff inputA={text} inputB={newText}/>;
      };
    }

    return <div className={'TableImgProcessorGrid m-1'}>
      <div className={''}>
        <div className={'mb-2'}>
          <div className={'text-left mb-2'}>
            <MultiButtonSwitch value={ocrMode} onChange={updateOCRMode} options={ocrModes}/>

            { ocrMode === 'TEXT' ? <SwitchInput className={'ml-2'} value={tryToFollowLines} onChange={updateFollowLines}>Follow lines</SwitchInput> : null }
          </div>

          <MultipleImagesEditor defaultName={this.props.contextId} folder={'fusebox-tables'} category={'fusebox-table'} value={fuseTableImgs}
                                forceJPG={true} onChange={this.fuseTableImgUrlChanged.bind(this)}/>
        </div>

        <div className={'mb-2 pl-3'}>
          {error}
        </div>

        { _.map(fuseTableImgs, ({url}) => <img key={url} className={'img-fluid'} src={url}/>) }
      </div>

      <div className={''}>
        { parsingTable ? <div className={'alert alert-info mb-2'}>Parsing table...</div> : null }

        { parsingColumns }

        <MultilineTextEditor id={'fuse-table-raw-input'} className={'no-wrap small'} minRows={10} maxRows={17} value={text}
                             onKeyUp={this.onKeyPressMergeLines.bind(this)}
                             onKeyDown={e => this.updateSelectedLineInTable(e.target)}
                             onClick={e => this.updateSelectedLineInTable(e.target)}
                             onChange={text => this.textChanged(text)}></MultilineTextEditor>


        <div className={'small text-secondary mb-05 d-flex justify-content-around'}>
          <IconButton icon={'sort'} onClick={() => this.sortText()}>Sort</IconButton>


          <IconButton icon={'auto_fix_normal'} onClick={() => this.context.page.runAsync(this.tryParseGPT())}>Magic GPT format</IconButton>
          {/*<IconButton icon={'auto_fix_normal'} onClick={() => this.context.page.runAsync(this.tryExtractYears())}>Extract years</IconButton>*/}
        </div>
        <div className={'small text-secondary mb-05 d-flex justify-content-around'}>
          <IconButton icon={'backspace'} level={'danger'} onClick={() => this.removeNumberPrefixes()}>Remove id prefix</IconButton>
        </div>

        <div className={'small text-secondary mt-2 text-center'}>
          Use <span className={'ShortcutKey'}>Shift+Alt+↑</span> to merge description line up (<span className={'ShortcutKey m-1'}>↓</span> for down)
        </div>


        <div className={'mt-3'}>
          <button className={'btn btn-primary'} onClick={() => this.props.onImport(fuses, fuseTableImgs)}>Add fuses!
          </button>

          <button className={'btn ml-2 btn-sm btn-outline-primary'} onClick={() => this.props.onImport(fuses, fuseTableImgs, {replace: true})}>Update fuses
          </button>

          <button className={'btn ml-2 btn-sm btn-outline-primary'} onClick={() => this.props.onImport(fuses, fuseTableImgs, {onlyAmps: true})}>Import amps only
          </button>
        </div>

        <div className={'mt-2 zoom-75 bg-light p-1 rounded'}>
          <h5 className={'text-center'}>Default types</h5>
          <table className={'table table-sm mb-0'}>
            <tbody>
            { this.getDefaultTypesRows() }
            </tbody>
          </table>
        </div>

      </div>

      <div className={''}>

        {/*<div className={'d-flex p-2 bg-light'}>*/}
        {/*  <SingleTextEditor small value={searchText} onChange={searchText => this.setState({searchText})} placeholder={'Search...'}/>*/}
        {/*  <SingleTextEditor small value={replaceText} onChange={replaceText => this.setState({replaceText})} placeholder={'Replace...'}/>*/}
        {/*  <button className={'ml-1 btn btn-sm btn-primary text-nowrap'}>Replace all</button>*/}
        {/*  <button className={'ml-1 btn btn-sm btn-'+(useRegex ? 'primary' : 'outline-secondary')} value={useRegex}*/}
        {/*          onClick={() => this.setState({useRegex: !useRegex})}>*/}
        {/*    .**/}
        {/*  </button>*/}
        {/*</div>*/}

        <table id={'fuse-table-preview'} className={'zoom-75 table table-sm'}>
          <thead>
          <tr>
            <th>Id</th>
            <th>Type</th>
            <th>Format</th>
            <th>Amp</th>
            <th>Description</th>
          </tr>
          </thead>
          <tbody>
          {
            _.map(fuses, ({ id, kind, description }, i) => {
              const rowColor = i === selectedLine ? 'bg-light-success' : (kind || {}).notUsed ? 'bg-light': '';
              const style = {};
              let [,prefix,n] = (id || '').match(/(\D+)?(\d+)$/i) || [];
              let [,prevPrefix,prevN] = (i > 0 && fuses[i-1].id?.match(/(\D+)?(\d+)$/i)) || [];

              if(prevN && n !== prevN && (!n || parseInt(n) !== (parseInt(prevN) + 1))) {
                if(prefix === prevPrefix) {
                  style.borderTop = 'solid 4px orange';
                } else {
                  style.borderTop = 'solid 4px gray';
                }
              }

              return <tr key={i} className={rowColor} style={style} onClick={() => this.scrollTextAreaToLine(i)}>
                <td className={id ? '' : 'bg-light-danger'}>{replacePreview(id)}</td>
                <td className={kind.type ? '' : 'bg-light-warn'}>{kind.type}</td>
                <td>{kind.format}</td>
                <td>{kind.amp}</td>
                <td>
                  <FuseDescription description={description}/>
                </td>
              </tr>;
            })
          }</tbody>
        </table>
      </div>
    </div>
    ;
  }
}

FuseboxTableImgProcessor.contextType = LegoAdminPageContext;

export default FuseboxTableImgProcessor;
