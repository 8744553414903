import React, { Component } from 'react';
import _ from 'lodash';
import FuseboxDiagramEditor from './FuseboxDiagramEditor';
import { IconButton } from '../../common/IconButton';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';

import { FuseboxTemplateReferences } from './FuseboxTemplateReferences';
import TemplatedFuseboxDiagram from './TemplatedFuseboxDiagram';
import { parseFuseboxDescriptionsInferringLanguage } from './fusebox-utils.mjs';
import ClipboardListener, { copyText } from '../../common/clipboard/ClipboardListener';
import ClipboardEnabled from '../../common/clipboard/ClipboardEnabled';
import LegoEditButton from '../LegoEditButton';
import { LocalesList } from '../LocalesList';
import { ModalTranslateFusebox } from './ModalTranslateFusebox';

const OPENED_FUSEBOX_URL_PARAM = 'openedFusebox';

class FuseboxLegoEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFuseboxIndex: 0,
    };

    // This is a hack used to change the fusebox key and force a rerender when the fusebox object is changed,
    // otherwise after edition+save or parsing fuses the diagram remains outdated.
    this.forcedUpdateId = new Date().valueOf()

    this.nlpService = window.client.service('/services/semantic/nlp');

    // setTimeout(() => this.editFusebox(this.props.value[0], 0), 50);
  }

  componentDidMount() {
    const openedFusebox = this.props.openedFusebox ?? this.context.page.getUrlParam(OPENED_FUSEBOX_URL_PARAM);
    if(openedFusebox !== undefined && this.props.value?.[openedFusebox]) {
      this.editFusebox(this.props.value[openedFusebox], openedFusebox);
    }
  }

  onChange(field, newValue) {
    let copy = { ...this.props.value, [field]: newValue };
    this.props.onChange(copy);
  }

  editFusebox(fusebox, i) {
    this.context.page.setUrlParam(OPENED_FUSEBOX_URL_PARAM, i);

    let editorRef = React.createRef();
    this.context.page.openModal(<FuseboxDiagramEditor ref={editorRef} lego={this.props.lego} fusebox={fusebox} onSave={newFusebox => {
      this.props.value[i] = newFusebox;
      this.forcedUpdateId = new Date().valueOf();
      this.props.onChange(this.props.value);
      this.context.page.deleteUrlParam(OPENED_FUSEBOX_URL_PARAM);
      this.context.page.closeModal();
    }}/>, {
      title: `Box ${i+1} of ${this.props.lego.type} ${this.props.lego._id}`,
      onClose: () => this.context.page.deleteUrlParam(OPENED_FUSEBOX_URL_PARAM),
      fullScreen: true,
      closeConfirmationCbk: () => {
        // Only ask for confirmation if the editor has unsaved changes
        if(editorRef.current && _.isEqual(editorRef.current.state.changedFusebox, fusebox)) {
          return true;
        }
        return confirm('Are you sure you wanna close it? You will lose all your changes!');
      }
    });
  }

  removeFusebox(fusebox) {
    if (confirm('Are you really sure you want to DELETE it?')) {
      this.props.onChange(_.without(this.props.value, fusebox));
    }
  }

  copyFusebox(fusebox) {
    copyText(JSON.stringify(fusebox)).catch(console.error);
  }

  handleClipboardChange(clipboard) {
    if(_.isString(clipboard) && clipboard) {
      try {
        let fbox = JSON.parse(clipboard);
        if(fbox && fbox.fuses && fbox.boxAspectRatio) {
          this.setState({clipboardFusebox: fbox});
          return;
        }
      } catch (err) {

      }
    }
    this.setState({clipboardFusebox: null})
  }

  addFusebox() {
    let fuses = [...(this.props.value || []), { fuses: [], boxAspectRatio: 0.5 }];
    this.props.onChange(fuses);
  }

  pasteFusebox()  {
    let fuses = [...(this.props.value || []), this.state.clipboardFusebox];
    this.props.onChange(fuses);
  }

  toggleFuseboxVisibility(fusebox) {
    if(fusebox.hidden) {
      delete fusebox.hidden;
    } else {
      fusebox.hidden = true;
    }
    this.props.onChange(this.props.value);
  }

  async parseFuseboxes() {
    await parseFuseboxDescriptionsInferringLanguage(this.props.value, this.nlpService, this.props.lego.locales?.includes('pt'));
    this.forcedUpdateId = new Date().valueOf();
    this.props.onChange(this.props.value);
  }

  openTranslateDialog() {
    let translationDone = false;
    this.context.page.openModal(<ModalTranslateFusebox lego={this.props.lego} onTranslationDone={() => {
      translationDone = true;
    }}/>, {
      title: 'Translate fusebox ' + this.props.lego._id,
      onClose: () => {
        if (translationDone) {
          this.props.onReload();
        }
      }
    });
  }

  updateTranslation() {
    this.context.page.runAsync(async () => {
      let changedLego = await this.context.page.service('services/data/fusebox-translation').update(this.props.lego._id, {save: false});
      this.props.onChange(changedLego.data);
    }, 'Updating translation...');
  }

  render() {
    const {isTemplate, value} = this.props;

    const {clipboardFusebox} = this.state;

    let warning = null;
    if (isTemplate) {
      if (value?.length !== 1) {
        warning = <div className={'alert alert-danger'}>A fuseboxTemplate MUST HAVE exactly ONE ONLY fusebox. FIX IT or change the type to 'fusebox'</div>
      }
    }

    const swap = (from, to) => {
      let data = [... this.props.value];
      data[from] = this.props.value[to];
      data[to] = this.props.value[from];
      this.props.onChange(data);
    };

    let translateButton, updateTranslationButton;

    if(!this.props.changed && !this.props.lego.labels?.includes('readonly')) {
      translateButton =  <IconButton icon={'translate'}
                                     onClick={() => this.openTranslateDialog()}
                                     className={'mt-2'}>Translate...</IconButton>
    }

    if(_.find(this.props.lego.labels, l => l.startsWith('translatedFrom:'))) {
      updateTranslationButton =  <IconButton level={'violeta'} icon={'translate'}
                                             onClick={() => this.updateTranslation()}
                                             className={'mt-2'}>Update <LocalesList locales={this.props.lego.locales}/> translation</IconButton>
    }

    return <div className={''}>
      <ClipboardListener onChange={val => this.handleClipboardChange(val)}/>

      {warning}
      <div className={'row no-gutters justify-content-around'}>
        {
          _.flatten(_.map(value || [], (fusebox, i) => {
              const hiddenClass = fusebox.hidden ? ' translucent' : '';
              const progressBackground = fusebox.fuses?.length ? (isTemplate ? 'bg-gray-blue' : 'bg-dark') : 'bg-warning';

              let diagramOfImage;
              if (fusebox.fuses?.length) {
                diagramOfImage = <TemplatedFuseboxDiagram development={true} fusebox={fusebox}/>;
              } else if(fusebox.boxDiagramImg) {
                diagramOfImage = <img className={'rounded'} style={{ maxHeight: '100%', maxWidth: '100%' }}
                                      src={fusebox.boxDiagramImg?.url}/>
              } else {
                diagramOfImage = <div>
                  <div>{fusebox.boxName}</div>
                  <img className={'rounded'} style={{ maxHeight: '180px', maxWidth: '200px' }}
                       src={fusebox.boxTablesImages?.[0]?.url}/>
                </div>
              }
              let key = fusebox.boxName + this.forcedUpdateId;
              // In weird cases, there are two boxes with the same box name. Add index to tell those boxes apart
              if(_.filter(value, box => box.boxName === fusebox.boxName)) {
                key += i;
              }

              return [
                <div className={`${isTemplate ? 'bg-gris-blue' : 'bg-dark'} mb-1 mr-1${hiddenClass}`}
                     key={key}
                     style={{ display: 'inline-block' }}>

                  <div className={`p-2 ${progressBackground}`} style={{ height: '30vh', maxWidth: '25vw' }}>
                    {diagramOfImage}
                  </div>

                  <div className={'text-center'}>
                    <IconButton level={'primary'} icon={'edit'} onClick={() => this.editFusebox(fusebox, i)}/>
                    <IconButton level={'danger'} icon={'delete'} onClick={() => this.removeFusebox(fusebox)}/>
                    <IconButton level={fusebox.hidden ? 'danger' : 'success'}
                                icon={fusebox.hidden ? 'visibility_off' : 'visibility'}
                                onClick={() => this.toggleFuseboxVisibility(fusebox)}
                    />
                    <ClipboardEnabled>
                      <IconButton icon={'content_copy'} onClick={() => this.copyFusebox(fusebox, i)}/>
                    </ClipboardEnabled>
                  </div>

                  {
                    fusebox.templateId ?
                      <div className={'small text-center pb-1'}>
                        <LegoEditButton icon={'fingerprint'} legoId={fusebox.templateId} extraQuery={`${OPENED_FUSEBOX_URL_PARAM}=0`} editorProps={{
                          onAfterSave: (updated) => this.forcedUpdateId = new Date().valueOf() + this.forceUpdate()
                        }}>
                          Template <span className={'small'}>{fusebox.templateId}</span>...
                        </LegoEditButton>
                      </div>
                      :
                      null
                  }
                </div>,
                (i+1) < value.length ? <div key={'controls-' + i} className={'flex-grow-0 align-self-center'}>
                  <div><IconButton icon={'swap_horiz'} className={'p-0'} onClick={() => swap(i, i + 1)}/></div>
                </div> : null
              ];
            }
          ))
        }

        <div className={'d-flex flex-column'}>
          {
            (!isTemplate || !value?.length) ?
              <IconButton className={'m-3'} icon={'note_add'} level={'success'} onClick={() => this.addFusebox()}> Add
                diagram</IconButton>
              : null
          }

          { clipboardFusebox ?
            <IconButton className={'m-3'} icon={'content_paste'} level={'success'} onClick={() => this.pasteFusebox()}>
              Paste box
            </IconButton> : null }

          {
            isTemplate && this.props.lego._id ? <div>
              <div className={'h6'}>Fuseboxes using this template:</div>
              <FuseboxTemplateReferences templateId={this.props.lego._id}/>
            </div> : null
          }

          <IconButton icon={'segment'}
                      onClick={() => this.context.page.runAsync(this.parseFuseboxes(), 'Parsing fuseboxes...')}
                      className={'mt-2'}>Parse</IconButton>

          { translateButton }

          { updateTranslationButton }

        </div>
      </div>
    </div>;
  }
}

FuseboxLegoEditor.contextType = LegoAdminPageContext;

export default FuseboxLegoEditor;
// //TODO: Hack to have vite hot reloading working
// export default function ComponentWrapper(props) {
//   return <FuseboxLegoEditor {...props}/>;
// }
