(function(){window.i18nData={
    "home": {
        "sloganManyCars": "Milhares de pessoas com o mesmo carro… e&nbsp;os&nbsp;mesmos&nbsp;problemas",
        "sloganShare": "Compartilhe. &nbsp; Ajuda. &nbsp; Obtenha ajuda.",
        "explanationInfographicAlt": "Uma comunidade para nos ajudarmos mutuamente",
        "howToStart": "Como começar?",
        "pickBrand": "Escolha a marca do seu veículo:",
        "alreadyUser": "Já é membro?",
        "ifNew": "Se ainda não é membro,",
        "canRegisterHere": "inscreva-se aqui",
        "doubts": "Dúvidas? Perguntas? Comentários?",
        "accountInfo": "Informações sobre sua conta, seus carros e sua atividade no site",
        "howDoesItWork": "Como funciona?",
        "moreBrands": "VER MAIS MARCAS",
        "youCanAlsoNavigateByModel": "Procurar por marca e modelo",
        "linkLogin": "INICIAR SESSÃO",
        "linkTermsOfUse": "Termos",
        "linkPrivacyPolicy": "Privacidade",
        "linkContactUs": "Feedback",
        "linkPublishWithUs": "Anuncie em nosso site",
        "searchPlaceholder": "Dúvidas ou perguntas? Escreva aqui...",
        "forExampleShort": "Ex:&nbsp;",
        "step1Share": "Conte-nos seu problema",
        "step1Explanation": "Descreva detalhadamente o seu problema para que outros membros da comunidade possam te ajudar. Opinautos tem mais de <strong>250.000</strong> casos reportados.",
        "step2GetHelp": "Receba ajuda",
        "step2Explanation": "O nosso objetivo, além de te ajudar, é reunir todas as informações sobre os problemas existentes e suas possíveis causas, graças ao apoio da nossa comunidade de mais de <strong>400.000</strong> membros.",
        "step3ShareSolution": "Conte-nos como foi solucionado",
        "step3Explanation": "Se foi possível resolver seu problema, compartilhe a solução: muitas pessoas podem aprender com a sua experiência.",
        "step4BePart": "Participe da comunidade",
        "step4Explanation": "Responda, vote, compartilhe seu conhecimento: A comunidade de Opinautos é feita de entusiastas como você!",
        "visitSiteInCountry": "Versão do site para:"
    },
    "misc": {
        "anonymous": "Anônimo",
        "or": "ou"
    },
    "emailAddresses": {
        "help": "ayuda@opinautos.com.br",
        "info": "info@opinautos.com.br",
        "problems": "problemas@opinautos.com.br",
        "feedback": "feedback@opinautos.com.br",
        "professionals": "profesionales@opinautos.com.br"
    },
    "facebook": {
        "solution": {
            "template": "No relato '%s...' foi resolvido em Opinautos!"
        },
        "firstAnswer": {
            "template": "Responderam '%s...' no Opinautos!"
        },
        "newAnswer": {
            "template": "Uma nova resposta a '%s...' em Opinautos!"
        }
    },
    "emails": {
        "welcome": {
            "helloAndWelcomeUser": "Olá <strong>%s</strong>, bem-vindo à Opinautos!",
            "thanksForJoining": "Obrigado por se juntar a nós :)",
            "clickToActivateUser": "Para ativar seu perfil '<em>%s</em>' por favor clique neste link:&nbsp;",
            "confirmRegistrationButton": "Confirme seu cadastro CLICANDO AQUI",
            "weWillEmailYou": "Se alguém te responder ou pedir ajuda, nós te <strong>enviaremos um email</strong>!",
            "contactUs": "Qualquer dúvida entre em contato conosco <a href='mailto:%s'>%s</a>!",
            "weWillEmailYouOnFuseboxUpdates": "Já estamos trabalhando na ordem do diagrama de fusíveis para o seu {{marca}} {{modelo}}! Iremos notificá-lo quando tivermos novidades.<br><br>Enquanto isso, convidamos você a ler <a href={{url}}>o que está sendo dito na comunidade sobre os fusíveis do seu carro</a>.<br><br>Você está no lugar perfeito para encontrar o que procura."
        },
        "solution": {
            "subject": "No relato '%s...' foi resolvido em Opinautos!",
            "markedAsSolved": "No relato de %s %s está marcado como resolvido!",
            "readButtonContent": "Leia a solução!"
        },
        "newAnswer": {
            "subject": "Uma nova resposta a '%s...' em Opinautos!",
            "thereIsANewComment": "Há um novo comentário no relato de %s %s!",
            "theUserWrote": "O usuário <strong>%s</strong> respondeu e escreveu:",
            "theUserWroteNoContent": "O usuário <strong>%s</strong> respondeu.",
            "readAndReplyButton": "Leia a resposta completa e responda CLICANDO AQUI",
            "readAndReplyButtonNoContent": "Leia a resposta completa e responda CLICANDO AQUI"
        },
        "firstAnswer": {
            "subject": "Responderam '%s...' no Opinautos!",
            "thereHasBeenAReply": "Responderam ao que você escreveu sobre %s %s!",
            "theUserAnsweredYou": "O usuário <strong>%s</strong> te respondeu e escreveu:",
            "theUserAnsweredYouNoContent": "O usuário <strong>%s</strong> te respondeu.",
            "ifSolvedShare": "Se o seu problema foi resolvido, não esqueça de COMPARTILHAR COMO!"
        },
        "footer": {
            "didYouForgotPassword": "Esqueceu sua senha?",
            "getInstructionsToReset": "Receba instruções para redefini-la.",
            "youCanAlso": "Você também pode ",
            "cancelEmailSuscription": "cancelar sua inscrição para estes e-mails",
            "orChangeYour": " ou mudar suas",
            "notificationsConfiguration": "configurações de notificações.",
            "doYouNeedHelp": " Está precisando de ajuda?",
            "ifYouDidntRegister": "Se está recebendo esta mensagem por engano e nunca tenha criado uma conta no Opinautos, clique em ",
            "notMyAccount": "esta conta não é minha"
        },
        "passwordReset": {
            "subject": "Esqueceu sua senha de acesso à Opinautos?",
            "someoneAskForReset": "Alguém reportou que se esqueceu da senha de acesso para esta conta em Opinautos.com.br",
            "ifItHasBeenYou": "Se foi você, defina uma nova senha de acesso clicando no link a seguir:&nbsp;",
            "pickNewPass": "Definir nova senha",
            "ifNotYouIgnoreEmail": "Se não foi você, ignore este e-mail."
        },
        "emailConfirmation": {
            "subject": "%s, confirme seu endereço de e-mail no Opinautos!"
        },
        "common": {
            "opinautosTeamSignature": "Equipe Opinautos",
            "helloUser": "Olá <strong>%s</strong>,",
            "usernameOnlyGreeting": "%s,",
            "javierSignature": "Javier - Equipe do Opinautos"
        },
        "campaigns": {
            "subject": "Olá {{username}}, Você tem novidades sobre o seu {marca}} {{modelo}}?",
            "repliedByAuthorButUnsolved": {
                "yourProblemIsNotMarkedSolved": "Percebemos que seu relato <em>'{{titulo}}'</em> sobre seu {{marca}} {{modelo}} ainda não foi marcado como solucionado.",
                "ifSolvedMarkIt": "Se você já resolveu o problema, ajude outros usuários da comunidade contando como conseguiu resolver e clique em <em>'Aceitar como solução ao problema'</em> sobre seu comentário.",
                "ifNotSolvedShareAnyNews": "Se ainda não foi resolvido e tem alguma novidade, compartilhe escrevendo um comentário para que possam continuar te ajudando.",
                "clickHereToShareNews": "Veja e comente em seu relato CLICANDO AQUI­"
            },
            "noReplies": {
                "noRepliesYet": "Sabemos que o relato <em>'{{titulo}}'</em> abre o seu {{marca}} {{modelo}} ainda não obter respostas, mas não se desanime!</strong>",
                "shareAnyNews": "Se você tem alguma novidade sobre o problema ou mais detalhes, conte para os usuários para que eles possam te ajudar ou pelo menos aprender com a sua experiência."
            },
            "authorDidntComeBack": {
                "peopleRepliedWhereAreYou": "Houveram respostas no relato <em>'{{titulo}}'</em> sobre seu{{marca}} {{modelo}}, mas <strong>não soubemos mais nada de você</strong>! Foram úteis? Você conseguiu solucionar seu problema?"
            },
            "contributor": {
                "variations": {
                    "subjects": {
                        "help": "{{username}}, veja quantas pessoas você ajudou em Opinautos!",
                        "points": "{{username}}, suas respostas no Opinautos continuam adicionando pontos!",
                        "ranking": "{{username}}, você continua subindo no ranking Opinautos!",
                        "contribute": "{{username}}, os usuários do Opinautos precisam de você!"
                    },
                    "bodies": {
                        "help": "Suas respostas ajudaram muitas pessoas, e ainda tem outras que precisam de você.",
                        "points": "Suas respostas têm ajudado muitas pessoas, volte e continue adicionando mais pontos à sua pontuação.",
                        "ranking": "Suas respostas têm ajudado muitas pessoas, voltar a responder e continuar subindo posições no ranking de usuários.",
                        "contribute": "Suas respostas ajudaram muitas pessoas, e muitas outras ainda precisam de sua ajuda."
                    },
                    "buttons": {
                        "help": "Clique aqui para ver quantas você ajudou!",
                        "points": "Clique aqui para ver sua pontuação!",
                        "ranking": "Clique aqui para ver sua classificação!",
                        "contribute": "Clique aqui para continuar ajudando!"
                    }
                }
            },
            "contribution": {
                "manualApproved": {
                    "body": "Seu manual foi verificado e publicado. <strong>Obrigado! É muito útil para a comunidade 💙</strong>",
                    "subject": "Seu manual do {{modelo}} foi publicado 🚀",
                    "btnSeeManual": "Veja o manual"
                },
                "fuseboxImageApproved": {
                    "body": "Já verificamos a foto do fusível que você carregou. <strong>Obrigado! É muito útil para a comunidade 💙</strong>",
                    "subject": "Processamos sua foto do fusível do {{modelo}} 🚀"
                },
                "tirePressureLabelImageApproved": {
                    "body": "Já verificamos a foto da etiqueta de pressão dos pneus que você carregou. <strong>Obrigado! É muito útil para a comunidade 💙</strong>",
                    "subject": "Processamos sua foto da etiqueta de pressão dos pneus do {{modelo}} 🚀"
                },
                "common": {
                    "oneMoreContrib": "Essa contribuição lhe rendeu pontos e fez você subir no ranking 🚀"
                }
            },
            "dataFeedback": {
                "fusebox": {
                    "requestInfo": {
                        "subject": "Diagrama de fusíveis {{model.modelo}} {{year}}",
                        "body": "Andamos procurando sua caixa de fusíveis ({{modelo}} {{year}}) mas não encontramos o manual do usuário que normalmente contém o diagrama de fusíveis. <br/><br/><strong>Se você tiver o manual do seu {{modelo}}</strong>, tente buscar por \"fusíveis\" no índice do mesmo. Se encontrar o diagrama e <strong>nos responder com as fotos</strong> do manual, podemos criar a versão interativa e te enviar (assim aproveitamos para ajudar outros proprietários que também precisam 😉).<br/> <br/><strong>Caso você não tenha o manual</strong>, algumas vezes a tampa de plástico da caixa de fusíveis possui um diagrama. Se você puder nos enviar uma foto, nós daremos uma olhada.",
                        "suggestedFusebox": "💡 Vimos que a foto que você nos enviou é muito similar a <a href='{{url}}' target='_blank'>essa caixa de fusíveis do ano {{suggestedYear}}</a>, que talvez possa te servir. Depois conta pra gente se te ajudou!"
                    },
                    "requestInfoSuggest": {
                        "subject": "Diagrama de fusíveis {{model.modelo}} {{year}}, talvez possa te servir"
                    },
                    "weFoundFusebox": {
                        "subject": "Conseguimos o diagrama de fusíveis do seu {{model.modelo}} {{year}}!",
                        "body": "Levou um tempo, mas <strong>conseguimos  o diagrama de fusíveis</strong> para o seu {{modelo}} {{year}} 😁<br/><br/>Já está publicado, <a href='{{fuseboxUrl}}' target='_blank'>é só acessar esse link</a>!<br/> <br/>Ante qualquer dúvida ou erro, estamos à disposição. Como a ajuda de outros donos foi essencial, queremos te convidar a seguir contribuindo com a nossa comunidade do {{modelo}} 🛠"
                    },
                    "alreadyHaveFusebox": {
                        "body": "Recebemos a foto da sua caixa de fusíveis, desculpe a demora!</br><br/> Já publicamos esse <a href='{{url}}' target='_blank'>diagrama de fusíveis para o {{modelo}} {{year}}</a>, que coincide com a sua foto. <br/> Se você achou essa informação útil ou ainda tiver alguma dúvida, não hesite em nos contar.",
                        "sorryComment": "Dessa vez demoramos um pouco, mas já terminamos de processar! Damos muita  atenção individual a cada caso.</br><br/>"
                    }
                },
                "customSubject": "{{subjectHtml}}"
            },
            "scoreChange": {
                "subject": "Olá {{username}}! você escalou no ranking de Opinautos!",
                "content": "Suas respostas ajudaram muitas pessoas, e há muito mais ainda precisando de sua ajuda.",
                "link": "Clique aqui para continuar ajudando!",
                "yourCurrentRanking": "Sua classificação atual",
                "seeProfileAndStats": "Veja seu perfil e estatísticas ..."
            }
        },
        "spammerEngagement": {
            "registerAsMechanicButton": "Clique aqui para se registrar como profissional",
            "subject": "AVISO: Uso inapropriado de Opinautos",
            "youllBeBlocked": "O envio repetido desses comentários levará ao bloqueio do usuário",
            "weHaveDetectedSpam": "Detectamos o seu uso da nossa plataforma para fins publicitários. Vamos apagar qualquer comentário que <ul style=\"color:Tomato;\"> <li>Não fornece informações úteis para outros usuários.</li> <li>Contém apenas publicidade ou informações de contato (por exemplo, <i>\"Posso ajudar, me mande mensagem no 1122334455\"</i>).</li> </ul>",
            "ifMechanicThenRegisterAsSuch": "Se você oferece serviços profissionais de conserto de automóveis e deseja publicar suas informações de contato, <span style=\"color:MediumSeaGreen;\">você pode se registrar conosco como um profissional</span> e suas informações serão exibidas ao lado de comentários úteis que você escrever em nossa plataforma."
        }
    },
    "messages": {
        "more": {
            "whileYouGetAReplyHelp": "Enquanto te respondem, continue ajudando:",
            "doReplies": "Responda aos problemas de outros usuários onde você puder controbuir",
            "doVotes": "Vote nas respostas úteis para dar reconhecimento e pontos para outros usuários",
            "doReports": "Reportar respostas não construtivas, debochadas e spam",
            "doReviewLeave": "Deixe uma opinião",
            "doReviewOfYourVehicle": " sobre seu veículo para ajudar futuros proprietários",
            "doAndGetPoints": "Assim você também soma pontos e sobe&nbsp;no&nbsp;<a href='{{url}}'>ranking&nbsp;de&nbsp;membros</a>!",
            "dontForgetTo": "Não esqueça:"
        },
        "welcomeAfterAddProblem": {
            "welcome": "Bem-vindo/a a Opinautos!",
            "whileYouGetHelp": "Opinautos é uma comunidade aberta de voluntários que tentam ajudar, por isso, às vezes as respostas podem demorar. Existem várias formas de ajudar:"
        },
        "welcomeAfterReply": {
            "thankYou": "Obrigado <strong>{{user}}</strong>!<br> E bem-vindo/a a Opinautos",
            "weSentYouAnEmail": "Você receberá um <strong>email</strong> da Opinautos com um link. Clique no link para confirmar que você é o dono de <strong>{{email}}</strong>"
        },
        "confirmationResent": {
            "weChangedYourEmail": "Mudamos seu e-mail para <strong>'%s'</strong> e reenviamos seu e-mail de confirmação!",
            "ifProblemsEmailUs": "Se continuar sem receber ou se tiver qualquer problema, entre em contato conosco aqui <strong><a href='mailto:%s'>%s</a></strong>"
        },
        "thankYouUser": "Obrigado {{user}}!",
        "feedbackSent": "<p>Muito obrigado por compartilhar seus comentários! </p><p>Assim que tivermos novidades, te avisaremos. </p>",
        "surveyFollowupSent": "<p>Muito obrigado por compartilhar seus comentários. Você nos ajuda a melhorar o site! </p>",
        "failedFacebookLogin": "<p>Opinautos não recebeu autorização do Facebook.</p>",
        "failedFacebookLoginTitle": "Falha de autenticação com Facebook",
        "passwordReseted": "<p>Senha redefinida com êxito!</p><p>Faça login com sua nova senha!</p>",
        "emailAlreadyExists": "<p>Isso significa que <strong>você já criou um usuário</strong> em Opinautos usando o endereço <em>%s</em></p><p>Use este usuário inserindo sua senha ou Facebook:</p>",
        "defectoAgregado": "<p>Seu relato foi adicionado com sucesso.</p><p>Muito obrigado por compartilhar sua experiência %s!</p>",
        "defaultSuccessTitle": "Sucesso!",
        "thereWasAProblem": "Houve um problema",
        "thereWasAProblemWith": "Há um problema com o",
        "readOnlyModeMessage": "Estamos atualizando o site e esta função está momentaneamente desativada. Por favor, tente novamente dentro de 10 minutos. Desculpe-nos o inconveniente :)",
        "problemNotFound": "Denúncia não encontrada.",
        "commentNotFound": "Comentário não encontrado.",
        "userNotFound": "Usuário não encontrado.",
        "tagNotFound": "A categoria de problema '%s' não existe.",
        "defaultErrorPage": "Houve um problema ao carregar a página. Se este problema se repite envie um email a %s",
        "pageNotFound": "Página não encontrada",
        "vehicleNotFound": "O veículo que você está tentando editar não existe",
        "manualUploaded": "<strong>Recebemos o arquivo :)</strong><br><br>Muito obrigado por subir esta informação.<br>Opinautos não existiria sem este tipo de contribuição.<br><br><strong>OBRIGADO</strong>",
        "userContribution": "<strong>Recebido!</strong><br><br>Muito obrigado por subir esta informação.<br>Opinautos não existiria sem este tipo de contribuição.<br><br><strong>OBRIGADO</strong>",
        "mpgUploaded": "<strong>Obrigado por compartilhar sua experiência :)</strong><br><br>Graças à informação que você forneceu, vamos poder informar o consumo real do veículo.<br>",
        "blacklisted": "O site não pode ser acessado",
        "addReport": {
            "anAnswerMayTakeSomeTime": "Pode ser que a comunidade demore umas horas ou dias para responder",
            "andActivateyourAccount": "para ativar seu perfil em Opinautos",
            "clickTheLinkSentTo": "Acesse o link que enviamos a",
            "youllGetEmailOnReply": "Quando te respondam te avisamos por email",
            "yourReportIsPublished": "A sua pergunta foi publicada na comunidade!"
        },
        "addReview": {
            "yourReviewIsPublished": "Obrigado! Sua opinião foi publicada"
        },
        "keepHelping": "Continue ajudando:",
        "checkYourCnx": "Sua conexão com a internet está boa?"
    },
    "surveys": {
        "thanks": "OBRIGADO!",
        "qSurvey1": "Encontrou o que estava procurando?",
        "yes": "Sim",
        "no": "Não",
        "meh": "Ni...",
        "usefulInformation": "Essa informação foi útil?"
    },
    "views": {
        "denunciaHeadline": {
            "readMore": "Ler..."
        },
        "denuncia": {
            "upvoteTitle": "Votar como útil",
            "downvoteTitle": "Subtrair voto por não ser útil",
            "vote": {
                "one": "voto",
                "other": "votos"
            },
            "replies": {
                "one": "resposta",
                "other": "respostas"
            },
            "sameProblem": {
                "one": "e outra pessoa com o mesmo problema",
                "other": "e outras <strong class='n'>%s</strong> pessoas com o mesmo problema"
            },
            "sameProblemCounter": {
                "one": "<span class='js-sameproblem-count'>%s</span> usuário",
                "other": "<span class='js-sameproblem-count'>%s</span> usuários"
            },
            "reply": "Responder",
            "comment": "Comentar",
            "solvedAlt": "Resolvido",
            "solved": "Resolvido",
            "readHowWasSolved": "Ler como",
            "readFull": "Ler completo",
            "readMore": "Ler mais",
            "edit": "Editar",
            "delete": "Apagar",
            "confirmDelete": "Tem certeza de que deseja excluir o seu problema?",
            "confirmCommentDelete": "Tem certeza de que deseja excluir o seu comentário?",
            "fromCountry": " de %s",
            "shareYourProblem": "Conte o seu problema",
            "hadTheSameProblem": "Tenho esse problema",
            "flagInnapropiate": "Denunciar inapropriado",
            "madeIn": "Feito em %s",
            "anonymousUser": "usuário anônimo",
            "markAsSolution": "Aceitar como solução ao problema",
            "unmarkAsSolution": "Desmarcar como solução",
            "markedAsBestSolution": "Marcada pelo autor do problema como a melhor solução",
            "accordingToAuthor": "&nbsp; (de acordo com %s, o autor do problema)&nbsp;",
            "bestSolutionAccordingTo": "%s selecionou este comentário como a melhor resposta a seu problema",
            "bestSolution": "Melhor solução",
            "reportComment": "Denunciar inapropriado",
            "deleteComment": "Apagar",
            "NHTSAAuthor": "Relato da NHTSA",
            "answersHeader": "Respostas da comunidade",
            "noAnswersYet": "Ainda não há respostas &nbsp;",
            "lockedMsg": "A conversa neste relato está fechada para novos comentários.",
            "why": "Por&nbsp;que?",
            "contactProfessional": "Entrar em contato com um profissional",
            "filterModelsOfYear": "Modelos&nbsp;ano&nbsp;<strong>%s</strong>",
            "seeOtherYears": "Ver&nbsp;todos&nbsp;os&nbsp;anos",
            "upvoteBtn": "Votar util",
            "cases": {
                "one": "caso",
                "other": "casos"
            },
            "helps": {
                "one": "ajuda",
                "other": "ajudas"
            },
            "otherOfftopicProblems": "Comentários sobre outros problemas",
            "seeMoreOfftopicProblems": "Ver outros {{count}} comentários não relacionados",
            "solutions": {
                "one": "solução",
                "other": "soluções"
            }
        },
        "denunciaEntera": {
            "problemCategorizedAs": "Categorizado como: &nbsp;",
            "dateAndReportedBy": "Escrito %s por",
            "problemsWithModel": "Está com problemas com um %s %s?",
            "shareYourExperience": "Conte sua experiência"
        },
        "denunciasTagsResumen": {
            "popupContent": "O <strong>%s%%</strong> dos defeitos do modelo (%s relatos) mencionaram problemas de <strong>%s</strong>. <div><a href='%s' class='btn'>Veja os relatos</a></div>",
            "popupTitle": "Problemas de <strong>%s</strong>",
            "otherProblems": "problemas menos comuns...",
            "allProblems": "Todos os problemas",
            "seeAllCategories": "+ Ver todas as categorias",
            "seeLessCategories": "- Ver menos categorias",
            "seeOtherTypeOfProblems": "Outros tipos de problemas...",
            "moreSpecificProblems": "Problemas mais específicos:"
        },
        "errorPage": {
            "thereWasAProblem": "Houve um problema com a página"
        },
        "elegirMarca": {
            "pickABrand": "Escolha uma marca:",
            "mostCommonBrands": "Marcas mais comuns:",
            "allBrands": "Todas as marcas:"
        },
        "elegirModelo": {
            "pickAModel": "Escolha um modelo de <strong>%s</strong>:",
            "mostCommonModels": "Mais populares:",
            "allModels": "Todos os modelos:"
        },
        "help": "help/about-pt",
        "privacyPolicy": "legal/privacy-policy-pt",
        "termsOfUse": "legal/terms-of-use-pt",
        "selectorModelo": {
            "mostPopular": "Mais populares:",
            "allModels": "Todos os modelos:"
        },
        "myAccount": {
            "title": "Sua conta no Opinautos",
            "pickAPassword": "Você ainda não definiu uma senha! Escolha agora:",
            "errorCannotAddVehicle": "Esta função ainda está em construção.\\n\\nPedimos desculpas :(",
            "linkUserConfig": "Configurações de notificações",
            "linkModelInfo": "Informaçao útil",
            "userData": {
                "yourData": "Seus dados",
                "yourNameIs": "Seu nome público é",
                "yourEmailIs": "Seu endereço de e-mail é",
                "yourCountryIs": "Seu país é",
                "edit": "Editar informações...",
                "updatingData": "Atualizando informações...",
                "youAreUsingFBLogin": "Você está autenticando usando a sua conta de Facebook \"<strong>%s</strong>\"",
                "country": "País",
                "editProfile": "Editar perfil",
                "nickname": "Apelido",
                "yourCountry": "Seu país"
            },
            "vehicles": {
                "yourVehicles": {
                    "one": "Seu veículo",
                    "other": "Seus veículos"
                },
                "modelYear": "Modelo ",
                "madeIn": "Fabricado em ",
                "youHaveNoVehicles": "Você não tem veículos",
                "addVehicle": "Adicionar um veículo",
                "addNewVehicle": "Novo veículo"
            },
            "problems": {
                "problems": "Problemas",
                "problemNotSolved": "Problema não resolvido",
                "goToProblem": "Ir ao problema",
                "without": "sem",
                "addProblem": "Reportar um problema",
                "newProblem": "Novo problema",
                "updateProblem": "Comentar novidades"
            },
            "contributions": {
                "completeProfileCallToAction": "Complete seu perfil",
                "profileCompletionProgress": "Perfil <strong>{{coverage}}%</strong> completo",
                "yourComments": "Suas últimas respostas",
                "youHaveNoContributions": "Você ainda não participou de uma conversa",
                "seeAll": "Ver todos os meus comentários",
                "yourContributions": "Suas contribuições",
                "approved": "APROVADA",
                "pending": "PENDENTE",
                "rejected": "REJEITADO",
                "duplicated": "DUPLICADO",
                "descPdf": "{{count}} arquivos ({{fileNames}})",
                "carProfileImage": "Foto do seu carro",
                "carProfileIntro": "Mostre o melhor lado do seu {{model}}",
                "fuseboxImage": "Foto de sua caixa de fusível",
                "tirePressureImage": "Etiqueta de pressão dos pneus",
                "tirePressureIntro": "Procure a etiqueta de pressão dos pneus na <strong>porta do motorista ou na tampa do tanque</strong>"
            },
            "stats": {
                "noActivty": "Você ainda não possui atividade na comunidade"
            }
        },
        "memberPublicProfile": {
            "title": "Perfil público",
            "publicNickname": "Seu nome público é",
            "originCountry": "País de origem ",
            "userSince": "Usuário desde ",
            "memberSince": "Membro desde ",
            "userVehicles": {
                "one": "Seu veículo",
                "other": "Seus veículos"
            },
            "statistics": "Estatísticas",
            "hisReplies": "Suas respostas",
            "hisReports": "Seus relatos de problemas",
            "positiveVotes": "Votos positivos",
            "negativeVotes": "Votos negativos",
            "numberOfReplies": "Quantidade",
            "numberOfReports": "Quantidade",
            "usersRanking": "Ranking de usuários",
            "usersRankingTwoLines": "Ranking de<br/>usuários",
            "position": "Posição",
            "seeTop100": "Ver top 100",
            "solutions": "Soluções",
            "denunciasPlusOnes": "Adesões",
            "offersProfessionalServices": "&nbsp;Oferece serviços profissionais",
            "moreInformation": "mais informações",
            "replies": "Respostas",
            "hideRankings": "Menos estatísticas",
            "showMoreRankings": "Veja sua classificação em mais {{count}} modelos...",
            "showMoreRankingsPublic": "Veja sua classificação em mais {{count}} modelos...",
            "stats": {
                "contribs": {
                    "one": "contribuição",
                    "other": "contribuições"
                }
            }
        },
        "professionalProfile": {
            "shop": "Estabelecimento:",
            "professional": "Profissional: %s",
            "speciality": "Especialidade",
            "contactPerson": "Pessoa de contato",
            "country": "País",
            "phone": "Telefone",
            "address": "Endereço",
            "seeContactData": "Ver dados de contato",
            "userProfile": "Perfil no Opinautos",
            "seeCompleteProfile": "Ver perfil completo...",
            "wantToAppearAsProfessional": "Deseja aparecer como profissional?<br>Entre em contato conosco através de <a href='mailto:profesionales@opinautos.com.br'>profesionales@opinautos.com.br</a>",
            "user": "Usuário&nbsp;",
            "completeTheForm": "Preencha este formulário com seus dados",
            "orWriteUs": "Ou entre em contato conosco pelo e-mail <a href='mailto:profesionales@opinautos.com.br'>profesionales@opinautos.com.br</a>"
        },
        "membersTopByScore": {
            "title": "Ranking dos {{count}} membros de Opinautos com mais contribuições",
            "headerRank": "Ranking",
            "headerScore": "Pontos",
            "headerUser": "Usuário",
            "headerCountry": "País",
            "headerMemberAge": "Membro desde",
            "headerBestAnswersAndVotes": "Soluções e votos",
            "seeTop1000": "Veja top 1000",
            "titleModel": "{{make}} {{model}}: Os 100 membros com mais contribuições"
        },
        "modeloComparar": {
            "otherModel": "Outro modelo...",
            "problems": "Problemas e defeitos",
            "pickAModel": "Escolha um modelo",
            "usuallyComparedWith": "Geralmente comparado com",
            "vs": "VS"
        },
        "modeloDefectos": {
            "problemsOf": "Problemas e defeitos do <span class='marca'>%s</span> <span class='modelo'>%s</span>",
            "reportedProblems": " Existem <strong class='resultCount'>%s</strong> casos reportados",
            "beMoreSpecific": "Problemas mais específicos:",
            "relatedProblems": "Problemas relacionados:",
            "problemsWithA": "Problemas com um %s?",
            "shareAndGetHelp": "Compartilhe e receba ajuda!",
            "mostCommonProblems": "Problemas comuns",
            "mostRecentProblems": "Problemas mais recentes",
            "problemsWithNoAnswers": "Problemas sem resposta",
            "shareThem": "Compartilhe",
            "problemsByCategory": "Problemas por categoria",
            "otherTagProblems": "Outros problemas de %s",
            "otherTypeOfProblems": "Outros tipos de problema",
            "seeProblemsWithNoAnswers": "ver problemas sem resposta",
            "seeOwnersManual": "Ver manual do propietário"
        },
        "modeloDefectosPorTipo": {
            "problemsOfType": "Problemas e defeitos de <span id='currentTag'>{{tag}}</span> do <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "problemsOfTypeShort": "Problemas de <span id='currentTag'>{{tag}}</span> do <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "problemsOfTypeShortV2": "Problemas de <span id='currentTag'>{{tag}}</span>",
            "thereAreNReports": "Existem <strong>%s</strong> casos reportados",
            "allProblems": "Todos os problemas",
            "bestProblems": "Problemas em destaque",
            "bestProblemsOf": "Problemas em destaque de %s",
            "latestProblems": "Problemas recentes",
            "seeBestProblems": "ver destaques",
            "seeLatestProblems": "ver recentes",
            "seeAllProblems": "Ver os {{count}} problemas de {{tag}}",
            "seeOwnersManual": "Ver manual do propietário",
            "seeFuseboxDiagram": "Ver diagrama de fusíveis",
            "tagsWithFusebox": [
                "fusiveis",
                "reles"
            ]
        },
        "modeloDefectoThread": {},
        "modeloResumen": {
            "people": "pessoas",
            "reports": "casos",
            "ofProblemsAndSolutions": " de problemas e defeitos, soluções e experiências valiosas de outros proprietários",
            "modelOverviewOf": "Informações úteis sobre o&nbsp;%s&nbsp;%s",
            "problems": "Problemas e soluções",
            "addedProblems": " foram adicionados defeitos, problemas e falhas",
            "searchYourProblem": "Busque seu problema",
            "solveYourProblem": "peça ajuda",
            "shareYourExperience": "Compartilhe sua experiência ou problema",
            "seeMoreProblems": "Ver mais relatos de problemas...",
            "seeMore": "Ver mais",
            "noReportsYet": "Ainda não há relatos de experiências ou pedidos de ajuda com algum problema.",
            "wannaBeTheFirst": "Quer ser o primeiro?",
            "notComparedYet": "Ainda não há comparações para este modelo.",
            "compareModelWith": "Comparar <strong>%s %s</strong> com outro modelo...",
            "compareWithOtherModel": "Comparar com outro modelo",
            "ownersManual": "Baixar manual do proprietário do %s&nbsp;%s",
            "warningLights": "Luzes do painel no %s mais comuns explicadas",
            "fuseBox": "Confira um diagram interativo da caixa&nbsp;de&nbsp;fusíveis do seu %s %s",
            "guides": "Troca de óleo, Correia Dentada, luz de Check Engine e mais",
            "reviewsDescription": "Proprietários do {{model}} dão sua opinião sobre seus veículos, o bom e o ruim",
            "serviceManual": "Manuais de serviços, reparação e outras coisas úteis"
        },
        "modelReviews": {
            "title": "Opiniões sobre o {{model}}",
            "subtitle": "Últimas opiniões de proprietários do {{model}}",
            "doYouOwnOne": "Você é um proprietário?",
            "weAreLookingForReviews": "Ajude outras pessoas que querem saber a sua opinião",
            "errorYouAlreadyMadeAReview": "Só é possível deixar uma opinião sobre cada veículo e você já deixou um depoimento sobre seu {{model}}.<br><br><a href='{{reviewUrl}}'>Leia esta opinião aqui</a>",
            "leaveYourReview": "Deixe sua opinião",
            "liked": "O melhor:",
            "disliked": "O pior:",
            "averageTitle": "Média entre {{count}} opiniões:",
            "summary": {
                "negative": "⛔ O aspecto negativo mais mencionado",
                "positive": "✅ O aspecto positivo mais mencionado"
            }
        },
        "relatedProblems": {
            "relatedProblems": "Problemas relacionados",
            "inOtherModels": "Em outros modelos"
        },
        "registracion": {
            "title": "Crie uma conta no Opinautos",
            "join": "Participe da Opinautos!",
            "youAreAlreadyRegistered": "Você já está cadastrado no Opinautos!<br/>Seu usuário é '<strong>%s</strong>'",
            "seeAccountInfo": "Ver informações sobre sua conta"
        },
        "registracionOauth": {
            "title": "Não existe usuário vinculado a esta conta de Facebook",
            "didYouHaveAnAccount": "Você já foi registrado em Opinautos?",
            "nope": "Não...",
            "yep": "Sim...",
            "ifYouAreNewCreateAnAccount": "Se você é novo no Opinautos e ainda não está registrado, crie uma conta clicando aqui:",
            "createAnAccount": "Crie uma conta no Opinautos",
            "ifHadAnAccountConnect": "Se você já tem um perfil no Opinautos e deseja vincular a esta conta de Facebook, insira o e-mail e senha de acesso do seu perfil Opinautos e clique em \"Conectar com minha conta de Facebook\"",
            "connectWithFacebook": "Conectar com minha conta do Facebook"
        },
        "registracionOauthEmailTaken": {
            "title": "Já existe um usuário registrado usando o e-mail '%s' <br>",
            "sameFacebookEmail": "Esse mesmo e-mail é o endereço utilizado pela sua conta de Facebook",
            "ifHadAnAccountWithEmail": "Se você já tem um perfil no Opinautos que usa este e-mail '%s' e deseja associa-lo a esta conta de Facebook, insira a senha deste usuário e clique em \"Conectar com minha conta de Facebook\"",
            "ifForgotPassword": "Se você esqueceu sua senha, clique aqui: "
        },
        "selectorMarcaModelo": {
            "errorLoadingModels": "Houve um erro ao carregar modelos: ",
            "pickModelOf": "Escolha um modelo de "
        },
        "userConfig": {
            "title": "Notificações por e-mail",
            "emailCheckboxEngagement": "Receber lembretes, estatísticas da conta e outras informações relevantes periodicamente",
            "emailCheckboxTransactional": "Receber soluções e respostas aos meus problemas e comentários"
        },
        "userPanel": {
            "myAccount": "Minha Conta",
            "logOut": "Sair",
            "areYouNew": "Você é novo?",
            "signUp": "Cadastre-se",
            "logInOrSignUp": "Entrar ou Cadastrar",
            "createAccount": "Crie sua conta"
        },
        "userNotices": {
            "needToPickPassword": "<strong>%s</strong>&nbsp; Você ainda precisa escolher uma senha de acesso para seu usuário&nbsp;",
            "pickPassword": "Definir senha",
            "passwordSaved": "Senha salva com sucesso",
            "weSentYouAConfirmationEmail": "Enviamos um e-mail para <strong>%s</strong> confirmando seu novo usuário!&nbsp&nbsp",
            "addressIsCorrect": "Não recebi o e-mail",
            "addressIsNotRight": "Meu e-mail está errado"
        },
        "formReportDenuncia": {
            "title": "Nos ajude a manter a qualidade da comunidade!"
        },
        "resetPasswordExpired": {
            "description": "A solicitação de perda de senha expirou (se passaram mais de 24 horas)",
            "makeNewRequest": "Faça uma nova solicitação clicando aqui"
        },
        "searchResults": {
            "youSearched": "Resultados para ",
            "searchInstead": "Em vez disso, pesquisar por ",
            "resultsFound": "<strong>{{count}}</strong> casos relacionados:",
            "noResultsFound": "Não foram encontrados casos relacionados"
        },
        "login": {
            "youAreLoggedAs": "Você está acessando com o usuário '%s'. Veja ",
            "thisAccountsInfo": "informações desta conta",
            "logInAsOtherUser": "acessar com outra conta",
            "orYouCan": " ou você pode ",
            "newUser": "Criar novo usuário?"
        },
        "infoOwnersManual": {
            "downloadPDF": "Baixar manual em PDF",
            "findBelowExplanation": "Aqui abaixo você pode baixar gratuitamente o manual de proprietários do seu {{make}} {{model}}.<br> Manuais para proprietário {{minYear}} a {{maxYear}} ",
            "currentlyDoesntExist": "No momento não temos manuais do {{make}} {{model}}.",
            "forTrims": "Para {{model}} {{year}} {{trims}}",
            "letUsKnow": "Solicite aqui",
            "uploadIt": "Suba e compartilhe",
            "lookingForOther": "Procurando outro carro ou modelo?&nbsp;",
            "canYouContribute": "Você tem algum manual em PDF para compartilhar?&nbsp;",
            "modelOwnersManual": "Manual do propietário {{make}}&nbsp;{{model}} <strong>{{year}}</strong>",
            "title": "Manual do propietário do <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "titleRepairOther": "Manuais de serviços e reparação <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "currentlyDoesntExistYear": "Não contamos com manuais do {{make}} {{model}} para o ano {{year}}",
            "downloadManualInPDF": "Baixar manual em PDF",
            "editionFromCountry": "Edicão {{country}}",
            "titleYear": "Manual do proprietario do <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>&nbsp;{{year}}",
            "totalPages": "{{pages}} páginas",
            "manualEditions": {
                "one": "Veja uma edição anterior deste manual",
                "other": "Veja as {{count}} edições anteriores deste manual"
            },
            "sectionNoName": "Outros",
            "manualInSections": "O manual é dividido em {{count}} seções",
            "modelOtherManual": "{{category}} {{make}}&nbsp;{{model}}&nbsp;{{year}}"
        },
        "articles": {
            "relatedProblems": "Problemas com {{article}} em {{make}} {{model}}",
            "articlesIndexTitle": "Guias úteis para o {{make}}&nbsp;{{model}}",
            "readMoreAboutMakeAndTopic": "Leia mais sobre modelos {{make}} <br> e {{topic}}",
            "readMoreAboutTopic": "Informações mais gerais sobre<br> {{topic}} em um vehículo",
            "relatedArticles": "Artigos relacionados",
            "makeModelsWithMoreProblems": "Modelos {{make}} com mais problemas no {{topic}}",
            "makeProblemsCount": "{{count}} problemas",
            "haveYouFoundAnError": "Você encontrou um erro neste guia? Deixe-nos saber",
            "feedbackProblemsWithThisGuide": "Problemas com este guia?",
            "feedbackProblemsWriteHere": "Escreva-nos aqui...",
            "templateVehicle": "  carro",
            "templateManualLinkClickHere": "clique aqui",
            "templateManualLinkOwnerManual": "manual do proprietário",
            "articleNotFound": "Artigo não encontrado: '{{article}}'"
        },
        "filters": {
            "filters": "Filtros"
        },
        "scoreChanges": {
            "points": {
                "one": "ponto",
                "other": "pontos"
            },
            "positions": {
                "one": "posição",
                "other": "posições"
            },
            "cause": {
                "commentsVotesPositive": {
                    "one": "voto de resposta útil",
                    "other": "votos de respostas úteis"
                },
                "denunciasVotesPositive": {
                    "one": "voto de relato útil",
                    "other": "votos de relatos úteis"
                },
                "commentsMarkedAsSolutionCount": {
                    "one": "resposta aceita como solução",
                    "other": "respostas aceitas como soluções"
                },
                "denunciasPlusOnes": {
                    "one": "pessoa com o mesmo problema",
                    "other": "pessoas com o mesmo problema"
                },
                "commentsCount": {
                    "one": "resposta",
                    "other": "respostas"
                },
                "contributionsApproved": {
                    "one": "contribuição",
                    "other": "contribuições"
                }
            },
            "congratulations": "Parabéns!",
            "positionText": "Você acabou de adicionar <strong>{{score}}&nbsp;{{scorePhrase}}</strong> para a <a href='{{rankingUrl}}'>classificação de membros</a>",
            "positionTitle": "Você está na <br/>posição",
            "rankingUp": "Você acabou de adicionar <strong>{{scorePhrase}}</strong> e subiu <strong>{{position}}&nbsp;{{positionPhrase}}</strong> na <a href='{{rankingUrl}}' > classificação de membros</a>",
            "subTitle": "Você adicionou",
            "title": "Obrigado por contribuir {{username}}!"
        },
        "infoFusebox": {
            "title": "Esquemas caixa de fusíveis do <span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span>",
            "titleYear": "Esquema caixa de fusíveis do {{make}}&nbsp;{{model}}&nbsp;{{year}}",
            "yearDifferentFuseboxes": "Este {{model}} tem {{count}} caixas de fusíveis diferentes:",
            "diagramBoxName": "Diagrama de {{box}}",
            "pickYearReminder": "Os diagramas de fusíveis do {{make}} {{model}} mudam ao longo dos anos, selecione o ano do seu veículo:&#32;",
            "relayNo": "Relé",
            "fuseNo": "Fusível #",
            "fusetableHeaderNo": "#",
            "fusetableHeaderType": "Tipo",
            "fusetableHeaderDescription": "Descrição",
            "source": "Fonte",
            "tapForMoreFuseInfo": "<strong>Toque em um fusível</strong> para ver mais informações",
            "exploreInteractiveFusebox": "Veja os diagramas de fusíveis interativos do {{make}} {{model}}. As caixas de fusíveis mudam ao longo dos anos, escolha o ano do seu veículo:&#32;",
            "fuseboxDiagramYear": "Diagramas&nbsp;de&nbsp;fusíveis<br/><span class='year'>{{model}} {{year}}<br/><span class='other'>{{other}}</span></span>",
            "feedbackMistakeInvitation": "Encontrou&nbsp;um&nbsp;bug? &nbsp;",
            "askForYourFusebox": "Seu carro está faltando?",
            "wrongFusebox": "Não combina com o seu carro?",
            "askForItWeHelpYou": "Peça seu diagrama",
            "letUsKnow": "Nos informe!",
            "onboardingTouchFuse": "👆 Toca um fusível para ver sua função.<br/><br/>↓ Você tem a tabela abaixo",
            "onboardingTouchTable": "👆 Toque em um fusível da lista para marcá-lo no diagrama",
            "nofuseboxYet": "Ainda não publicamos diagramas para o <strong>{{make}} {{model}}</strong>, mas envie uma foto da sua caixa de fusíveis e conseguiremos para você"
        },
        "infoWarningLights": {
            "getHelpWith": "<b>Obter ajuda</b> com &#32",
            "getHelpWithWarningLight": "<b>Obter ajuda</b> com &#32",
            "infoSource": "Fonte: manual do proprietário <span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span>",
            "letUsKnow": "Nos informe!",
            "lookingForSomethingElse": "<b>Procurando outra luz indicadora ou outro modelo?</b>&nbsp;&nbsp;",
            "pickALight": "Escolha uma luz para ver o que significa:",
            "readRelatedReportsPlural": "Veja <span><b>{{tagCount}}</span> relatórios</b> de {{tag}} no <span>{{model}}</span>",
            "readRelatedReportsSingular": "Veja <span><b>{{tagCount}}</span> relatório</b> de {{tag}} no <span>{{model}}</span>",
            "title": "Significado das luzes do painel do <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>"
        },
        "infoTirePressure": {
            "accordingToSpecs": "{makeLogo}&nbsp;&nbsp;<span class=\"align-middle\">Pressões detalhadas</spam>",
            "validForOriginalTires": "* Somente pneus originais",
            "spareTire": "Pneu reserva",
            "front": "frente",
            "rear": "atrás",
            "summaryTitle": "{makeLogo}&nbsp;&nbsp;<span class=\"align-middle\">Resumo calibragem {makeOnly} {makeOrVehicle} </span>&nbsp; {countryFlag}",
            "defaultPressureUnit": "psi",
            "conditions": {
                "highway": "Em estradas",
                "no-highway": "Em ruas",
                "loadHigh": "Com carga alta",
                "loadNormal": "Com carga normal",
                "economic": "Economia de combustível",
                "comfort": "Confortável",
                "trailer": "Com trailer",
                "all": " "
            }
        },
        "modelTrims": {
            "feedbackMistakeInvitation": "Foi vendido em algum outro ano? Há&nbsp;um&nbsp;error? &nbsp;",
            "subtitle": "Anos em que o {{make}}&nbsp;{{model}} foi vendido&nbsp;no&nbsp;{{country}}",
            "subtitleNotInCountry": "Não temos informações sobre o {{make}}&nbsp;{{model}}&nbsp;no&nbsp;{{country}}",
            "title": "Versões do {{make}} {{model}} no {{country}}",
            "titleGlobal": "Versões do {{make}} {{model}}",
            "trimInCountry": "{{make}} {{model}} no {{country}}",
            "versions": "Versões {{model}} <span class='year'>{{year}}</span>"
        },
        "modelYearTrims": {
            "feedbackMistakeInvitation": "Falta uma versão? Há&nbsp;um&nbsp;erro? &nbsp;",
            "seeOtherYears": "Para outros anos clique aqui",
            "subtitle": "Versões {{make}} {{model}} que foram vendidas em {{year}} no {{country}}",
            "subtitleYearNotSold": "O {{make}} {{model}} <strong>{{year}}</strong> não foi vendido no {{country}}",
            "title": "Versões do {{model}} {{year}} no {{country}}",
            "trimsInOtherCountries": "Versões do {{model}} em outros países"
        },
        "modeloCommunity": {
            "infographic": {
                "fuseboxDiagram_line1": "diagramas",
                "fuseboxDiagram_line2": "de fusíveis"
            },
            "lastPosts": "Últimas perguntas",
            "postsWithoutComments": "Perguntas em destaque não respondidas",
            "title": "Comunidade {{make}} {{model}}"
        },
        "infoFuseboxIndividual": {
            "locationDescriptionMoreThanOneBox": "Este {{model}} tem {{boxesCount}} caixas de fusíveis diferentes onde o {{fuse}} está localizado:",
            "locationTitle": "Localização do {{fuse}} do {{make}} {{model}} {{year}}",
            "relatedProblems": "Problemas comuns com o {{fuse}}",
            "stillHaveDoubts": "Ainda tem dúvidas sobre seu problema?<br>Pergunte à comunidade!",
            "askForHelp": "Peça ajuda",
            "filteredTableHeader": "Fusíveis e relés relacionados com {{fuseEntity}}:",
            "linkFullFusebox": "Ver lista completa de fusíveis e relés...",
            "problemsLink": "Ver problemas com o {{fuse}}"
        }
    },
    "forms": {
        "common": {
            "topUserCountries": "br\npt",
            "topCarCountries": "br\nmx\nar\nes",
            "carCountries": "de\nar\nau\nat\nbe\nbr\nca\nco\ncn\nkr\neg\nsk\nsi\nes\nus\nfi\nfr\nnl\nhu\nin\nid\nir\nit\njp\nmy\nmx\npe\npl\npt\ngb\ncz\nro\nru\nrs\nza\nse\nth\ntw\ntr\nua\nuz",
            "userCountries": "ar\nbr\nus\nmx\npt",
            "countryOther": "OUTRO",
            "checkAllFields": "Verifique se está tudo completo",
            "spellcheckLang": "pt",
            "errorUserNotAuthorized": "Usuário não autorizado para realizar esta operação"
        },
        "modelSelector": {
            "brandAndModelLabel": "Marca e Modelo",
            "modelExtrasLabel": "Versão",
            "modelExtrasExample": "Ex: 4 portas, full, motor 1.7",
            "yearLabel": "Ano modelo",
            "pickYear": "Escolha o ano",
            "yearExample": "Ex: 1999, 2003, 2012...",
            "madeInLabel": "Fabricado em",
            "carUsageLabel": "Kms de uso",
            "carUsageExample": "Ex: 0, 250, 5000",
            "carUsageUnit": "km",
            "changeBrandOrModel": "Outro <br> modelo",
            "pickBrand": "Escolha uma marca",
            "cantFindMyModel": "Não estou encontrando minha marca ou modelo",
            "cantFindMyModelModalMsg": "Desejo avisar que na lista de marcas e modelos falta:"
        },
        "login": {
            "loginError": "Não foi possível autenticar o usuário. Verifique se o e-mail e senha estão corretos.",
            "facebookLoginError": "Houve um problema ao autenticar o usuário.",
            "facebookAuthFailed": "Houve um problema ao autenticar com Facebook",
            "facebookNotRegisteredError": "Não está cadastrado no site",
            "signInWithFacebook": "Cadastre-se com Facebook",
            "logInWithFacebook": "Acesse com Facebook",
            "forgotPassword": "Esqueci minha senha",
            "onlyForRegisteredUsers": "Esta página é para membros cadastrados. Se você já é cadastrado, acesse o site: "
        },
        "setPassword": {
            "title": "Defina uma senha para poder continuar o acesso ao site!",
            "repeatPassword": "Repita a senha",
            "savePassword": "Salvar senha!"
        },
        "wrongEmail": {
            "updateEmail": "Atualizar e-mail",
            "myEmailIsNot": "Meu e-mail NÃO é  <em>%s</em>",
            "errorSameEmail": "É o mesmo e-mail de antes, corrija ",
            "insteadItIs": "Ao invés de",
            "title": "Corrigir endereço de e-mail"
        },
        "registration": {
            "youLogIn": "Acesse",
            "email": "E-mail",
            "logIn": "Acessar",
            "logIn2": "Iniciar sessão",
            "forgotPassword": "Esqueci minha <br> senha",
            "password": "Senha",
            "logOut": "Encerrar sessão",
            "errorAddingUser": "Houve um problema ao adicionar usuário",
            "errorUserAlreadyExists": "O usuário %s já existe",
            "errorEmailAlreadyExists": "Já existe um usuário registrado com este e-mail.<br>Se este usuário é seu, por favor acesse o site usando sua senha. Clique no botão de Acessar, localizado na parte superior direita.",
            "errorTitleEmailAlreadyExists": "%s já existe!",
            "doYouHaveAnAccount": "Já tem uma conta?"
        },
        "userRegistration": {
            "nicknameLabel": "Nome de usuário",
            "nickNameExplanation": "Este é o nome que aparecerá para todos os usuários",
            "userCountryExplanation": "Seu país",
            "userCountryPlaceholder": "Escolha seu país",
            "emailLabel": "ou com seu E-mail",
            "emailLabelReply": "Seu E-mail",
            "emailExplanation": "Para gerenciar seu perfil em nosso site e para avisar quando outro usuário responder ou fizer uma pergunta."
        },
        "connectAccounts": {
            "errorCouldNotAuthenticate": "Não foi possível autenticar usuário. Verifique se o nome e senha estão corretos.<br><br>"
        },
        "feedback": {
            "title": "Nos envie seu feedback sobre o site",
            "contentPlaceholder": "Comentários, ideias ou sugestões? Escreva aqui",
            "emailDescription": "E-mail para contato (opcional)",
            "send": "Enviar comentários",
            "feedbackTooShort": "O comentário precisa ter pelo menos 10 letras!"
        },
        "passwordReset": {
            "unknownEmail": "Este e-mail não pertence a nenhum usuário cadastrado",
            "requestExpired": "A solicitação para perda de senha expirou. Por favor, faça uma nova solicitação.",
            "userNotFound": "A solicitação para redefinição de senha pertence a um usuário inexistente.",
            "userNotConfirmed": "O usuário ainda não está confirmado",
            "sentExplanation": "<br>Te enviamos um e-mail com um link para definição de nova senha <br> ¡Verifique sua caixa de entrada!",
            "noEmailFilled": "<strong>Você não forneceu um endereço de e-mail</strong>",
            "sendRequest": "Enviar e-mail para nova senha",
            "enterYourEmail": "Insira o endereço de e-mail cadastrado nesta conta:",
            "youAreNotConfirmedYet": "Você ainda não tem uma senha porque você ainda não confirmou seu e-mail!",
            "lookForTheConfirmationEmail": "Procure o e-mail que enviamos para <em>%s</em> e <strong>clique no botão</strong> que diz <em>\"Confirmar que sou um usuário legítimo\"</em>.",
            "pickANewPassword": "Defina uma senha para continuar o acesso ao site!",
            "saveNewPassword": "Salvar nova senha!"
        },
        "emailconfirmation": {
            "invalidToken": "Identificador confirmação inválido",
            "alreadyConfirmed": "O usuário já havia sido confirmado.",
            "thanksForConfirming": "<strong>%s</strong>, obrigado por confirmar seu endereço de e-mail.",
            "errorFailed": "A confirmação falhou: %s",
            "errorInvalidLink": "Link de confirmação inválido."
        },
        "changeEmail": {
            "alreadyTaken": "Este endereço de e-mail já está sendo usado.",
            "userHadNoEmail": "Este usuário não tem e-mail."
        },
        "vote": {
            "errorAlreadyVotedComment": "Esta resposta já foi votada. Não é possível votar mais de uma vez.",
            "errorSelfVoteComment": "Não é possível votar em suas próprias respostas.",
            "errorAlreadyVoted": "Você já voltou neste problema. Não é possível votar mais de uma vez.",
            "errorSelfVote": "Não é possível votar em seus próprios relatos."
        },
        "agregarDenuncia": {
            "errorInvalidProblemId": "id de denúncia '%s' inválido.",
            "errorDuplicate": "Já existe um relato exatamente igual a este:<br><br> <a href='%s'>%s</a><br><br>Você já adicionou esta denúncia?",
            "validations": {
                "editedTitleTooLong": "tituloEditado muito comprido",
                "editedTitleTooShort": "tituloEditado muito curto",
                "editedContentTooShort": "conteúdoEditado muito curto",
                "editedContentTooLong": "conteúdoEditado muito comprido"
            },
            "sectionVehicle": "Seu veículo",
            "sectionPickVehicle": "Escolha seu veículo",
            "sectionProblem": "Conte seu problema",
            "sectionAccount": "Sua conta, para entrarmos em contato",
            "orAddANewVehicle": "ou você pode adicionar um novo veículo",
            "toUseOtherVehicleClick": "(para usar outro dos seus veículo, clique aqui)",
            "emailRequiredExplanation": "Precisamos do seu e-mail para avisar quando você receber respostas e para que você possa administrar sua conta.",
            "signature": "Assinatura",
            "vehicleCountryPlaceholder": "Escolha um país",
            "titlePlaceholder": "Um título para o seu problema",
            "contentPlaceholder": "Descrição detalhada do seu problema",
            "ifRegistered": "Se você já tem uma conta,",
            "loginWithIt": "acesse com ela",
            "addProblem": "Enviar",
            "floatingAddText": "Perguntar"
        },
        "addReview": {
            "yourScore": "Qual pontuação você daria?",
            "buttonNext": "Seguinte",
            "buttonBack": "Atrás",
            "sectionYourVehicle": "Opine sobre seu veículo",
            "sectionYourExperience": "Em sua experiência:",
            "sectionLiked": "O que você mais gosta",
            "sectionDisliked": "O que você não gosta",
            "likedPlaceholder": "Qual é a melhor coisa sobre o seu veículo?",
            "dislikedPlaceholder": "Qual é a pior coisa do seu veículo?",
            "sectionCreateUser": "Crie um usuário",
            "submitReview": "Enviar opinião",
            "orAddANewVehicle": "adicione outro veículo"
        },
        "responderDenuncia": {
            "errorDuplicate": "Já existe um comentário em o mesmo conteúdo!<br><br>Se você não está vendo um comentário que você acabou de fazer, tente recarregar a página ;)",
            "errorLocked": "O relato que você está tentando responder já está fechado para novos comentários.",
            "errorNoParent": "O comentário que você está tentando responder não existe mais",
            "errorSubSubComment": "Não é possível responder a um subcomentário de um comentário",
            "areaReplyPlaceholder": "Escreva aqui a sua resposta",
            "areaCommentPlaceholder": "Escriva aqui seus comentários",
            "submitReply": "Enviar resposta",
            "submitComment": "Enviar comentário",
            "notSolvedYet": "Continua sem solução",
            "solvedIt": "Já solucionei",
            "solvedTheProblem": "Resolvi meu problema ou dúvida"
        },
        "editComment": {
            "noChanges": "Não houveram mudanças no comentário editado.",
            "errorInvalidIndex": "Índice de comentário inválido",
            "areaPlaceholder": "Resposta.",
            "errCannotDestroyComment": "Você pode adicionar o que precisa ao comentário, mas para excluí-lo ou editá-lo completamente, entre em contato conosco em: <a href='mailto:moderadores@opinautos.com.br'>moderadores@opinautos.com.br</a>",
            "errParentIsChild": "Não é possível aninhar mais de dois níveis",
            "errParentNewerThanChild": "O comentário pai não pode ser mais recente que o filho",
            "errParentNotFound": "Não há comentários dos pais",
            "errParentSameAsChild": "Um comentário não pode ser filho de si mesmo"
        },
        "editDenuncia": {
            "noChanges": "Não houveram mudanças na denúncia editada.",
            "checkAllFields": "Verifique se está tudo completo",
            "errorUserNotAuthorized": "Usuário não autorizado para editar este relato",
            "errCannotDestroyDenuncia": "Você pode adicionar o que precisa ao relato, mas para excluí-lo ou editá-lo completamente, entre em contato conosco em: <a href='mailto:moderadores@opinautos.com.br'>moderadores@opinautos.com.br</a>"
        },
        "reportComment": {
            "title": "Ajude-nos a manter a qualidade da comunidade!",
            "explanation": "Denuncie este comentário se achar que o conteúdo é inapropriado para o site.",
            "examples": "Por exemplo, se contém: <ul> <li>Agressões</li> <li>Provocações</li> <li>Publicidade ou Spam</li> </ul>",
            "areaReasonPlaceholder": "Escreva o motivo...",
            "report": "Denunciar"
        },
        "reportDenuncia": {
            "title": "Ajude-nos a manter a qualidade da comunidade!",
            "explanation": "Denuncie este defeito se parecer que seu conteúdo é inadequado para o site."
        },
        "noConfirmation": {
            "title": "Se o e-mail de confirmação não chegar...",
            "check1": " Verifique que seu endereço de e-mail está escrito corretamente: <strong>%s</strong>",
            "check2": " Verifique se o e-mail não está na pasta de <strong>e-mail não desejado</strong> (ou spam).<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(O assunto do e-mail é '<em>Confirme seu e-mail no Opinautos</em>')",
            "check3": " Se não, escreva para <strong><a href='mailto:%s'>%s</a></strong> com assunto '<em>Confirmação não chega</em>'"
        },
        "formNoConfirmation": {
            "title": "Se o e-mail de confirmação não chegar..."
        },
        "formWrongEmail": {
            "title": "Corrigir endereço de e-mail"
        },
        "formSurveyFollowUp": {
            "title": "Como podemos melhorar?"
        },
        "noAnswers": {
            "title": "Por que algumas perguntas não têm resposta?",
            "explanation": "Opinautos é uma comunidade onde a ajuda e as respostas são dadas pela própria comunidade, formada por outros <strong>proprietários, fãs, mecânicos</strong>, etc.<br><br>Às vezes demora um pouco para que um membro do site veja uma pergunta em que possa colaborar e comentar. <br><br> Portanto, obrigado pela compreensão e <strong>te convidamos a colaborar</strong> onde puder!!"
        },
        "sameProblem": {
            "errorAlreadyOwner": "Não é possível se marcar em seu próprio problema",
            "errorAlreadyMarked": "Já esta marcado no problema",
            "errorNothingToRemove": "Não estava marcado no problema ao qual está tentando desinscrever"
        },
        "imageUpload": {
            "upload": "Clique para carregar uma foto ou&nbsp;arraste até aqui",
            "dropzone": "Arraste e solta as imagens aqui",
            "dropzoneHover": "Solte as imagens",
            "viewOriginal": "Ver original",
            "policy": {
                "title": "<h3>Uso de imagens</h3>",
                "yesExplanation": "<strong>SIM</strong> adicionar fotos do problema ou da solução que ajudem os outros usuários",
                "noExplanation": "<strong>Não</strong> colocar fotos de perfil ou do seu carro que não agregam à discussão"
            },
            "uploadYourPhoto": "Carregue sua foto",
            "submitContrib": "Enviar contribuição",
            "uploading": "Enviando..."
        },
        "pdfUpload": {
            "title": "Carregar manuais em PDF",
            "upload": "Clique aqui para carregar um arquivo em PDF ou&nbsp;arraste aqui",
            "dropzone": "Arraste e solte o arquivo aqui",
            "dropzoneHover": "Solte os arquivos",
            "submitFiles": "Carregar arquivos"
        },
        "ShareMPG": {
            "title": "Qual é o consumo do seu&nbsp;{{modelo}}?",
            "explanation": "Compartilhe, assim você poderá comparar com outros proprietários",
            "dontKnow": "Não entendi",
            "shareMpg": "Compartihar"
        },
        "switchCountry": {
            "nowYouAreInVersion": "Atualmente navegando Opinautos<br/>na versão {{country}}",
            "title": "Versão do site para"
        },
        "pickVehicle": {
            "dontKnow": "Não sei",
            "other": "Outro",
            "otherTrim": "Outra versão...",
            "saveVehicle": "Salvar veiculo",
            "whatYearIsYourModel": "De que ano é seu {{model}}?",
            "whichOfThisVersion": "Qual dessas versões?",
            "whichVersion": "Qual versão?",
            "otherMakeModel": "Outra marca ou modelo..."
        },
        "contribFuseboxPicture": {
            "titleFindDiagram": "Com uma foto do seu fusível podemos obter o diagrama",
            "titleHelpUs": "Ajude-nos a melhorar o diagrama com uma foto do seu fusível",
            "modalTitleAskDiagram": "Peça diagrama de fusíveis"
        },
        "contribImage": {
            "popupBarTitle": "Adicione sua contribuição",
            "thanksForTheUpload": "Muito obrigado por adicionar!",
            "imageWillBeChecked": "Sua foto é o primeiro passo para obter o diagrama. Já estamos trabalhando nisso, faremos o nosso melhor para tê-lo pronto o mais rápido possível 🔧",
            "registerSoWeCanContactYou": "Crie sua conta em Opinautos.com.br para que possamos entrar em contato com você:",
            "anyExtraComment": "Alguma coisa que você queira adicionar?",
            "extraCommentPlaceholder": "Qualquer comentário que nos ajude a melhorar os diagramas...",
            "carProfileThx": "Obrigado por compartilhar sua foto 🔧",
            "tirePressureLabelThx": "Guardaremos sua foto para ajudar a todos que nos perguntam qual é a pressão correta dos pneus"
        },
        "completeVehicleProfile": {
            "actionDesc": {
                "profile": "Especifique o modelo e a versão do seu carro",
                "profilePic": "Compartilhe uma foto do seu veículo",
                "review": "Deixe uma opinião sobre seu carro: o bom e o ruim",
                "tirepressure": "Tire uma foto da etiqueta de pressão dos pneus"
            },
            "title": "Complete o perfil do seu veículo"
        },
        "pickMake": {
            "searchYourMake": "Escolha sua marca",
            "whatMakeIsYourCar": "Qual é a marca do seu veículo?"
        },
        "pickModel": {
            "searchYourModel": "Pesquisar modelo",
            "whatModelIsYourCar": "Qual modelo de {{make}}?"
        },
        "pickFuseboxProblem": {
            "DIFFERENT_FUSES": "Os fusíveis ou suas cores são muito diferentes",
            "FUSEBOX_NOT_FOUND": "Não tem diagrama",
            "FUSE_NOT_FOUND": "Não consigo encontrar um fusível",
            "OTHER": "Outra razão",
            "YEAR_NOT_FOUND": "Tem um diagrama, mas não o meu ano",
            "didYouFindYourDiagram": "Como podemos te ajudar?"
        }
    },
    "static": {
        "domain": "//static.opinautos.com.br",
        "images": {
            "logoLargeWhite": "/images/design2/opinautos-logo-white-large.png",
            "logoHeaderWhite": "/images/design2/opinautos-logo-white-header.png",
            "logoSmall": "/images/design2/opinautos-logo-dark-large.png",
            "allMakesImage": "/images/makes-medium-all-tiny.png",
            "allMakesImageMapping": "abarth\nacura\nalfa-romeo\naro\naudi\naustin\nbaic\nbmw\nbuick\nbyd\ncadillac\nchangan\nchery\nchevrolet\nchrysler\ncitroen\ncupra\ndacia\ndaewoo\ndaihatsu\ndatsun\ndodge\ndongfeng\nds\nfaw\nferrari\nfiat\nford\nfoton\ngalloper\ngeely\ngenesis\ngmc\ngreat-wall\nhaval\nhonda\nhummer\nhyundai\nika\ninfiniti\nisuzu\niveco\njac\njaguar\njeep\nkia\nlada\nlancia\nlandrover\nlexus\nlifan\nlincoln\nlotus\nmahindra\nmaserati\nmazda\nmercedes-benz\nmercury\nmini\nmitsubishi\nnissan\noldsmobile\noltcit\nopel\npeugeot\nplymouth\npontiac\nporsche\nproton\nram\nrenault\nrover\nrenault-samsung\nsaab\nsantana\nsaturn\nscion\nseat\nskoda\nsmart\nssangyong\nsubaru\nsuzuki\nswm\ntata\ntesla\ntoyota\nvolkswagen\nvolvo\ndfsk\nbentley\nshineray\n",
            "topQuickMarcas": "chevrolet\nvolkswagen\nford\nrenault\npeugeot\nfiat"
        }
    },
    "search": {
        "searchboxPlaceholder": "Pesquisar problema...",
        "errorExplanation": "Houve um erro ao realizar a pesquisa :( Por favor, tente novamente mais tarde",
        "popularSearches": "Buscas populares"
    },
    "global": {
        "otherBrands": "Outras marcas...",
        "opinautos": "Opinautos",
        "opinautosDomain": "Opinautos.com.br",
        "writeUs": "Entre em contato conosco clicando aqui",
        "writeUsShort": "Entre em contato",
        "welcome": "Bem-vindo",
        "commentsAboutTheSite": "Comentários sobre o site?",
        "cancel": "Cancelar",
        "close": "Fechar",
        "ok": "Aceitar",
        "thanks": "Obrigado",
        "distanceUnit": "kms",
        "dateDistance": "{{distance}} atrás",
        "saveChanges": "Salvar mudanças",
        "loading": "Carregando",
        "otherLanguages": {
            "es": {
                "otherLanguageName": "Español",
                "otherLanguageDomainUrl": "https://www.opinautos.com",
                "otherLanguageFlagImage": "/images/banderas/es.png",
                "modelProblemsLinkText": "Problemas del %s en español",
                "problemsUrl": "https://www.opinautos.com/%s/%s/defectos"
            },
            "en": {
                "otherLanguageName": "English",
                "otherLanguageDomainUrl": "https://www.startmycar.com",
                "otherLanguageFlagImage": "/images/banderas/us.png",
                "modelProblemsLinkText": "%s problems in English",
                "problemsUrl": "https://www.startmycar.com/%s/%s/problems"
            }
        },
        "letUsKnow": "Avisar",
        "sendDataCorrection": "Você encontrou um erro ou algo para melhorar?",
        "tellUsHere": "Conte-nos o que corrigir...",
        "generations": {
            "generationN": "Geração {{n}}",
            "restyle": {
                "0": "Pré-reestilização",
                "1": "1ª reestilização",
                "2": "2ª reestilização",
                "3": "3ª reestilização",
                "4": "4ª reestilização",
                "5": "5ª reestilização",
                "6": "6ª reestilização"
            },
            "other": "Outras versões"
        },
        "thankYouVeryMuch": "¡Muito obrigado!"
    },
    "titles": {
        "help": "Ajuda - Opinautos",
        "searchModel": "Buscar problemas e falhas %s - Opinautos",
        "searchGlobal": "Buscar problemas e falhas - Opinautos",
        "signUp": "Cadastre-se - Opinautos",
        "logInOrSignUp": "Entrar ou cadastrar- Opinautos",
        "pickBrand": "Escolher marca - ",
        "pickModel": "Escolher modelo de ",
        "problemsModel": "Problemas y falhas %s %s - Opinautos",
        "problemsModelNew": "%s %s: Problemas e soluções - Opinautos",
        "problemsModelAdd": "Adicionar defeito, problema ou falha %s %s - Opinautos",
        "problemsModelTag": "Problemas {tag} {make} {model} - Opinautos",
        "problemsModelTag_oldversion": "Problemas e falhas {tag} {make} {model} - Opinautos",
        "problemsModelTag_modeloParte": "{make} {model} {tag}: falhas e soluções - Opinautos",
        "problemsModelTag_modeloSintoma": "{make} {model} {tag}: causas e soluções - Opinautos",
        "problemsModelTag_problemasNeutralModelo": "Problemas {tag} {make} {model}: Soluções - Opinautos",
        "problemsModelTag_parteModelo": "{tag} {make} {model}: falhas e soluções - Opinautos",
        "problemsModelTag_problemasParteModelo": "Problemas de {tag} {make} {model}: Soluções - Opinautos",
        "problemsModelTag_sintomaModelo": "{tag} {make} {model}: causas e soluções - Opinautos",
        "problemsModelTag_neutralModelo": "{tag} {make} {model} - Opinautos",
        "problemsModelPage": "Problemas e falhas %s %s - Pag %s - Opinautos",
        "problemsModelPageNew": "%s %s: Problemas e soluções - Pag %s - Opinautos",
        "modelSummary": "Informações %s %s - Opinautos",
        "modelComparison": "Comparações %s %s com outros modelos - Opinautos",
        "modelComparisonVs": "Comparações %s %s e %s %s - Opinautos",
        "modelReviews": "Opiniões sobre {{make}} {{model}} - Opinautos",
        "myAccount": "Minha conta - Opinautos",
        "memberProfile": "Perfil de %s - Opinautos",
        "professionalProfile": "Profissional: %s - Opinautos",
        "membersTopByScore": "Ranking de contribuições - Opinautos",
        "forgotPassword": "Esqueci minha senha - Opinautos",
        "userConfig": "Configurar minha conta - Opinautos",
        "newPassword": "Nova senha - Opinautos",
        "problemsModelEdit": "Editar relato",
        "problemsModelIndividual": "%s %s %s - Opinautos",
        "problemsCommentEdit": "Editar comentário",
        "modelOwnersManual": "Manual do proprietário %s %s - Opinautos",
        "modelOwnersManualRepair": "Manuais de serviços e reparação {{make}} {{model}} - Opinautos",
        "modelOwnersManualYear": "Manual do proprietário {{make}} {{model}} {{year}} - Opinautos",
        "modelOwnersManualUpload": "Carregar manual do proprietário %s %s - Opinautos",
        "modelWarningLights": "Luzes de alerta do %s %s - Opinautos",
        "modelFuseBox": "Diagrama de fusíveis {{make}} {{model}} todos os anos - Opinautos",
        "modelFuseBoxYear": "Manual caixa de fusíveis {{make}} {{model}} {{year}} - Opinautos",
        "userAddVehicle": "Adicionar ou editar veículo",
        "modelGuides": "Guias úteis {{make}} {{model}}",
        "modelTrims": "Versões do {{make}} {{modelo}} em {{country}}",
        "modelTrimsNoCountry": "Versões do {{make}} {{model}}",
        "modelCommunity": "Comunidade {{make}} {{model}} - Opinautos",
        "searchBySymptom": "Sintomas do veículo",
        "modelFuseBoxIndividualFuse": "{{fuse}} {{make}} {{model}} {{year}} - Opinautos"
    },
    "tagging": {
        "catMechanic": "Mecânica",
        "catMechanicSlug": "mecânica",
        "catSecurity": "Segurança",
        "catSecuritySlug": "segurança"
    },
    "urls": {
        "compare": "comparar",
        "problems": "defeitos",
        "pagePrefix": "pag",
        "addNewProblem": "adicionar",
        "add": "adicionar",
        "pickBrand": "escolhermarca",
        "pickModel": "escolhermodelo",
        "byDate": "recentes",
        "byScore": "destacados",
        "byNoAnswers": "sem-resposta",
        "auth": "aut",
        "addlogin": "addlogin",
        "facebook": "facebook",
        "registrationLogin": "cadastrologin",
        "userAlreadyExists": "cadastrojaexiste",
        "callback": "callback",
        "myAccount": "minhaconta",
        "members": "membros",
        "professional": "profissionais",
        "topByScore": "ranking",
        "profile": "perfil",
        "logout": "logout",
        "search": "buscar",
        "reviews": "opinioes",
        "signup": "cadastro",
        "forgotPassword": "esquecisenha",
        "newPassword": "novasenha",
        "userConfig": "configuracoes",
        "help": "ajuda",
        "information": "info",
        "ownersManual": "manuais",
        "warningLights": "luzes-alerta",
        "fuseBox": "fusiveis",
        "upload": "carregar",
        "showAll": "todos",
        "guides": "guias",
        "topics": "temas",
        "myCar": "meucarro",
        "trims": "versoes",
        "community": "comunidade",
        "ownersManualRepair": "reparo",
        "symptomsWizard": "procure-sintoma"
    },
    "menu": {
        "modelOverview": "Informação útil",
        "modelProblems": "Problemas <br>e falhas",
        "modelProblems2": "Problemas e falhas",
        "modelCompare": "Comparações",
        "modelReviews": "Opiniões de proprietários",
        "help": "Ajuda",
        "search": "Buscar",
        "pickModel": "Escolher modelo",
        "infoOwnersManual": "Manual do proprietário",
        "infoServiceManual": "Manuais de serviços e reparação",
        "infoWarningLights": "Luzes de Alerta",
        "infoFuseBox": "Diagrama de fusíveis",
        "infoGuides": "Guias Úteis",
        "changeModel": "Mudar",
        "modelCompareShort": "Comparações",
        "modelOverviewShort": "Informação",
        "modelProblemsShort": "Problemas",
        "modelReviewsShort": "Opiniões",
        "infoTrims": "Versões de {{model}}",
        "modelCommunity": "Comunidade"
    },
    "breadcrumbs": {
        "home": "Home",
        "newPassword": "Nova senha",
        "forgoPassword": "Esqueci minha senha",
        "login": "Acessar minha conta",
        "pickBrand": "Escolher marca",
        "pickModel": "Escolher modelo",
        "search": "Buscar",
        "shortProblems": "Problemas",
        "longProblems": "Problemas e falhas",
        "compare": "Comparações",
        "addProblem": "Adicionar um problema",
        "compareTwoModels": "Comparar dois modelos",
        "signUp": "Juntar-se a Opinautos",
        "myAccount": "Minha conta",
        "ownersManual": "Manual do Proprietário",
        "ownersManualUpload": "Carregar manual",
        "warningLights": "Luzes de Alerta",
        "modelOverview": "Informações úteis",
        "fuseBox": "Fusíveis",
        "guides": "Guias",
        "reviews": "Opiniões",
        "trims": "Versões",
        "ownersManualShort": "Manuais",
        "manualsRepairOther": "Serviços e reparação",
        "community": "Comunidade"
    },
    "validations": {
        "brandInvalid": "A marca inserida não é válida.",
        "invalidModel": "Modelo não é válido.",
        "searchTermTooShort": "A pesquisa precisa ser mais informativo",
        "searchTermTooLong": "A pesquisa não pode ter mais de 35 letras ou espaços!",
        "noModel": "Selecione um modelo",
        "userEmail": "Verifique se o e-mail inserido está com erros de digitação",
        "userCountry": "Selecione seu país de residência",
        "usernameTooShort": "Selecione um nome com no mínimo 4 letras",
        "usernameTooLong": "O nome não pode ter mais de 35 letras ou espaços!",
        "madeInCountryEmpty": "Selecione um país de fabricação",
        "carYear": "Selecione um ano de fabricação",
        "carKms": "Insira um número de kms, mesmo que seja aproximado",
        "carKmsOnlyDigits": "O número de kms não deve contar pontos, vírgulas ou espaços",
        "mpgGeneral": "Os kms por litro é o número de kms que, em média, o veículo faz com cada litro de combustível",
        "titleTooShort": "O título precisa ser mais informativo",
        "titleTooLong": "O título não pode ser tão comprido",
        "contentTooShort": "O conteúdo precisa ser completo e descritivo. No mínimo 25 caractéres.",
        "smallContentTooShort": "Escreva pelo menos 5 caracteres",
        "replyTooShort": "O comentário precisa ser mais completo e descritivo. No mínimo 20 caractéres.",
        "invalidDenunciaId": "Tentativa de responder inválida.",
        "invalidCommentId": "Tentativa de responder inválida.",
        "feedbackTooShort": "O conteúdo precisa ser mais completo",
        "passwordTooShort": "A senha escolhida é muito curto. Ela deve conter pelo menos 7 letras",
        "passwordDontMatch": "As senhas não coincidem. Escreva a senha novamente",
        "starsMustRate": "Escolha uma pontuação de 1 a 5 estrelas",
        "invalidImages": "As imagens enviadas não são válidas",
        "invalidFiles": "Os arquivos enviados não são válidos",
        "imageUploadInProgress": "As imagens ainda não foram carregadas. Reenvie o formulário quando terminarem de carregar",
        "imageUploadFileType": "O formato da imagem selecionada não é compatível com o site",
        "imageUploadTooManyFiles": "É possível subir um máximo de 10 imagens",
        "imageUploadFileTooLarge": "A imagem que você está tentando subir é grande demais (mais de 15mb). Por favor, diminua o tamanho da imagem",
        "imageUploadFailed": "Houve um problema ao carregar as imagens. Verifique sua conexão.",
        "pickVehicle": "Escolha um de seus veículos",
        "pdfUploadInProgress": "Os arquivos ainda não foram carregadas. Por favor, reenvie o formulário quando o upload for concluido",
        "pdfUploadFileType": "O formato do arquivo selecionado não é PDF",
        "pdfUploadTooManyFiles": "É possível subir um máximo de 10 arquivos",
        "pdfUploadFileTooLarge": "O arquivo PDF que você está tentando carregar é muito grande (mais de 20 mb).",
        "pdfUploadFailed": "Houve um problema ao carregar os arquivos. Verifique sua conexão",
        "pdfUploadMustUploadSomething": "Nenhum arquivo selecionado",
        "invalidContribution": "Houve um problema com a contribuição"
    },
    "share": {
        "facebook": {
            "locale": "pt_BR",
            "pageURL": "https://www.facebook.com/opinautos.com.br",
            "buttonTitle": "Compartilhar no Facebook",
            "buttonText": "Compartilhar",
            "feedTitle": "Quer saber como reparar um %s? Clique aqui!",
            "menuLikeText": "Curtiu a Opinautos?<br>Siga-nos no Facebook",
            "footerLikeText": "Siga-nos no Facebook"
        }
    },
    "legal": {
        "disclaimerProfessionals": "<strong>Opinautos</strong> não tem qualquer relação com os profissionais ou particulares aqui mencionados e declina qualquer responsabilidade com respeito ao uso das informações proporcionadas e interações entre os mesmos. Os dados e conteúdos aqui facilitados possuem caráter meramente informativo, sua veracidade não está comprovada e foram proporcionados por usuários do site."
    },
    "lang": {
        "en": "inglês",
        "es": "espanhol",
        "pt": "português",
        "language": "Idioma"
    },
    "countryCodeToName": {
        "af": "Afeganistão",
        "za": "África do Sul",
        "ax": "Ilhas Åland",
        "al": "Albânia",
        "de": "Alemanha",
        "ad": "Andorra",
        "ao": "Angola",
        "ai": "Anguila",
        "aq": "Antártida",
        "ag": "Antígua e Barbuda",
        "sa": "Arábia Saudita",
        "dz": "Argélia",
        "ar": "Argentina",
        "am": "Armênia",
        "aw": "Aruba",
        "au": "Austrália",
        "at": "Áustria",
        "az": "Azerbaijão",
        "bs": "Bahamas",
        "bd": "Bangladexe",
        "bb": "Barbados",
        "bh": "Barém",
        "be": "Bélgica",
        "bz": "Belize",
        "bj": "Benim",
        "bm": "Bermudas",
        "by": "Bielorrússia",
        "bo": "Bolívia",
        "bq": "Países Baixos Caribenhos",
        "ba": "Bósnia e Herzegovina",
        "bw": "Botsuana",
        "bv": "Ilha Bouvet",
        "br": "Brasil",
        "bn": "Brunei",
        "bg": "Bulgária",
        "bf": "Burquina Fasso",
        "bi": "Burundi",
        "bt": "Butão",
        "cv": "Cabo Verde",
        "ky": "Ilhas Caimã",
        "kh": "Camboja",
        "cm": "Camarões",
        "ca": "Canadá",
        "qa": "Catar",
        "kz": "Cazaquistão",
        "cf": "República Centro-Africana",
        "td": "Chade",
        "cz": "República Checa",
        "cl": "Chile",
        "cn": "China",
        "cy": "Chipre",
        "cc": "Ilhas Cocos (Keeling)",
        "co": "Colômbia",
        "km": "Comores",
        "cg": "República do Congo",
        "cd": "República Democrática do Congo",
        "ck": "Ilhas Cook",
        "kr": "Coreia do Sul",
        "kp": "Coreia do Norte",
        "ci": "Costa do Marfim",
        "cr": "Costa Rica",
        "hr": "Croácia",
        "cu": "Cuba",
        "cw": "Curaçau",
        "dk": "Dinamarca",
        "dj": "Djibuti",
        "dm": "Dominica",
        "do": "República Dominicana",
        "eg": "Egito",
        "sv": "El Salvador",
        "ae": "Emirados Árabes Unidos",
        "ec": "Equador",
        "er": "Eritreia",
        "sk": "Eslováquia",
        "si": "Eslovênia",
        "es": "Espanha",
        "us": "Estados Unidos",
        "ee": "Estónia",
        "sz": "Essuatíni",
        "sj": "Esvalbarde e Jan Mayen",
        "et": "Etiópia",
        "fo": "Ilhas Feroé",
        "fj": "Fiji",
        "ph": "Filipinas",
        "fi": "Finlândia",
        "fr": "França",
        "ga": "Gabão",
        "gm": "Gâmbia",
        "gh": "Gana",
        "ge": "Geórgia",
        "gs": "Ilhas Geórgia do Sul e Sandwich do Sul",
        "gi": "Gibraltar",
        "gd": "Granada",
        "gr": "Grécia",
        "gl": "Gronelândia",
        "gp": "Guadalupe",
        "gu": "Guam",
        "gt": "Guatemala",
        "gg": "Guernsey",
        "gy": "Guiana",
        "gf": "Guiana Francesa",
        "gw": "Guiné-Bissau",
        "gn": "Guiné",
        "gq": "Guiné Equatorial",
        "ht": "Haiti",
        "hm": "Ilha Heard e Ilhas McDonald",
        "hn": "Honduras",
        "hk": "Hong Kong",
        "hu": "Hungria",
        "ye": "Iêmen",
        "in": "Índia",
        "id": "Indonésia",
        "iq": "Iraque",
        "ir": "Irão",
        "ie": "Irlanda",
        "is": "Islândia",
        "il": "Israel",
        "it": "Itália",
        "jm": "Jamaica",
        "jp": "Japão",
        "je": "Jersey",
        "jo": "Jordânia",
        "kw": "Kuwait",
        "la": "Laos",
        "ls": "Lesoto",
        "lv": "Letônia",
        "lb": "Líbano",
        "lr": "Libéria",
        "ly": "Líbia",
        "li": "Listenstaine",
        "lt": "Lituânia",
        "lu": "Luxemburgo",
        "mo": "Macau",
        "mk": "Macedônia do Norte",
        "mg": "Madagáscar",
        "yt": "Maiote",
        "my": "Malásia",
        "mw": "Maláui",
        "mv": "Maldivas",
        "ml": "Mali",
        "mt": "Malta",
        "fk": "Ilhas Malvinas",
        "im": "Ilha de Man",
        "mp": "Marianas Setentrionais",
        "ma": "Marrocos",
        "mh": "Ilhas Marshall",
        "mq": "Martinica",
        "mu": "Maurícia",
        "mr": "Mauritânia",
        "um": "Ilhas Menores Distantes dos Estados Unidos",
        "mx": "México",
        "mm": "Mianmar",
        "fm": "Estados Federados da Micronésia",
        "mz": "Moçambique",
        "md": "Moldávia",
        "mc": "Mónaco",
        "mn": "Mongólia",
        "ms": "Monserrate",
        "me": "Montenegro",
        "na": "Namíbia",
        "cx": "Ilha do Natal",
        "nr": "Nauru",
        "np": "Nepal",
        "ni": "Nicarágua",
        "ne": "Níger",
        "ng": "Nigéria",
        "nu": "Niue",
        "nf": "Ilha Norfolk",
        "no": "Noruega",
        "nc": "Nova Caledônia",
        "nz": "Nova Zelândia",
        "om": "Omã",
        "nl": "Holanda",
        "pw": "Palau",
        "ps": "Palestina",
        "pa": "Panamá",
        "pg": "Papua-Nova Guiné",
        "pk": "Paquistão",
        "py": "Paraguai",
        "pe": "Peru",
        "pn": "Pitcairn",
        "pf": "Polinésia Francesa",
        "pl": "Polónia",
        "pr": "Porto Rico",
        "pt": "Portugal",
        "ke": "Quênia",
        "kg": "Quirguistão",
        "ki": "Quiribáti",
        "gb": "Reino Unido",
        "re": "Reunião",
        "ro": "Roménia",
        "rw": "Ruanda",
        "ru": "Rússia",
        "eh": "Saara Ocidental",
        "as": "Samoa Americana",
        "ws": "Samoa",
        "sb": "Ilhas Salomão",
        "sm": "San Marino",
        "sh": "Santa Helena, Ascensão e Tristão da Cunha",
        "lc": "Santa Lúcia",
        "bl": "São Bartolomeu",
        "kn": "São Cristóvão e Neves",
        "mf": "São Martinho",
        "sx": "São Martinho",
        "pm": "São Pedro e Miquelão",
        "st": "São Tomé e Príncipe",
        "vc": "São Vicente e Granadinas",
        "sc": "Seicheles",
        "sn": "Senegal",
        "lk": "Seri Lanca",
        "sl": "Serra Leoa",
        "rs": "Sérvia",
        "sg": "Singapura",
        "sy": "Síria",
        "so": "Somália",
        "sd": "Sudão",
        "ss": "Sudão do Sul",
        "se": "Suécia",
        "ch": "Suíça",
        "sr": "Suriname",
        "th": "Tailândia",
        "tw": "Taiwan",
        "tj": "Tajiquistão",
        "tz": "Tanzânia",
        "tf": "Terras Austrais e Antárticas Francesas",
        "io": "Território Britânico do Oceano Índico",
        "tl": "Timor-Leste",
        "tg": "Togo",
        "to": "Tonga",
        "tk": "Toquelau",
        "tt": "Trindade e Tobago",
        "tn": "Tunísia",
        "tc": "Turcas e Caicos",
        "tm": "Turcomenistão",
        "tr": "Turquia",
        "tv": "Tuvalu",
        "ua": "Ucrânia",
        "ug": "Uganda",
        "uy": "Uruguai",
        "uz": "Uzbequistão",
        "vu": "Vanuatu",
        "va": "Vaticano",
        "ve": "Venezuela",
        "vn": "Vietnã",
        "vi": "Ilhas Virgens Americanas",
        "vg": "Ilhas Virgens Britânicas",
        "wf": "Wallis e Futuna",
        "zm": "Zâmbia",
        "zw": "Zimbábue",
        "other": "outro",
        "global": "Internacional",
        "eu": "União Europeia"
    },
    "countryNameToCode": {
        "afeganistao": "af",
        "africa do sul": "za",
        "ilhas aland": "ax",
        "albania": "al",
        "alemanha": "de",
        "andorra": "ad",
        "angola": "ao",
        "anguila": "ai",
        "antartida": "aq",
        "antigua e barbuda": "ag",
        "arabia saudita": "sa",
        "argelia": "dz",
        "argentina": "ar",
        "armenia": "am",
        "aruba": "aw",
        "australia": "au",
        "austria": "at",
        "azerbaijao": "az",
        "bahamas": "bs",
        "bangladexe": "bd",
        "barbados": "bb",
        "barem": "bh",
        "belgica": "be",
        "belize": "bz",
        "benim": "bj",
        "bermudas": "bm",
        "bielorrussia": "by",
        "bolivia": "bo",
        "paises baixos caribenhos": "bq",
        "bosnia e herzegovina": "ba",
        "botsuana": "bw",
        "ilha bouvet": "bv",
        "brasil": "br",
        "brunei": "bn",
        "bulgaria": "bg",
        "burquina fasso": "bf",
        "burundi": "bi",
        "butao": "bt",
        "cabo verde": "cv",
        "ilhas caima": "ky",
        "camboja": "kh",
        "camaroes": "cm",
        "canada": "ca",
        "catar": "qa",
        "cazaquistao": "kz",
        "republica centro-africana": "cf",
        "chade": "td",
        "republica checa": "cz",
        "chile": "cl",
        "china": "cn",
        "chipre": "cy",
        "ilhas cocos (keeling)": "cc",
        "colombia": "co",
        "comores": "km",
        "republica do congo": "cg",
        "republica democratica do congo": "cd",
        "ilhas cook": "ck",
        "coreia do sul": "kr",
        "coreia do norte": "kp",
        "costa do marfim": "ci",
        "costa rica": "cr",
        "croacia": "hr",
        "cuba": "cu",
        "curacau": "cw",
        "dinamarca": "dk",
        "djibuti": "dj",
        "dominica": "dm",
        "republica dominicana": "do",
        "egito": "eg",
        "el salvador": "sv",
        "emirados arabes unidos": "ae",
        "equador": "ec",
        "eritreia": "er",
        "eslovaquia": "sk",
        "eslovenia": "si",
        "espanha": "es",
        "estados unidos": "us",
        "estonia": "ee",
        "essuatini": "sz",
        "esvalbarde e jan mayen": "sj",
        "etiopia": "et",
        "ilhas feroe": "fo",
        "fiji": "fj",
        "filipinas": "ph",
        "finlandia": "fi",
        "franca": "fr",
        "gabao": "ga",
        "gambia": "gm",
        "gana": "gh",
        "georgia": "ge",
        "ilhas georgia do sul e sandwich do sul": "gs",
        "gibraltar": "gi",
        "granada": "gd",
        "grecia": "gr",
        "gronelandia": "gl",
        "guadalupe": "gp",
        "guam": "gu",
        "guatemala": "gt",
        "guernsey": "gg",
        "guiana": "gy",
        "guiana francesa": "gf",
        "guine-bissau": "gw",
        "guine": "gn",
        "guine equatorial": "gq",
        "haiti": "ht",
        "ilha heard e ilhas mcdonald": "hm",
        "honduras": "hn",
        "hong kong": "hk",
        "hungria": "hu",
        "iemen": "ye",
        "india": "in",
        "indonesia": "id",
        "iraque": "iq",
        "irao": "ir",
        "irlanda": "ie",
        "islandia": "is",
        "israel": "il",
        "italia": "it",
        "jamaica": "jm",
        "japao": "jp",
        "jersey": "je",
        "jordania": "jo",
        "kuwait": "kw",
        "laos": "la",
        "lesoto": "ls",
        "letonia": "lv",
        "libano": "lb",
        "liberia": "lr",
        "libia": "ly",
        "listenstaine": "li",
        "lituania": "lt",
        "luxemburgo": "lu",
        "macau": "mo",
        "macedonia do norte": "mk",
        "madagascar": "mg",
        "maiote": "yt",
        "malasia": "my",
        "malaui": "mw",
        "maldivas": "mv",
        "mali": "ml",
        "malta": "mt",
        "ilhas malvinas": "fk",
        "ilha de man": "im",
        "marianas setentrionais": "mp",
        "marrocos": "ma",
        "ilhas marshall": "mh",
        "martinica": "mq",
        "mauricia": "mu",
        "mauritania": "mr",
        "ilhas menores distantes dos estados unidos": "um",
        "mexico": "mx",
        "mianmar": "mm",
        "estados federados da micronesia": "fm",
        "mocambique": "mz",
        "moldavia": "md",
        "monaco": "mc",
        "mongolia": "mn",
        "monserrate": "ms",
        "montenegro": "me",
        "namibia": "na",
        "ilha do natal": "cx",
        "nauru": "nr",
        "nepal": "np",
        "nicaragua": "ni",
        "niger": "ne",
        "nigeria": "ng",
        "niue": "nu",
        "ilha norfolk": "nf",
        "noruega": "no",
        "nova caledonia": "nc",
        "nova zelandia": "nz",
        "oma": "om",
        "holanda": "nl",
        "palau": "pw",
        "palestina": "ps",
        "panama": "pa",
        "papua-nova guine": "pg",
        "paquistao": "pk",
        "paraguai": "py",
        "peru": "pe",
        "pitcairn": "pn",
        "polinesia francesa": "pf",
        "polonia": "pl",
        "porto rico": "pr",
        "portugal": "pt",
        "quenia": "ke",
        "quirguistao": "kg",
        "quiribati": "ki",
        "reino unido": "gb",
        "reuniao": "re",
        "romenia": "ro",
        "ruanda": "rw",
        "russia": "ru",
        "saara ocidental": "eh",
        "samoa americana": "as",
        "samoa": "ws",
        "ilhas salomao": "sb",
        "san marino": "sm",
        "santa helena, ascensao e tristao da cunha": "sh",
        "santa lucia": "lc",
        "sao bartolomeu": "bl",
        "sao cristovao e neves": "kn",
        "sao martinho": "sx",
        "sao pedro e miquelao": "pm",
        "sao tome e principe": "st",
        "sao vicente e granadinas": "vc",
        "seicheles": "sc",
        "senegal": "sn",
        "seri lanca": "lk",
        "serra leoa": "sl",
        "servia": "rs",
        "singapura": "sg",
        "siria": "sy",
        "somalia": "so",
        "sudao": "sd",
        "sudao do sul": "ss",
        "suecia": "se",
        "suica": "ch",
        "suriname": "sr",
        "tailandia": "th",
        "taiwan": "tw",
        "tajiquistao": "tj",
        "tanzania": "tz",
        "terras austrais e antarticas francesas": "tf",
        "territorio britanico do oceano indico": "io",
        "timor-leste": "tl",
        "togo": "tg",
        "tonga": "to",
        "toquelau": "tk",
        "trindade e tobago": "tt",
        "tunisia": "tn",
        "turcas e caicos": "tc",
        "turcomenistao": "tm",
        "turquia": "tr",
        "tuvalu": "tv",
        "ucrania": "ua",
        "uganda": "ug",
        "uruguai": "uy",
        "uzbequistao": "uz",
        "vanuatu": "vu",
        "vaticano": "va",
        "venezuela": "ve",
        "vietna": "vn",
        "ilhas virgens americanas": "vi",
        "ilhas virgens britanicas": "vg",
        "wallis e futuna": "wf",
        "zambia": "zm",
        "zimbabue": "zw",
        "outro": "other",
        "internacional": "global"
    }
};})()