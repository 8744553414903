import React, { useEffect, useState } from 'react';
import useAsyncEffect from '../../common/react-hooks/useAsyncEffect';
import EventEmitter from 'events';

const updates = new EventEmitter();

export default function useEditableLegoData(query, defaultData, shouldUpdateLego = false) {
  const [data, setData] = useState(defaultData);
  const [legoId, setId] = useState(null);

  const queryId = JSON.stringify(query);

  let mounted = true;

  const saveInDB = async (newData) => {
    await client.service('/services/legos').update(legoId, { $set: { data: newData } });
    updates.emit(queryId, newData)
  };

  useAsyncEffect(async () => {
    try {
      const [lego] = await client.service('/services/legos').find({ query });
      if(lego && mounted) {
        setData(lego.data);
        setId(lego._id)
      }
    } catch (err) {
      console.error(err);
    }
  }, () => mounted = false, []);

  useEffect(() => {
    const handleUpdate = (e) => setData(e);
    updates.on(queryId, handleUpdate);
    return () => {
      updates.off(queryId, handleUpdate);
    };
  }, []);

  return [data, shouldUpdateLego ? saveInDB : setData, legoId];
}
