import React, { useState } from 'react';
import { IconButton } from '../common/IconButton';
import LegoContextSummary from '../lego/LegoContextSummary';
import { getImageThumbnailMedium, getImageThumbnailSmall } from '../../../../lib/imageUtilsESM';

export function ImagePreview({ url, fluid, fluidContain,fluidCover, img32, img50, img200, img100, contain, cover, className, selected, zoom, context, left, ...other }) {
  const [zoomedIn, setZoomedIn] = useState(false);
  const [isLeft, setSide] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [keepZoomOpen, setKeepZoomOpen] = useState(false);

  // When it is a known url pattern with thumbnails, use thumbnails
  const thumbMedium = getImageThumbnailMedium(url);
  const thumbSmall = getImageThumbnailSmall(url);

  let zoomOverlay;

  if (zoomedIn && zoom) {
    zoomOverlay = <div className={`HalfscreenImgPreview ${keepZoomOpen ? 'HalfscreenImgPreview--fixed' : ''} ${isLeft ? 'HalfscreenImgPreview--left' : ''}`}>
      <img src={thumbMedium}
           style={{ maxHeight: '100%', transform: `rotate(${rotation}deg)` }}
           className={'img-fluid'}
           {...other}
      />
      { keepZoomOpen ? <IconButton icon={'clear'} level={'white'} className={'float-bottom mb-4'}
                                   onClick={() => setKeepZoomOpen(false) + setZoomedIn(false)}>
        Close image
      </IconButton> : null}
      {context ? <LegoContextSummary className={'float-top mb-4'} context={context}/> : null}
    </div>;
  }

  let startZooming = (e) => {
    !keepZoomOpen && setSide(left ? !e.altKey : e.altKey)
    setZoomedIn(true);
    setRotation(0);
  };

  let onClick = (e) => {
    e.stopPropagation()
    if(e.ctrlKey) {
      window.open(url, { name: '_blank ' })
    } else if(e.shiftKey) {
      setKeepZoomOpen(!keepZoomOpen);
    } else {
      setRotation(rotation + 90);
    }
  };

  let srcUrl = thumbMedium;
  let imgSizeClass;
  let suffix = cover ? '-cover' : (contain ? '-contain' : '');
  if (fluid){
    imgSizeClass = 'img-fluid'
  } else if (fluidCover) {
    imgSizeClass = 'img-fluid-cover'
  } else if (fluidContain) {
    imgSizeClass = 'img-fluid-contain'
  } else if (img200) {
    imgSizeClass = 'img-200'+suffix
  } else if (img100) {
    imgSizeClass = 'img-100'+suffix
  } else if (img50) {
    imgSizeClass = 'img-50'+suffix
    srcUrl = thumbSmall;
  } else if (img32) {
    imgSizeClass = 'img-32'+suffix
    srcUrl = thumbSmall;
  } else {
    imgSizeClass = `img-med`
  }

  return <span className={`inline-block`}
               onMouseEnter={startZooming}
               onMouseLeave={() => !keepZoomOpen && setZoomedIn(false)}
               onClick={onClick}>
    <img src={srcUrl}
         className={`${zoom ? 'cursor-zoom-in' : ''} ${imgSizeClass} ${className || ''} ${selected ? 'border border-primary' : ''}`}
         {...other}
    />
    {zoomOverlay}
  </span>;
}
