import React, { Component } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import CRUDPage from './CRUDPage';
import DataviewEditor from '../components/dataview/DataviewEditor';
import { IconButton } from '../components/common/IconButton';
import LegoEditor from '../components/lego/LegoEditor';
import DataViewExplorer from '../components/lego/DataViewExplorer';
import HighlightedText from "../components/common/HighlightedText";
import JSONPreview from '../components/common/JSONPreview';

export default class DataViewsManager extends CRUDPage {
  constructor(props) {
    super(props, 'services/dataviews');

    this.state.numberOfResults = 40;
    this.dataviewStates = this.service('services/dataviews/state');

    this.onViewStateChange = this.onViewStateChange.bind(this)
  }
  componentDidMount() {
    super.componentDidMount();

    this.dataviewStates.on('viewStateChange', this.onViewStateChange);
    this.dataviewStates.on('updated', this.onViewStateChange);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.dataviewStates.removeListener('viewStateChange', this.onViewStateChange);
    this.dataviewStates.removeListener('updated', this.onViewStateChange);
  }

  updateMatchingView(view) {
    _.each(this.state.objects, (v, i) => {
      if(v._id === view._id) {
        this.state.objects[i] = view;
      }
    })
    this.setState({objects: this.state.objects});
  }

  async onViewStateChange(view) {
    this.updateMatchingView(view);

    view.__stats = await this.dataviewStates.get(view._id);
    this.updateMatchingView(view);
  }

  async fetchObjects() {
    // We don´t expect views to be that many
    await super.fetchObjects({$limit: 1000, $sort: 'name'});

    // Fetch views stats in parallel, then update UI
    let fetchStats = _.map(this.state.objects, async v => {
      // Fields with the __ prefix are ignored in copy and other operations
      v.__stats = await this.dataviewStates.get(v._id);
    });

    await Promise.all(fetchStats);
    this.setState({ objects: this.state.objects });
  }

  async exploreView(view) {
    this.modalActionsBus.emit('open', <div>
      <DataViewExplorer view={view} onCancel={() => this.modalActionsBus.emit('close')}/>
    </div>);
  }

  async rebuildView(view) {
    await this.dataviewStates.update(view._id, {});
  }

  getColumnsDefinition(objects) {
    return [
      {content: 'Type', className: ''},
      {content: 'Name', className: ''},
      {content: 'State', className: 'text-center'},
      {content: 'Sample of 3 keys', className: 'text-left'},
      {content: 'Selector & Parametrization', className: 'context-column'},
      {content: 'Options & processors', className: 'sem-column'},
    ]
  }

  getObjectColumns(view, definition) {
    let { _id, name, type, preProcessors, postProcessors, options, state, selector, updatedAt,  __stats } = view;
    state = state || {}

    let viewStateClass = state.name === 'ready' ? 'success' : 'danger';

    return [
      <td key={'type'}>
        <div>
          <div className={'badge badge-secondary'}>{type}</div>
          <div className={'text-info small ml-2'} title={updatedAt}>{updatedAt ? dayjs(updatedAt).fromNow() : ''}</div>
        </div>
        <div className={'text-secondary small'}>{_id}</div>
      </td>,

      <td key={'name'}>
        <strong className={'text-primary'}>{name}</strong>
      </td>,


      <td key={'state'}>
        <div className={'text-left no-wrap'}>
          <span className={'badge badge-'+viewStateClass}>{state.name || "???"}</span>
          <IconButton onClick={() => this.exploreView(view)} level={'secondary'} icon={'search'} description={'Edit'}></IconButton>

          <IconButton onClick={() => this.rebuildView(view)} level={'secondary'} icon={'restore_page'} description={'Edit'}><span className={'align-middle text-secondary'}>Rebuild</span></IconButton>
        </div>

        { __stats ? <div className={'small text-info'}>{__stats.keyCount} keys</div> : null}
      </td>,

      <td key={'keys'}>
        { __stats ?
          <div className={'small text-secondary'}>
            {(__stats.keySample || []).slice(0,3).map(key => <div key={key} className={'small break-word-all'}>{key}</div>)}
          </div>
          : null
        }
      </td>,

      <td key={'sel'} className={'data-column no-wrap'} onClick={() => this.openEdit(view)}>
        {_.map(selector || {}, (val,key) => <div key={key} className={'m-05'}>
          <span className={'monospace'} key={key}>{key}: </span>
          <span className={'monospace text-secondary'}>
            <HighlightedText text={_.isString(val) ? val: JSON.stringify(val)} regex={/\$\w+/} highlightClass={'text-dark rounded px-1 bg-light-primary'}/>
          </span>
        </div>)}
      </td>,

      <td key={'par'} className={'data-column'} onClick={() => this.openEdit(view)}>
        <JSONPreview value={options}/>
        {
          preProcessors && preProcessors.length ?
            <pre style={{ whiteSpace: 'pre-wrap' }} className={'small text-secondary monospace break-word-all mb-1'}>
            PRE: {_.map(preProcessors, p => JSON.stringify(p))}
          </pre> : null
        }
        {
          postProcessors && postProcessors.length ?
            <pre style={{ whiteSpace: 'pre-wrap' }} className={'small text-secondary monospace break-word-all mb-0'}>
          POS: {_.map(postProcessors, p => JSON.stringify(p))}
        </pre> : null
        }
      </td>,

    ];
  }

  async openEdit(obj) {
    if(obj._id) {
      if(!confirm("Editing a view will destroy the entire cache, are your sure?"))
        return;

      obj = await this.refreshObject(obj);
    }

    this.modalActionsBus.emit('open', <div>
      <DataviewEditor obj={obj} onSave={async (changedObj, closeDialog) => {
        try {
          let res = await this.updateOrCreate(obj, changedObj);

          if(closeDialog) {
            this.modalActionsBus.emit('close');
          } else if(obj._id) {
            return await this.refreshObject(obj);
          }
          return res;
        } catch (err) {
          this.handleError(err);
        }
      }} onCancel={() => this.modalActionsBus.emit('close')}/>
    </div>);
  }
}
