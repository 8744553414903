import React, { Component, useContext } from 'react';
import { TypeTag } from '../lego/TypeTag';
import { SearchFilterButton } from './SearchFilterButton';
import ModelContextEditor from '../lego/ModelContextEditor';
import _ from 'lodash';
import { LegoStateSwitch } from '../lego/LegoStateSwitch';
import IntentionsSelector from '../lego/IntentionsSelector';
import { LocalesSelector } from '../lego/LocalesSelector';
import LegoLabelsSelector from '../lego/LegoLabelsSelector';
import { IconButton } from './IconButton';
import BadgeId from './BadgeId';
import LegoAdminPageContext from '../../pages/legoAdminPageContext';
import InputMultipleSemantics from './editors/InputMultipleSemantics';

export function LegoFiltersBar({ filter, onChange, fields = ['type', 'intentions', 'locales', 'context', 'state', 'labels', '_id'] }) {
  const { page } = useContext(LegoAdminPageContext);

  const onFieldChange = (field, value) => {
    let newFilter = _.cloneDeep(filter || {});
    if (_.isEmpty(value)) {
      delete newFilter[field];
    } else {
      newFilter[field] = _.cloneDeep(value);
    }
    onChange(newFilter);
  };

  const onChangeSwitch = (field, value, isOn) => {
    let newFilter = _.cloneDeep(filter || {});
    if (isOn) {
      newFilter[field] = _.union(newFilter[field], [value]);
    } else {
      newFilter[field] = _.without(newFilter[field], value);
      if (!newFilter[field].length) {
        delete newFilter[field];
      }
    }
    onChange(newFilter);
  };

  let { type, intentions, locales, semantics, context, labels, state, _id } = filter || {};

  let fieldsUI = [];

  const openLego = (prefilledId = '') => {
    let id = prompt('Enter a lego ID', prefilledId);
    if(id) {
      page.loadAndEdit(id);
    }
  }

  const knownFields = {
    locales: <span className={'mr-3 zoom-90 align-middle'} key={'locales'}>
          <LocalesSelector value={locales} onChange={(locs) => onFieldChange('locales', locs)}/>
        </span>,

    intentions: <span style={{ width: intentions?.length ? '240px' : '140px', display: 'inline-block' }} className={'zoom-75 mr-3 text-left align-middle'}
                      key={'intentions'}>
          <IntentionsSelector value={intentions} onChange={(vs) => onFieldChange('intentions', vs)}/>
        </span>,

    type: <span className={'mr-3 zoom-75 inline-block align-middle'} style={{maxWidth: '240px'}} key={'type'}>
          {
            _.map(TypeTag.types(), t =>
              <SearchFilterButton key={t} onChange={isOn => onChangeSwitch('type', t, isOn)}
                                  value={_.includes(type, t)}>
                <TypeTag key={t} type={t}/>
              </SearchFilterButton>)
          }
        </span>,

    context: <span style={{ width: '300px', display: 'inline-block' }} key={'context'} className={'zoom-75 mr-3 align-middle text-left'}>
        <ModelContextEditor value={context} onChange={(newContext) => onFieldChange('context', newContext)}/>
      </span>,

    labels: <span style={{ width: labels?.length ? '300px' : '110px', display: 'inline-block' }} key={'labels'} className={'zoom-75 mr-3 align-middle text-left'}>
      <LegoLabelsSelector placeholder={'Labels...'} value={labels} onChange={(vs) => onFieldChange('labels', vs)}/>
    </span>,

    state: <span className={'zoom-90 mr-3 align-middle'} key={'state'}>
      <LegoStateSwitch compact multi value={state || 'other'} onChange={(states) => onFieldChange('state', states)}/>
    </span>,

    semantics: <span className={'zoom-75 mr-3 align-middle'} style={{ width: '300px', display: 'inline-block' }} key={'semantics'}>
      <InputMultipleSemantics value={semantics} onChange={(vs) => onFieldChange('semantics', vs)}/>
    </span>,

    _id: <span>
      <IconButton icon={'numbers'} level={'dark'} title={'Open a lego by entering an id'} onClick={() => openLego()}/>
    </span>
  };

  for (const field of fields) {
    if (knownFields[field]) {
      fieldsUI.push(knownFields[field]);
    }
  }

  return <React.Fragment>
      <span className={'inline-block align-middle text-center'}>
       {fieldsUI}
      </span>
  </React.Fragment>;
}
